<template>
    <section>
        <NavigationGoBack />
        <h1 class="mt-1">
            Einstellungen
        </h1>
        <div class="flex-row">
            <NuxtLink role="button" :to="localePath('dashboard-settings-contact-info')">
                Kontaktdaten ändern
            </NuxtLink>
            <NuxtLink v-if="!user.socialProvider" role="button" :to="localePath('dashboard-settings-change-password')">
                Passwort ändern
            </NuxtLink>
            <NuxtLink role="button" :to="localePath('dashboard-settings-optin-settings')">
                Optin Einstellungen
            </NuxtLink>
        </div>
    </section>
</template>

<script setup>
const localePath = useLocalePath()
const { user } = useUser()
useHead({
    title: 'Einstellungen'
})
</script>

<style scoped>
.flex-row { justify-content: flex-start; }
ul {
    list-style: none;
    padding-left: 0;
}
</style>
