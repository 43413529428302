<template>
    <section>
        <section class="top-section-container section-padding">
            <div class="container">
                <div>
                    <LocaleHomepageTitle />
                    <h1 class="white-text">
                        Kundengewinnung in deinem Servicegebiet...<br>
                        <span class="yellow-text">...so einfach, wie Pizza bestellen!</span>
                    </h1>
                </div>
                <div class="top-section mt-1">
                    <div>
                        <h2 class="white-text">
                            50.000 Einträge unserer Partner haben schon insgesamt <span class="yellow-text-highlight">1M+</span> Impressionen erreicht. Wann bist du dabei?
                        </h2>
                        <NuxtLink
                            :to="localePath('become-partner')"
                            role="button"
                            class="yellow btn"
                        >
                            Gratis Testphase starten
                        </NuxtLink>
                        <br>
                        <small class="white-text">
                            Jetzt noch -40% Rabatt sichern!
                        </small>
                        <p class="white-text mt-2">
                            Du hast noch Fragen?
                            <NuxtLink
                                class="white-link mt-1"
                                :to="localePath('free-consulting')"
                            >
                                Gratis Erstberatung
                            </NuxtLink>
                        </p>
                    </div>
                    <img
                        class="top-image"
                        src="/assets/images/growth.svg"
                        :alt="`Wachstum mit ${$myT('mainAppDomain')}`"
                    >
                </div>
            </div>
        </section>
        <section class="section-padding container">
            <h2>Deine Dienstleistung im Rampenlicht!</h2>
            <p>Egal ob du Essen, Haushaltsdienstleistungen oder Beauty-Services anbietest, {{ $myT('mainAppDomain') }} bringt dich direkt zu Kunden in deinem Servicegebiet.</p>
            <div class="benefits-container">
                <div class="benefits-items">
                    <BoxForSlot>
                        <template #content>
                            <p class="bold">
                                Über {{ $myT('mainAppDomain') }}
                            </p>
                            <ul>
                                <li>45.000+ Besuche monatlich (lt. similarweb, März 2024)</li>
                                <li>Spezialisiert auf mobile Dienstleistungen und Lieferservices</li>
                                <li>Über 40.000 Service-Angebote</li>
                            </ul>
                        </template>
                    </BoxForSlot>
                    <BoxForSlot>
                        <template #content>
                            <p class="bold">
                                Multichannel Präsenz
                            </p>
                            <ul>
                                <li>Mobil & Tablet optimiert</li>
                                <li>Digital Marketing</li>
                                <li>Social Media Präsenz</li>
                            </ul>
                        </template>
                    </BoxForSlot>
                    <BoxForSlot>
                        <template #content>
                            <p class="bold">
                                Partnerschaftliche Zusammenarbeit
                            </p>
                            <ul>
                                <li>Branchenexpertise und Erfahrung</li>
                                <li>Analysen & Profil-Optimierung: Erhalte wertvolle Daten und Tools zur Optimierung</li>
                                <li>Analyse deiner Einbindung & Datenfeed</li>
                            </ul>
                        </template>
                    </BoxForSlot>
                </div>
                <a
                    :href="`https://www.${$myT('mainAppDomain')}`"
                    target="_blank"
                >
                    <img
                        :src="`${SPACES_URL}/images/locaverse-screen.png`"
                        class="image-container"
                        :alt="`${$myT('mainAppDomain')} Alles kommt zu dir`"
                        :title="`${$myT('mainAppDomain')} Alles kommt zu dir`"
                        loading="lazy"
                    >
                </a>
            </div>
        </section>
        <section class="dark-blue-section section-padding">
            <div class="container">
                <h2>Wir haben die Interessenten - willst du für sie sichtbar werden?</h2>
                <p>Jetzt erfolgreich über {{ $myT('mainAppDomain') }} verkaufen</p>
                <NuxtLink
                    :to="localePath('become-partner')"
                    role="button"
                    class="yellow btn"
                >
                    Gratis Testphase starten
                </NuxtLink>
            </div>
        </section>
        <section class="for-grunde-section section-padding">
            <div class="container">
                <h2>Wir brauchen Partner wie dich!</h2>
                <p>{{ $myT('homepagePartnerHeading') }}</p>
                <div class="grunde-cards centered">
                    <div class="grunde-card flex-center">
                        <img
                            src="/assets/images/homepage/benefits-icons/1.svg"
                            loading="lazy"
                            alt="Hohe Reichweite und Sichtbarkeit genau in deinem Servicegebiet"
                        >
                        <p>Hohe Reichweite und Sichtbarkeit genau in deinem Servicegebiet</p>
                    </div>
                    <div class="grunde-card flex-center">
                        <img
                            src="/assets/images/homepage/benefits-icons/2.svg"
                            loading="lazy"
                            alt="Interessante Daten und Analysen"
                        >
                        <p>Interessante Daten und Analysen</p>
                    </div>
                    <div class="grunde-card flex-center">
                        <img
                            src="/assets/images/homepage/benefits-icons/3.svg"
                            loading="lazy"
                            alt="Persönliche Kundenbetreuung"
                        >
                        <p>Persönliche Kundenbetreuung</p>
                    </div>
                    <div class="grunde-card flex-center">
                        <img
                            src="/assets/images/homepage/benefits-icons/4.svg"
                            loading="lazy"
                            alt="Leistungsorientierte Abrechnung"
                        >
                        <p>Leistungsorientierte Abrechnung</p>
                    </div>
                </div>
            </div>
            <section class="target-area-section section-padding">
                <div class="container target-area-container">
                    <img
                        src="/assets/images/homepage/servicegebiet.svg"
                        loading="lazy"
                        alt="Servicegebiet"
                    >
                    <div>
                        <p>
                            Schluss mit kompliziertem & ineffizientem Marketing – bei uns ist der Weg zum Erfolg so einfach wie ein Klick: Erreiche deine Zielgruppe dort, wo du gefunden werden möchtest.
                        </p>
                        <p>
                            Definiere dein exaktes Service- oder Liefergebiet: Wähle aus Bezirken, Städten, Bundesländern und anderen vordefinierten Gebieten.
                        </p>
                    </div>
                </div>
                <NuxtLink
                    :to="localePath('become-partner')"
                    role="button"
                    class="mt-1 block-link yellow btn"
                >
                    Gratis Testphase starten
                </NuxtLink>
            </section>
        </section>
        <section class="partner-section section-padding">
            <NavigationPartnerLogos class="container" />
            <CustomerQuote
                v-for="(quote, index) in QUOTES"
                :key="`quote-${index}`"
                :text="quote.text"
                :author="quote.author"
                :image="quote.image"
                class="container mt-2"
            />
        </section>
        <section class="section-timeline section-padding container">
            <h2>
                Wie läuft die Zusammenarbeit ab?
            </h2>
            <div class="container-timeline">
                <TimeLine
                    :points="points"
                    class="container"
                />
                <img
                    class="timeline-image flex-center"
                    loading="lazy"
                    src="/assets/images/homepage/zusammenarbeit.svg"
                    alt="Wie läuft die Zusammenarbeit ab?"
                >
            </div>
            <NuxtLink
                :to="localePath('become-partner')"
                role="button"
                class="block-link yellow btn"
            >
                Gratis Testphase starten
            </NuxtLink>
        </section>
        <section class="offers-section section-padding">
            <div class="container">
                <h2>Was können wir dir bieten?</h2>
                <div class="offers-container">
                    <details
                        open
                        class="mt-2"
                    >
                        <summary
                            role="button"
                            class="outline"
                        >
                            <b class="summary-title">
                                Erhöhte Reichweite und neue Kunden
                            </b>
                        </summary>
                        <p>
                            Wir helfen Unternehmen dabei, ihre Sichtbarkeit zu steigern und neue Kunden zu erreichen, die sie sonst vielleicht nicht gefunden hätten. Mit über 45.000+ Besuchern monatlich (lt. similarweb, März 2024) und mehr als 180.000 Anbieter-Aufrufe pro Monat ist unsere Plattform eine effektive Möglichkeit, mehr Kunden zu gewinnen und den Umsatz zu steigern.
                        </p>
                    </details>
                    <details class="mt-2">
                        <summary
                            role="button"
                            class="outline"
                        >
                            <b class="summary-title">
                                Zeitersparnis
                            </b>
                        </summary>
                        <p>
                            Wir kümmern uns um dein Marketing. Deine Präsenz in deinem Servicegebiet ist bei uns garantiert!
                        </p>
                    </details>
                    <details class="mt-2">
                        <summary
                            role="button"
                            class="outline"
                        >
                            <b class="summary-title">
                                Persönlicher Kundenservice
                            </b>
                        </summary>
                        <p>
                            Du hast immer einen persönlichen Ansprechpartner zur Verfügung, der dir bei Fragen oder Problemen schnell und unkompliziert weiterhilft.
                        </p>
                    </details>
                    <details class="mt-2">
                        <summary
                            role="button"
                            class="outline"
                        >
                            <b class="summary-title">
                                Transparente Berichterstattung
                            </b>
                        </summary>
                        <p>Unser fortlaufendes Reporting bietet dir Einblicke in die Leistung deines Services auf unserer Plattform.</p>
                    </details>
                </div>
                <p class="mt-2">
                    Alles in allem bieten wir dir eine effektive und kosteneffiziente
                    Möglichkeit, deine Reichweite zu erhöhen, neue Kunden
                    zu gewinnen und dein Unternehmen erfolgreich voranzubringen.
                    Überzeug dich selbst und werde noch heute zu einem Teil von {{ $myT('mainAppDomain') }}
                    oder buche
                    <NuxtLink :to="localePath('free-consulting')">
                        kostenlos dein Beratungsgespräch
                    </NuxtLink>
                    bei uns. Wir beantworten gerne alle deine Fragen.
                </p>
                <NuxtLink
                    :to="localePath('become-partner')"
                    role="button"
                    class="block-link yellow btn"
                >
                    Gratis Testphase starten
                </NuxtLink>
            </div>
        </section>
        <section
            id="newsletter"
            class="section-padding newsletter"
        >
            <NewsletterForm class="container" />
        </section>
    </section>
</template>

<script setup>
import { QUOTES } from '~/constants'

definePageMeta({
    auth: false,
    layout: 'full-width'
})
const localePath = useLocalePath()
const SPACES_URL = useRuntimeConfig().public.spacesUrl

const { $myT } = useNuxtApp()
const mainAppDomain = $myT('mainAppDomain')

const points = [{
    title: 'Als Partner registrieren',
    text: `Einfach einen Account erstellen & dein Anbieterprofil anlegen und in wenigen Minuten auf ${mainAppDomain} präsent sein.`,
    linkUrl: localePath('become-partner'),
    linkText: 'Partner Profil erstellen'
}, {
    title: 'Kostenfreie und unverbindliche Testphase nutzen',
    text: 'Registriere dich jetzt und sichere dir -40% Rabatt auf unser effektivstes Paket für deine Reichweite und genieße es einen Monat lang komplett kostenlos!\n'
    + 'Bei Fragen hast du einen persönlichen Ansprechpartner.',
    linkUrl: localePath('free-consulting'),
    linkText: 'Dein persönlicher Ansprechpartner'
}, {
    title: 'Analysen & Profil-Optimierung',
    text: 'Für eine fortlaufende Optimierung stellen wir dir interessante Daten und Analysen zur Verfügung. Webinare und Tools helfen dir bei der Profiloptimierung.'
}, {
    title: 'Kostenfreies Beratungsgespräch',
    text: 'Bei Fragen kannst du jederzeit ein gratis Beratungsgespräch nutzen.',
    linkText: 'Gratis Beratungsgespräch',
    linkUrl: localePath('free-consulting')
}]

useSchemaOrg([
    defineProduct({
        name: `Werde Anbieter-Partner von ${mainAppDomain}`,
        image: [`${SPACES_URL}/images/locaverse-screen.png`],
        aggregateRating: {
            ratingValue: 4.6,
            bestRating: 5,
            ratingCount: 1617
        }
    })
])
const metaDescription = '✓ Hohe Reichweite und Sichtbarkeit ✓ Steigerung deiner Bekanntheit ✓ Persönliche Kundenbetreuung ✓ Leistungsorientierte CPC Abrechnung'
useHead({
    title: `Werde Anbieter-Partner von ${mainAppDomain}`,
    meta: [{ name: 'description', content: metaDescription }]
})
</script>

<style scoped>
h1 {
    font-size: 2rem;
    margin-bottom: 0;
}
.yellow-text {
    width: fit-content;
    margin-left: auto;
    text-align: right;
    color: var(--yellow);
    display: block;
}
.section-padding {
    padding-top: calc(var(--spacing) * 2);
    padding-bottom: calc(var(--spacing) * 2);
}
details summary[role=button]::after {
    background-image: var(--icon-chevron);
}
.bold {
    font-weight: bold;
}
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.top-section-container {
    background-color: var(--dark-blue);
}
.top-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.top-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
h1, .white-link, .white-text {
    color: var(--white);
}
.white-text a, .white-link {  color: var(--white); text-decoration: underline; }
.for-grunde-section, .partner-section, .offers-section, .target-area-section {
    background-color: var(--white);
}
.grunde-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: var(--spacing);
}
.grunde-cards p { font-size: 0.8rem; }
.grunde-card {gap: 1rem;}
.grunde-card img {  width: 5rem; }
.container-timeline {
    display: flex;
    flex-direction: column;
}
.timeline-image {
    padding: var(--spacing);
    display: none;
}
.benefits-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: calc(2 * var(--spacing));
}
.benefits-items {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing);
}
.image-container {
    width: 14rem;
    display: block;
}
.dark-blue-section {
    background-color: var(--dark-blue);
    color: var(--white);
    text-align: center;
}
.dark-blue-section h2, .dark-blue-section p { color: var(--white); }
.summary-title {
    margin-bottom: 0;
}
.target-area-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(2 * var(--spacing));
}
.target-area-container img {
    width: 15rem;
    height: auto;
}
.yellow-text-highlight { color: var(--yellow); }
@media (min-width: 425px) {
    .top-image {
        width: 20rem
    }
}
@media (min-width: 768px) {
    .target-area-container {
        display: grid;
        grid-template-columns: 1fr 3fr;
        align-items: start;
        gap: calc(2 * var(--spacing));
    }
    .benefits-container {
        display: grid;
        align-items: flex-start;
        grid-template-columns: 2fr 1.5fr;
    }
    .image-container {
        position: sticky;
        top: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .grunde-cards { display: flex; }
    .container-timeline {
        flex-direction: row;
        align-items: flex-start;
    }
    .top-section {
        flex-direction: row;
        align-items: center;
        gap: var(--spacing);
    }
}
@media (min-width: 1024px) {
    .grunde-card img {  width: 7rem; }
    .top-section { flex-direction: row; }
    .timeline-image {
        width: 20rem;
        display: block;
        position: sticky;
        top: 0;
    }
    .offers-container {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 1440px) {
    .image-container { width: 18rem; }
}
</style>
