<template>
    <label v-if="!selectedFile" class="dropzone" :for="`cardFileInput-${uniqueIdentifier}`">
        <span class="dropzone-card">
            <slot name="dropzone-card" />
        </span>
        <input
            :id="`cardFileInput-${uniqueIdentifier}`"
            :ref="`cardFileInput-${uniqueIdentifier}`"
            type="file"
            :multiple="allowMultiple"
            style="display: none"
            accept=".jpg,.jpeg,.png,.gif,.webp"
            @change="handleFileChange"
        >
    </label>
    <div v-else class="thumbnail" draggable="false">
        <img :src="selectedFile.previewURL" alt="Preview" draggable="false">
        <span class="close" @click="emit('remove-file')">
            <img
                src="/icons/xmark-solid.svg"
                alt="Delete Icon"
            >
        </span>
    </div>
</template>

<script setup>
defineProps({
    selectedFile: { type: Object, required: false, default: null },
    uniqueIdentifier: { type: String, required: true },
    allowMultiple: { type: Boolean, required: false, default: false }
})

const emit = defineEmits(['remove-file', 'update:selectedFile'])

function handleFileChange (event) {
    const file = event.target.files[0]
    if (!file) { return }
    const reader = new FileReader()
    reader.onload = (e) => {
        const previewURL = e.target.result
        emit('update:selectedFile', { file, previewURL })
    }
    reader.readAsDataURL(file)
}
</script>

<style scoped>
.dropzone {
    position: relative;
    max-width: 18rem;
    aspect-ratio: 4/3;
    border: 2px dashed var(--neutral-200);
    border-radius: var(--border-radius);
    display: grid;
    place-items: center;
    cursor: pointer;
    background-color: var(--neutral-100);
    padding: var(--spacing);
    transition: .2s ease-in-out;
    margin-left: auto;
    margin-right: auto;
}
.dropzone:hover { background-color: #dfdfdf; }
.dropzone-card {
    display: grid;
    text-align: center;
    place-items: center;
    row-gap: 0.5rem;
}
.thumbnail { position: relative; }
.thumbnail img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 70%;
    border-radius: var(--border-radius);
}
.close {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    background-color: var(--white);
    height: 2rem;
    width: 2rem;
    display: grid;
    place-items: center;
    border-radius: 50%;
    border: 1px solid var(--dark-blue);
    cursor: pointer;
}
.close img {
    height: 1rem;
    width: 1rem;
}
/* Hide default input styles */
input[type="file"] {
    display: none;
}
</style>
