<template>
    <section class="ptb-1">
        <NavigationGoBack />
        <h1 class="mt-1">
            Passwort zurücksetzen
        </h1>
        <p>
            Bitte gebe Deine E-Mail an, mit der Du Dich registriert hast. Wir senden Dir einen
            Link zur Wiederherstellung Deines Passworts.
        </p>

        <form
            v-if="!data.submitted"
            method="post"
            @submit.prevent="sendPasswordResetLink"
        >
            <input
                id="email"
                v-model="data.email"
                type="email"
                name="email"
                placeholder="E-Mail Adresse eingeben"
                required
                maxlength="255"
            >
            <button :aria-busy="data.submitting">
                Abschicken
            </button>
        </form>

        <img v-else src="/assets/icons/circle-check-solid.svg" alt="Success Icon" loading="lazy">
    </section>
</template>

<script setup>

definePageMeta({
    auth: {
        unauthenticatedOnly: true,
        navigateAuthenticatedTo: '/dashboard'
    },
    layout: 'standard'
})
const { $backendApi } = useNuxtApp()
const toast = useToast()

const data = reactive({
    email: '',
    submitting: false,
    submitted: false
})

async function sendPasswordResetLink () {
    data.submitting = true
    try {
        await $backendApi('auth/password-reset/resend-link', {
            method: 'POST',
            body: { email: data.email, userType: 'company' }
        })
        toast.success('Anfrage erfolgreich.')
        data.submitting = false
        data.submitted = true
    } catch (err) {
        const msg = err.response._data.msg
        data.submitting = false
        toast.error(`Anfrage fehlgeschlagen. ${msg}`, { timeout: 5000 })
    }
}

useHead({
    title: 'Passwort zurücksetzen',
    meta: [{ name: 'robots', content: 'noindex' }]
})
</script>

<style scoped>
img {
    width: 5em;
    height: 5em;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
</style>
