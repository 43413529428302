<template>
    <div>
        <LazyServiceOpeningHoursEditDialog
            v-if="showOpeningHoursEditDialog"
            :service-id="serviceId"
            @close="showOpeningHoursEditDialog = false"
        />
        <h3 class="flex-row">
            Service-Zeiten
            <ActionIcon
                icon="pen-solid"
                :position-absolute="false"
                @click="showOpeningHoursEditDialog = true"
            />
        </h3>
        <div v-if="hours.length > 0" class="hours-container mt-1">
            <div v-for="(entry, index) in hours" :key="index" class="hour-box">
                <span class="day">{{ dayOfWeekStringShort(entry.dayOfWeek) }}</span>
                <span v-if="entry.openingTime && entry.closingTime" class="time">
                    {{ entry.openingTime }} - {{ entry.closingTime }}
                </span>
                <span v-else class="time">Auf Anfrage</span>
            </div>
        </div>
        <p v-else class="mt-1">
            <i>Keine Service-Zeiten angegeben</i>
        </p>
    </div>
</template>

<script setup>
import { WEEKDAYS_MAPPING } from '~/constants'

defineProps({
    hours: { type: Array, required: false, default: () => [] },
    serviceId: { type: Number, required: true }
})

const showOpeningHoursEditDialog = ref(false)

function dayOfWeekStringShort (dayIndex) {
    return WEEKDAYS_MAPPING[dayIndex].slice(0, 2)
}
</script>

<style scoped>
p { margin-bottom: 0; }
h3 {
    margin-bottom: 0;
    font-size: 1rem;
}
.flex-row {
    display: flex;
    column-gap: var(--spacing);
    align-items: center;
    justify-content: flex-start;
}
.hours-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0.5rem;
}
.day {
    font-size: 1.3rem;
}
.hour-box {
    width: 5.6rem;
    min-height: 3rem;
    border-radius: var(--border-radius);
    display: grid;
    place-items: center;
    border: 1px solid var(--neutral-200);
    padding: 0 0.1rem 0 0.1rem;
}
.hour-box .time {
    font-size: 0.7rem;
    text-align: center;
}
</style>
