<template>
    <div class="features-container">
        <div class="box">
            <h3>Anbieterprofil</h3>
            <PricingTierItem text="Eigene URL-Seite" />
            <PricingTierItem text="Suchmaschinenoptimierung" />
            <PricingTierItem text="Kontakt-Infos" />
            <PricingTierItem text="Adresse" />
            <PricingTierItem text="Öffnungszeiten" />
            <PricingTierItem text="Dein Logo" />
            <PricingTierItem text="Unbegrenzte Beschreibung mir Formatierungsoptionen" />
            <PricingTierItem text="Häufig gestellte Fragen (FAQs) mit Formatierungsoptionen" />
            <PricingTierItem text="Links zu deinen Social Media Profilen" />
            <PricingTierItem text="Dein Instagram Profil eingebettet" />
            <PricingTierItem text="Gutscheine und Aktionen einstellen" />
            <PricingTierItem text="Informationen zur Nachhaltigkeit" />
            <PricingTierItem text="Kontaktformular" />
        </div>
        <div class="box">
            <h3>Service-Leistung Seite</h3>
            <PricingTierItem text="Eigene URL-Seite" />
            <PricingTierItem text="Suchmaschinenoptimierung" />
            <PricingTierItem text="Kategorie" />
            <PricingTierItem text="Servicegebiet: Orte, PLZ" />
            <PricingTierItem text="Kontakt-Infos" />
            <PricingTierItem text="Adresse" />
            <PricingTierItem text="Titelbild" />
            <PricingTierItem text="Bildergalerie" />
            <PricingTierItem text="Spezifische Service-Zeiten" />
            <PricingTierItem text="Zahlungsmethoden" />
            <PricingTierItem text="Preis" />
            <PricingTierItem text="Produktliste" />
            <PricingTierItem text="Unbegrenzte Beschreibung mir Formatierungsoptionen" />
            <PricingTierItem text="Häufig gestellte Fragen (FAQs) mit Formatierungsoptionen" />
            <PricingTierItem text="Kontaktformular" />
        </div>
    </div>
</template>

<script setup>
</script>

<style scoped>
h3 { text-align: center; margin-bottom: 0; }
.features-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
    justify-content: flex-start;
}
.box {
    padding: 0.75rem;
    background-color: var(--white);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}
@media (min-width: 768px) {
    .features-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        column-gap: 1rem;
        row-gap: 1rem;
    }
}
</style>
