<template>
    <div class="my-table-container">
        <p v-if="selectedCategoryName">
            Ausgewählt: <strong>{{ selectedCategoryName }}</strong>
        </p>
        <input
            id="search"
            v-model="searchTerm"
            type="search"
            name="search"
            placeholder="Suchbegriff"
        >
        <div v-if="!error" class="grid-container">
            <label v-for="(item, index) in filteredCategories" :key="index" :for="'small_' + index">
                <input
                    :id="'small_' + index"
                    v-model="categoryId"
                    type="radio"
                    name="category_id"
                    :value="item.id"
                    @change="processInput(item)"
                >
                {{ item.name }}
            </label>
        </div>
        <p v-else>
            Kategorien konnten nicht geladen werden.
        </p>
    </div>
</template>

<script setup>
const allCategories = ref([])
const searchTerm = ref('')
const error = ref(false)

const filteredCategories = computed(() => {
    return allCategories.value.filter((item) => {
        return item.name
            .toLowerCase()
            .includes(searchTerm.value.toLowerCase())
    })
})

const { $backendApi } = useNuxtApp()
async function fetchCategories () {
    try {
        const { categories } = await $backendApi('/categories/')
        allCategories.value = categories
    } catch (error) {
        error.value = true
        handleBackendError({ error, toast })
    }
}

const categoryId = defineModel({ type: Number })
const selectedCategoryName = ref('')

onMounted(async () => {
    await fetchCategories()
    const targetCategory = allCategories.value.find(obj => obj.id === categoryId.value)
    if (targetCategory) {
        selectedCategoryName.value = targetCategory.name
    }
})

function processInput (item) {
    selectedCategoryName.value = item.name
    categoryId.value = item.id
}
</script>

<style scoped>
.grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: var(--spacing);
    height: 20rem;
    overflow-y: auto;
}
.grid-container label { cursor: pointer; }
@media (min-width: 768px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>
