<template>
    <section>
        <NavigationGoBack />
        <p v-if="pending" aria-busy="true">
            Lade...
        </p>
        <p v-else-if="error">
            Content kann nicht angezeigt werden 😔
        </p>
        <div v-else class="requests-container">
            <div class="space-between">
                <h1 class="mt-1">
                    Kontaktanfragen <span v-if="total > 0">({{ total }} ungelesen)</span>
                </h1>
                <BusinessContactFormToggle />
            </div>
            <ContactRequest
                v-for="notification in notifications"
                :key="notification.id"
                :contact-request="notification"
            />
        </div>
    </section>
</template>

<script setup>
const { data, pending, error } = await useIFetch('/notifications/provider')
const { notifications, total, hasNextPage } = data.value
</script>

<style scoped>
.space-between { flex-wrap: wrap; }
.requests-container {
    padding-bottom: 2rem;
}
</style>
