<template>
    <section class="full-height">
        <NavigationAppHeader />
        <main>
            <slot />
        </main>
        <NavigationDefaultFooter />
    </section>
</template>

<script setup>
useCustomHead()
</script>

<style scoped>
section { margin-bottom: 0; }
main {
    margin-top: 4rem;
}
@media (min-width: 768px) {
    main {
        margin-top: 0;
    }
}
</style>
