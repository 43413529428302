<template>
    <div class="table-container">
        <table role="grid">
            <thead>
                <tr>
                    <slot name="headers" />
                </tr>
            </thead>
            <tbody>
                <slot name="content" />
            </tbody>
        </table>
        <slot name="loading" />
    </div>
</template>

<style scoped>
.table-container {
    overflow-y: auto;
    max-height: 100%;
}
</style>
