export function useOnboardingDone () {
    const { business, hasStripeSubscription } = storeToRefs(useBusinessStore())
    const { hasServices } = storeToRefs(useServiceStore())
    const { hasContactInfo, isStripeCustomer, user } = useUser()
    const route = useRoute()

    // TODO: add once all transitioned to stripe: isStripeCustomer.value && hasStripeSubscription.value
    const onboardingDone = computed(() => {
        const mandatoryConditionsMet = hasContactInfo.value && business.value && hasServices.value
        const stripeConditionsMet = (isStripeCustomer.value && hasStripeSubscription.value) || user.value?.id <= 304
        return stripeConditionsMet && mandatoryConditionsMet
    })
    const showNavigationLinks = computed(() => {
        return !route.path.includes('dashboard') || onboardingDone.value
    })

    return { onboardingDone, showNavigationLinks }
}
