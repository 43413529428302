<template>
    <div>
        <h2>Ergänze hier Links zu deinen Social Media Kanälen <small>(optional)</small></h2>
        <FormBusinessSocialMediaLinks
            v-model="newSocialMedia"
            button-text="Weiter"
            @submit="next"
        />
    </div>
</template>

<script setup>
const emit = defineEmits(['next'])

const { $clientLocalStorage } = useNuxtApp()
const savedState = $clientLocalStorage.getItem({ item: 'newBusinessProcess', parseJson: true }) ?? {}

const newSocialMedia = ref({
    facebookLink: { link: savedState.facebookLink, invalid: '' },
    linkedinLink: { link: savedState.linkedinLink, invalid: '' },
    instagramLink: { link: savedState.instagramLink, invalid: '' },
    youtubeLink: { link: savedState.youtubeLink, invalid: '' },
    tiktokLink: { link: savedState.tiktokLink, invalid: '' }
})

function next () {
    const sm = newSocialMedia.value
    savedState.facebookLink = sm.facebookLink.link
    savedState.linkedinLink = sm.linkedinLink.link
    savedState.instagramLink = sm.instagramLink.link
    savedState.youtubeLink = sm.youtubeLink.link
    savedState.tiktokLink = sm.tiktokLink.link
    $clientLocalStorage.setItem({
        item: 'newBusinessProcess',
        value: savedState
    })
    emit('next')
}
</script>

<style scoped>

</style>
