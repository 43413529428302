<template>
    <div>
        <Teleport
            v-if="showAiDialog"
            to="body"
        >
            <dialog open>
                <article>
                    <CloseIcon @close="showAiDialog = false" />
                    <h3 class="mr-2">
                        Personalisierte Beschreibung mit KI
                    </h3>
                    <p>
                        Gib bis zu 3 Links an, damit die Beschreibung auf dein Angebot angepasst werden kann.
                    </p>
                    <form @submit.prevent="generateDescription">
                        <div
                            v-for="(link, index) in links"
                            :key="index"
                            class="space-between mb-1"
                        >
                            <label :for="`link${index}`">
                                Link für Kontext
                                <input
                                    :id="`link${index}`"
                                    v-model="links[index]"
                                    type="url"
                                    placeholder="https://..."
                                    required
                                >
                            </label>
                            <ActionIcon
                                v-if="links.length > 1"
                                :position-absolute="false"
                                class="ml-05 mt-auto"
                                icon="trash-can-solid"
                                @click="links.splice(index, 1)"
                            />
                        </div>
                        <div class="space-between">
                            <button
                                class="outline"
                                :disabled="hasEmptyLinks || isLoading || links.length > 2"
                                type="button"
                                @click="addLink"
                            >
                                + Link hinzufügen
                            </button>
                            <button
                                type="submit"
                                :aria-busy="isLoading"
                            >
                                Generieren
                            </button>
                        </div>
                    </form>
                </article>
            </dialog>
        </Teleport>
        <div class="space-between">
            <label for="description">Beschreibung <b>(mind. {{ minLength }} Zeichen)</b> *</label>
            <button
                v-if="aiPurpose"
                type="button"
                class="outline small fit-content"
                @click="showAiDialog = true"
            >
                ✨ Mit KI generieren
            </button>
        </div>
        <TiptapEditor
            id="description"
            v-model="description.text"
            :min-length="minLength"
            @total-chars="(n) => description.totalChars = n"
        />
        <progress
            class="editor-progress"
            :max="1500"
            :value="description.totalChars"
        />
        <slot name="hint">
            <p v-if="showHint">
                <b>Unsere Empfehlung:</b> schreibe ca. 200 Wörter - das ist optimal für die Suchmaschinenoptimierung, so wirst du besser gefunden!
                Stelle dich / dein Unternehmen kurz vor und beschreibe z.B. deine Leistungen und Kompetenzen.
                Denke daran, dass das die Besucher deines Anbieterprofils von dir überzeugen soll!
            </p>
        </slot>
    </div>
</template>

<script setup>
const { minLength, showHint, aiPurpose } = defineProps({
    minLength: { type: Number, default: 250 },
    showHint: { type: Boolean, default: true },
    aiPurpose: { type: String, required: false, default: null }
})
const description = defineModel({ type: Object })

const showAiDialog = ref(false)
const links = ref([''])
const totalLinks = computed(() => links.value.length)
const hasEmptyLinks = computed(() => links.value.some(link => link.length === 0))
function addLink () {
    if (totalLinks.value > 2) return
    links.value.push('')
}

const { $backendApi } = useNuxtApp()
const toast = useToast()
const isLoading = ref(false)
async function generateDescription () {
    const validLinks = links.value.filter(link => link.length > 0 && link.startsWith('https'))
    if (!validLinks.length) {
        return toast.error('Bitte gib mindestens einen Link ein!')
    }
    isLoading.value = true
    try {
        const { aiDescription } = await $backendApi('/content/ai-description', {
            method: 'POST',
            body: {
                links: validLinks,
                purpose: aiPurpose
            }
        })
        description.value.text = aiDescription
        showAiDialog.value = false
    } catch (error) {
        handleBackendError({ error, toast })
    }
    isLoading.value = false
}
</script>

<style scoped>
.editor-progress { margin-top: 0.5rem; }
input, label { margin-bottom: 0; width: 100%; }
.mt-auto { margin-top: auto; }
</style>
