<template>
    <form @submit.prevent="submitForm">
        <label for="title">Headline des Angebots *
            <input
                id="title"
                v-model="couponData.title"
                type="text"
                placeholder="z.B. -50% auf die erste Unterrichtsstunde"
                required
            >
        </label>
        <label for="content">Beschreibung *
            <textarea
                id="content"
                v-model="couponData.content"
                required
                placeholder="Beschreibe hier das Angebot genauer: z.B. die Bedingungen, oder weitere Vorteile"
            />
        </label>
        <label for="couponCode">Gutschein-Code (Optional)
            <input
                id="couponCode"
                v-model="couponData.couponCode"
                placeholder="z.B. MINUS-50, Nutzer werden diesen Code kopieren können"
                type="text"
            >
        </label>
        <label for="expiresAt">Ende des Angebots (Das Angebot wird zu diesem Zeitpunkt automatisch entfernt) *
            <input
                id="meeting-time"
                v-model="couponData.expiresAt"
                type="datetime-local"
                name="meeting-time"
                :min="new Date().toISOString().slice(0, 16)"
                required
            >
        </label>
        <label>
            <input
                v-model="couponData.useBusinessLink"
                type="checkbox"
            >
            Link vom Anbieterprofil übernehmen
        </label>
        <label class="mt-1">
            Link zu dem Angebot (Optional)
            <input
                v-model="couponData.link"
                :disabled="couponData.useBusinessLink"
                type="url"
                placeholder="z.B. https://www.allekommen.at/angebot"
            >
        </label>
        <button
            :disabled="submitDisabled"
            :aria-busy="loading"
            type="submit"
        >
            Fertig
        </button>
    </form>
</template>

<script setup>
const props = defineProps({
    method: { type: String, default: 'POST' }
})
const couponData = defineModel({ type: Object })
const emit = defineEmits(['done'])

const couponsStore = useCouponsStore()
const toast = useToast()

const submitDisabled = computed(() => {
    const cd = couponData.value
    return !(cd.title && cd.content && cd.expiresAt) || (!cd.useBusinessLink && !cd.link)
})

const loading = ref(false)
async function submitForm () {
    loading.value = true
    try {
        if (props.method === 'POST') {
            await couponsStore.createBusinessCoupon(couponData.value)
            toast.success('Angebot erstellt!')
        } else {
            await couponsStore.updateBusinessCoupon(couponData.value)
            toast.success('Angebot aktualisiert!')
        }
        emit('done')
    } catch (error) {
        handleBackendError({ error, toast })
    }
    loading.value = false
}
</script>

<style scoped>
form { margin-bottom: 0; }
</style>
