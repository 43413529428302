<template>
    <section>
        <h1>Gewinne noch mehr Interessenten aus deinem Servicegebiet</h1>
        <p>Starte in vier einfachen Schritten.</p>
        <TimeLine :points="points" />
    </section>
</template>

<script setup>
const localePath = useLocalePath()

const serviceStore = useServiceStore()
const { business } = storeToRefs(useBusinessStore())
const hasServices = computed(() => serviceStore.hasServices)
const { hasContactInfo } = useUser()

const { $myT } = useNuxtApp()
const mainDomain = $myT('mainAppDomain')

const points = [{
    title: 'Hinterlege deine Kontaktdaten für deinen persönlichen Ansprechpartner',
    text: 'Du stehst bei uns im Mittelpunkt. Damit wir dir schnell und effektiv helfen können, freuen wir uns auf einen Kontakt mit dir!',
    linkUrl: localePath('dashboard-settings-contact-info'),
    linkText: hasContactInfo.value ? 'Kontaktdaten bearbeiten' : 'Kontaktdaten hinterlegen',
    completed: hasContactInfo.value,
    disabled: hasContactInfo.value
}, {
    title: 'Erstelle dein Anbieterprofil',
    text: `Dein Anbieterprofil ist wie eine Visitenkarte auf ${mainDomain}, du kannst es individuell mit Informationen anreichern, um es attraktiver für die Kunden zu machen.`,
    linkUrl: !business.value ? localePath('dashboard-business-new') : localePath('dashboard-business-edit'),
    linkText: !business.value ? 'Jetzt Anbieterprofil erstellen' : 'Anbieterprofil bearbeiten',
    completed: !!business.value,
    disabled: !hasContactInfo.value || !!business.value
}, {
    title: 'Erstelle deine erste Service-Leistung',
    text: 'Für jede Dienstleistung oder Lieferservice legst du einen eigenen Service an. Beschreibe darin, was du anbietest und in welchem Gebiet. Das ermöglicht es dir, für verschiedene Services verschiedene Regionen anzugeben.',
    linkUrl: localePath('dashboard-services-new'),
    linkText: 'Jetzt Service erstellen',
    completed: hasServices.value,
    disabled: !business.value || hasServices.value
},
{
    title: 'Profileinrichtung abschließen',
    text: 'Der letzte Schritt, bevor dein Angebot live geht!',
    linkUrl: localePath('dashboard-activate-profile'),
    linkText: 'Jetzt abschließen',
    completed: business.value?.stripeSubscriptionId,
    disabled: !hasContactInfo.value || !business.value || !hasServices.value || business.value?.stripeSubscriptionId
}]

const allPointsCompleted = computed(() => {
    return points.every(point => point.completed)
})
const router = useRouter()
onMounted(() => {
    if (allPointsCompleted.value) {
        router.push(localePath('dashboard'))
    }
})
</script>

<style scoped>
</style>
