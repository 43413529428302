<template>
    <div class="flex-row">
        <label for="monthly-budget">
            Dein Monatsbudget
            <input
                id="monthly-budget"
                v-model="monthlyBudget"
                min="30"
                type="number"
                step="10"
                placeholder="bspw. 250€"
            >
        </label>
        <button :aria-busy="loading" class="submit" @click="save">
            Speichern
        </button>
    </div>
</template>

<script setup>
const { $backendApi } = useNuxtApp()
const { business } = storeToRefs(useBusinessStore())

const monthlyBudget = ref(business.value.monthlyBudget)
const toast = useToast()
const loading = ref(false)
async function save () {
    if (!monthlyBudget.value || monthlyBudget.value < 30) {
        return toast.error('Monatsbudget muss mindestens 30 € betragen.')
    }
    loading.value = true
    try {
        await $backendApi(`/businesses/${business.value.id}/monthly-budget`, {
            method: 'PUT',
            body: { monthlyBudget: monthlyBudget.value }
        })
        business.value.monthlyBudget = monthlyBudget.value
        toast.success('Monatsbudget erfolgreich gespeichert!')
    } catch (error) {
        handleBackendError({ error, toast })
    }
    loading.value = false
}
</script>

<style scoped>
.flex-row {
    align-items: flex-end;
    justify-content: flex-start;
}
label { margin: 0; }
#monthly-budget {
    display: block;
    width: 11rem;
    margin: 0;
}
.submit {
    width: fit-content;
    padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
}
</style>
