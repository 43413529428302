<template>
    <form @submit.prevent="next">
        <h2>Kontaktinformationen</h2>
        <InputBusinessContactInfo v-model="newContactInfo" />
        <button class="mt-1" type="submit" :disabled="!linksDifferentAndValid">
            Weiter
        </button>
    </form>
</template>

<script setup>
const emit = defineEmits(['next'])

const { $clientLocalStorage } = useNuxtApp()
const savedState = $clientLocalStorage.getItem({ item: 'newBusinessProcess', parseJson: true }) ?? {}

const newContactInfo = ref({
    email: savedState?.contactInfo?.email || '',
    phone: savedState?.contactInfo?.phone || '',
    websiteLink: savedState?.contactInfo?.websiteLink || '',
    customerServiceLink: savedState?.contactInfo?.customerServiceLink || ''
})
const { linksDifferentAndValid } = useBusinessContactInfo(newContactInfo.value)

function next () {
    savedState.contactInfo = newContactInfo.value
    $clientLocalStorage.setItem({
        item: 'newBusinessProcess',
        value: savedState
    })
    emit('next')
}
</script>

<style scoped>

</style>
