<template>
    <form
        v-if="!submitted"
        method="post"
        @submit.prevent="resendEmailConfirmationLink"
    >
        <input
            id="email"
            v-model="email"
            type="email"
            name="email"
            placeholder="E-Mail Adresse eingeben"
            required
            maxlength="255"
        >
        <label for="newsletter">
            <input
                id="newsletter"
                v-model="newsletter"
                type="checkbox"
                name="newsletter"
            >
            Newsletter mit exklusiven Angeboten, Tipps und Neuigkeiten anmelden
        </label>
        <button class="mt-1" :aria-busy="submitting">
            Neuen Link anfragen
        </button>
    </form>
    <p v-else class="centered">
        Danke!
    </p>
</template>

<script setup>
const toast = useToast()
const { $backendApi } = useNuxtApp()

const email = ref('')
const submitting = ref(false)
const newsletter = ref(false)
const submitted = ref(false)

async function resendEmailConfirmationLink () {
    submitting.value = true
    try {
        await $backendApi('auth/confirm-email/resend-confirmation', {
            method: 'POST',
            body: {
                email: email.value,
                userType: 'company',
                newsletter: newsletter.value
            }
        })
        toast.success('Anfrage erfolgreich.')
        submitted.value = true
    } catch (error) {
        handleBackendError({ error, toast })
    }
    submitting.value = false
}

</script>

<style scoped>
</style>
