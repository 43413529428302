export function useEditDescription (initialValue, saveCallback, additionalParameters = {}) {
    const editDescription = ref(false)
    const description = ref({ text: initialValue, totalChars: 0 })
    const loading = ref(false)
    const descriptionTooShort = computed(() => description.value.totalChars < 250)

    const toast = useToast()
    async function saveDescription () {
        loading.value = true
        try {
            await saveCallback({ description: description.value.text, ...additionalParameters })
            toast.success('Gespeichert.')
            editDescription.value = false
        } catch (error) {
            handleBackendError({ error, toast })
        }
        loading.value = false
    }

    return {
        editDescription,
        description,
        loading,
        descriptionTooShort,
        saveDescription
    }
}
