<template>
    <div class="dropzone-section">
        <p class="title">
            <span>{{ selectedFiles.length }} Bilder gewählt</span>
            <span>Max. 5 MB / Datei</span>
        </p>
        <div class="dropzone-container" @dragover.prevent @drop="handleDrop">
            <!-- Use a label element to associate the file input -->
            <label class="dropzone" for="fileInput">
                <span class="dropzone-card">
                    <img
                        src="/assets/icons/camera.svg"
                        alt="Camera Icon"
                        draggable="false"
                    >
                    Hier klicken oder Dateien hineinziehen
                </span>
                <input
                    id="fileInput"
                    ref="fileInput"
                    type="file"
                    multiple
                    style="display: none"
                    accept=".jpg,.jpeg,.png,.gif,.webp"
                    @change="handleFileChange"
                >
            </label>
        </div>
        <div class="thumbnails">
            <div v-for="(file, index) in selectedFiles" :key="index" class="thumbnail" draggable="false">
                <img :src="file.previewURL" alt="Preview" draggable="false">
                <span class="close" @click="removeThumbnail(index)">
                    <img
                        src="/icons/xmark-solid.svg"
                        alt="Delete Icon"
                    >
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
function handleDrop (event) {
    event.preventDefault()
    handleFiles(event.dataTransfer.files)
}
function handleFileChange (event) {
    handleFiles(event.target.files)
}

const selectedFiles = defineModel({ type: Array, required: true })
function handleFiles (files) {
    for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const reader = new FileReader()
        // Check if the file with the same name already exists in selectedFiles
        const existingFile = selectedFiles.value.find(
            f => f.file.name === file.name
        )
        if (!existingFile) {
            reader.onload = (e) => {
                const previewURL = e.target.result
                selectedFiles.value.push({ file, previewURL })
            }
            reader.readAsDataURL(file)
        }
    }
}

function removeThumbnail (index) {
    // Remove the thumbnail at the specified index from the selectedFiles array
    selectedFiles.value.splice(index, 1)
}
</script>

<style scoped>
.dropzone-section {
    background-color: var(--white);
    border-radius: var(--border-radius);
    padding: var(--spacing);
}
.dropzone-container {
    position: relative;
    height: 10rem; /* Customize the height as needed */
    border: 2px dashed var(--neutral-200);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--neutral-100);
    padding: var(--spacing);
    transition: .2s ease-in-out;
}
.dropzone-container:hover { background-color: #dfdfdf; }
.dropzone {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.dropzone-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: var(--spacing);
    row-gap: var(--spacing);
    text-align: center;
}
.thumbnails {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing);
    margin-top: 1rem;
    max-height: 15rem;
    overflow-y: auto;
}
.thumbnail {
    width: auto;
    height: 8rem;
    position: relative;
}
.thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.close {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    background-color: var(--white);
    height: 2rem;
    width: 2rem;
    display: grid;
    place-items: center;
    border-radius: 50%;
    border: 1px solid var(--dark-blue);
    cursor: pointer;
}
.close img {
    height: 1rem;
    width: 1rem;
}
.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.danger { color: var(--red); }
/* Hide default input styles */
input[type="file"] {
    display: none;
}
</style>
