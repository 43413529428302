<template>
    <div class="michael-card">
        <img src="/assets/images/michael.jpg" alt="Michael" class="michael-image">
        <div class="centered">
            <h2>
                Brauchst du Unterstützung? Ich bin für dich da!
            </h2>
            <NuxtLink role="button" class="yellow" :to="localePath('free-consulting')">
                Mit Michael sprechen
            </NuxtLink>
        </div>
    </div>
</template>

<script setup>
const localePath = useLocalePath()
</script>

<style scoped>
.michael-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: var(--spacing);
    background-color: var(--white);
    border-radius: var(--border-radius);
    padding: var(--spacing);
    max-width: 18rem;
    margin-left: auto;
    margin-right: auto;
    box-shadow: var(--small-shadow);
}
.michael-image {
    width: 100%;
    max-width: 10rem;
    border-radius: var(--border-radius);
    box-shadow: var(--small-shadow);
}
.centered h2 {
    font-size: 1rem;
}
@media (min-width: 768px) {
    .michael-card {
        max-width: 15rem;
    }
}
</style>
