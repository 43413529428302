<template>
    <StaticContentLoader name="Restaurants-mit-Lieferservice" />
</template>

<script setup>
definePageMeta({
    auth: false,
    layout: 'standard'
})
useHead({
    title: 'Gastro-Revolution: Restaurants mit Lieferservice erobern neue Horizonte der Kundengewinnung'
})
</script>
