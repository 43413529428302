<template>
    <div class="back" @click="navigate">
        <img
            class="icon-container"
            src="/assets/icons/chevron-left-solid.svg"
            alt="Back Icon"
            loading="lazy"
        >
        {{ props.text }}
    </div>
</template>

<script setup>
const props = defineProps({
    link: { type: String, required: false, default: null },
    text: { type: String, required: false, default: 'Zurück' }
})

const router = useRouter()
/**
 * Method to handle navigation when the button is clicked.
 * If a link is specified, it uses Vue Router to navigate to the specified route.
 * Otherwise, it goes back to the previous page in the browser history.
 */
function navigate () {
    props.link ? router.push(props.link) : router.go(-1)
}
</script>

<style scoped>
.back {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    cursor: pointer;
    width: fit-content;
}
</style>
