<template>
    <form @submit.prevent="next">
        <h2>Firmenadresse</h2>
        <InputAddressFields v-model="address" />
        <p>* Pflichtfelder</p>
        <button type="submit" :aria-busy="loading">
            Weiter
        </button>
    </form>
</template>

<script setup>
const emit = defineEmits(['next'])

// common composables
const toast = useToast()
const { $clientLocalStorage } = useNuxtApp()
const savedState = $clientLocalStorage.getItem({ item: 'newBusinessProcess', parseJson: true }) ?? {}

const address = ref({
    streetName: savedState?.address?.streetName || '',
    houseNumber: savedState?.address?.houseNumber || '',
    zipCode: savedState?.address?.zipCode || '',
    city: savedState?.address?.city || '',
    lat: savedState?.address?.lat || null,
    lon: savedState?.address?.lon || null
})
const loading = ref(false)

async function next () {
    loading.value = true
    try {
        const addressString = `${address.value.streetName} ${address.value.houseNumber}, ${address.value.zipCode} ${address.value.city}`
        const locations = await $fetch('/api/address-search/geocoding', {
            query: { address: addressString }
        })
        if (!locations) { return toast.error('Die Adresse konnte nicht gefunden werden') }
        const { lat, lon } = locations[0]
        address.value.lat = lat
        address.value.lon = lon
        savedState.address = address.value
        $clientLocalStorage.setItem({
            item: 'newBusinessProcess',
            value: savedState
        })
        emit('next')
    } catch (error) {
        handleBackendError({ error, toast })
    }
    loading.value = false
}
</script>
