<template>
    <tr>
        <td>
            <input v-if="edit" v-model="product.name" type="text" placeholder="Produktbezeichnung">
            <span v-else>{{ product.name }}</span>
        </td>
        <td>
            <input v-if="edit" v-model="product.price" type="number" placeholder="Preis in €">
            <span v-else>{{ product.price }}</span>
        </td>
        <td class="icons">
            <ActionIcon
                v-if="!edit && !confirmDelete"
                icon="pen-solid"
                :position-absolute="false"
                @click="enableEdit"
            />
            <ActionIcon
                v-if="edit && !confirmDelete"
                icon="check-solid"
                :position-absolute="false"
                @click="save"
            />
            <ActionIcon
                v-if="!confirmDelete"
                icon="trash-can-solid"
                :position-absolute="false"
                @click="confirmDelete = true"
            />
            <button v-if="confirmDelete" :aria-busy="deleting" class="small" @click="deleteProduct">
                Löschen
            </button>
        </td>
    </tr>
</template>

<script setup>
const props = defineProps({
    serviceId: { type: Number, required: true },
    product: { type: Object, required: true }
})
const emit = defineEmits(['new-edit-value', 'delete-product', 'delete-new-product'])

const product = ref(props.product)
const edit = ref(!!props.product.edit)
function enableEdit () {
    edit.value = true
    emit('new-edit-value', true)
}

const serviceStore = useServiceStore()
const toast = useToast()
async function save () {
    if (!product.value.name || !product.value.price) {
        toast.warning('Bitte alle Produktfelder ausfüllen.')
        return
    }
    try {
        if (!product.value.id) {
            product.value.id = await serviceStore.createProduct({
                serviceId: props.serviceId,
                product: { name: product.value.name, price: product.value.price }
            })
            product.value.edit = false
        } else {
            await serviceStore.updateProduct({
                serviceId: props.serviceId,
                productId: product.value.id,
                product: { name: product.value.name, price: product.value.price }
            })
        }
        toast.success('Erfolgreich gespeichert!')
        emit('new-edit-value', false)
    } catch (error) {
        handleBackendError({ error, toast })
    }
    edit.value = false
}

const confirmDelete = ref(false)
const deleting = ref(false)
async function deleteProduct () {
    deleting.value = true
    edit.value = false
    if (!product.value.id) {
        return emit('delete-new-product')
    }
    try {
        await serviceStore.deleteProduct({
            serviceId: props.serviceId,
            productId: product.value.id
        })
        toast.success('Erfolgreich gelöscht!')
        emit('delete-product', product.value.id)
    } catch (error) {
        handleBackendError({ error, toast })
    }
    confirmDelete.value = false
    deleting.value = false
}
</script>

<style scoped>
input { margin-bottom: 0; }
td { padding: 0.3rem; height: 3rem; }
.icons { display: flex; column-gap: 0.5rem; align-items: center; }
button { margin-bottom: 0; }
</style>
