<template>
    <div>
        <h2>Auszug einiger unserer überzeugten Partner</h2>
        <div
            v-if="partners[config.locale].length !== 0"
            class="logo-container"
        >
            <a
                v-for="(partner, index) in partners[config.locale]"
                :key="index"
                :href="`https://www.${$myT('mainAppDomain')}/anbieter/${partner.permanentName}`"
                target="_blank"
            >
                <BoxForSlot class="container">
                    <template #content>
                        <img
                            :src="`${SPACES_URL}/images/partner-logos/${partner.image}`"
                            :alt="`${partner.name} ist Partner von ${$myT('mainAppDomain')}`"
                            class="logo-img"
                        >
                    </template>
                </BoxForSlot>
            </a>
            <BoxForSlot class="container">
                <template #content>
                    <NuxtLink
                        :to="localePath('become-partner')"
                        role="button"
                        type="submit"
                        class="link-btn"
                    >
                        Partner Profil erstellen
                    </NuxtLink>
                </template>
            </BoxForSlot>
        </div>
    </div>
</template>

<script setup>
const localePath = useLocalePath()
const config = useRuntimeConfig().public
const SPACES_URL = config.spacesUrl

const partners = {
    at: [

        {
            name: 'Lieferando',
            permanentName: 'lieferando-osterreich-191709',
            image: 'lieferando.png'
        },
        {
            name: 'Haushaltshilfe24',
            permanentName: 'haushaltshilfe24-85411',
            image: 'haushaltshilfe24.webp'
        },
        {
            name: 'MyHammer',
            permanentName: 'myhammer-191711',
            image: 'myhammer.png'
        },
        {
            name: 'Idealo',
            permanentName: 'idealo-85387',
            image: 'idealo.webp'
        },
        {
            name: 'HalloBabysitter',
            permanentName: 'babysitter-at-85421',
            image: 'babysitter.gif'
        },
        {
            name: 'Betreut',
            permanentName: 'betreut-85389',
            image: 'betreut.png'
        },
        {
            name: 'Alfies',
            permanentName: 'alfies-191743',
            image: 'alfies.png'
        },
        {
            name: 'Extrasauber',
            permanentName: 'extrasauber-191744',
            image: 'extrasauber.jpg'
        },
        {
            name: 'Embodymental',
            permanentName: 'embodymental-health-connection-85959',
            image: 'embodymental.jpg'
        },
        {
            name: 'Vienntus',
            permanentName: 'vienntus-191745',
            image: 'vienntus.jfif'
        },
        {
            name: 'Le Ciel Vintage',
            permanentName: 'le-ciel-vintage-191523',
            image: 'le-ciel-vintage.jpg'
        }
    ],
    de: []
}
</script>

<style scoped>
.logo-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, auto);
    gap: var(--spacing);
    justify-content: center;
    align-items: center;
}
.logo-container .container {
    text-align: center;
    height: 8rem;
    position: relative;
    overflow: hidden;
}

.logo-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    max-height: 7rem;
}

.logo-container .container.link-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

/****** Media Queries ******/
/* Medium devices (landscape tablets, large phones) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .logo-container {
        grid-template-columns: repeat(3, 1fr);
    }
}
/* Small devices (portrait tablets, large phones) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
    .logo-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
/* Extra-small devices (swipeable carousel) */
@media only screen and (max-width: 599px) {
    .logo-container {
        display: flex;
        gap: calc(var(--spacing) * 2);
        flex-direction: row;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        padding: 0.2rem;
        margin: 0 auto;
        scroll-padding-left: var(--spacing);
        justify-content: flex-start;
    }
    .logo-container > a, .logo-container .container {
        flex: 0 0 80vw;
        scroll-snap-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-sizing: border-box;
    }
    .logo-container .container {
        min-height: 9.3rem;
        width: 80%;
        margin-bottom: 1rem;
    }
    .logo-img, .link-btn {
        object-fit: contain;
        width: 100%;
        max-height: 80%;
    }
    .link-btn {
        height: auto;
        padding: var(--spacing);
        width: 80%;
    }
}
</style>
