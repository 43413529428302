<template>
    <div class="form-container">
        <label for="websiteLink">
            Website Link. Falls Du keine Webseite hast, kannst Du hier auch
            einen Link zu Social Media angeben. *
            <input
                id="websiteLink"
                v-model="contactInfo.websiteLink"
                type="url"
                required
                name="websiteLink"
                placeholder="https://www.deine-webseite.at"
                @blur="validateLink(contactInfo.websiteLink)"
            >
        </label>
        <div class="phone-email-container">
            <label for="businessemail">
                E-Mail <span v-if="emailRequired">*</span>
                <input
                    id="businessemail"
                    v-model="contactInfo.email"
                    type="email"
                    :aria-invalid="emailRequired && !contactInfo.email ? 'true' : ''"
                    name="businessemail"
                    placeholder="E-Mail eingeben"
                >
            </label>
            <label for="businessphone">
                Telefonnummer
                <input
                    id="businessphone"
                    v-model="contactInfo.phone"
                    type="tel"
                    name="businessphone"
                    placeholder="+43..."
                    maxlength="40"
                    minlength="6"
                >
            </label>
        </div>
        <label for="customerServiceLink">
            Link zu Deiner Webseite in den Bereich Kontakt oder Kundenservice
            <input
                id="customerServiceLink"
                v-model="contactInfo.customerServiceLink"
                type="url"
                name="customerServiceLink"
                placeholder="https://www.dein-customer-service.at"
                @blur="validateLink(contactInfo.customerServiceLink)"
            >
        </label>
    </div>
</template>

<script setup>
const contactInfo = defineModel({
    type: Object
})

const { linksEqual } = useBusinessContactInfo(contactInfo.value)
const toast = useToast()

const emailRequired = computed(() => contactInfo.value.enableContactForm)

function validateLink (link) {
    if (!link || link.length === 0) { return }
    if (!link.startsWith('https')) {
        return toast.error('Bitte https im Link ergänzen!')
    }
    if (linksEqual.value) {
        return toast.error('Bitte unterschiedliche Links verwenden!')
    }
}
</script>

<style scoped>
input {
    margin-bottom: 0;
}
.form-container {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing);
}
@media (min-width: 768px) {
    .phone-email-container {
        display: grid;
        width: calc(100% - var(--spacing));
        grid-template-columns: 60% 40%;
        column-gap: var(--spacing);
    }
}
</style>
