<template>
    <dialog open>
        <article>
            <CloseIcon @close="emit('close')" />
            <h3>Mehr Bilder hochladen</h3>
            <p>
                Gute Bilder werten deine Service-Page sehr stark auf. Oft ist es das Erste, was der Besucher wahrnimmt.
                <strong>Wir empfehlen zumindest drei Bilder hinzuzufügen</strong>.
            </p>
            <DropZone v-model="newImages" class="mt-1" />
            <button :aria-busy="loading" class="mt-1" @click="upload">
                Hochladen
            </button>
        </article>
    </dialog>
</template>

<script setup>
const props = defineProps({
    serviceId: { type: Number, required: true }
})
const emit = defineEmits(['close'])

const newImages = ref([])
const loading = ref(false)
const toast = useToast()
const serviceStore = useServiceStore()
async function upload () {
    if (newImages.value.length === 0) { return }
    loading.value = true
    try {
        await serviceStore.uploadServiceImages({
            images: newImages.value, serviceId: props.serviceId
        })
        toast.success('Upload erfolgreich.')
        newImages.value = []
        emit('close')
    } catch (error) {
        handleBackendError({ error, toast })
    }
    loading.value = false
}
</script>

<style scoped>

</style>
