<template>
    <label for="national">
        <input
            id="national"
            v-model="national"
            type="checkbox"
            role="switch"
            name="national"
        >
        <span :aria-busy="loading">Das Service ist nationalweit verfügbar</span>
    </label>
</template>

<script setup>
defineProps({ loading: Boolean })
const national = defineModel({ type: Boolean, default: false })
</script>

<style scoped>

</style>
