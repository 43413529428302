import * as Sentry from '@sentry/vue'
import { GOOGLE_GEOCODING_API_KEY } from '~/constants'

/**
 * Retrieves the locations for a given address.
 * @param {string} address - The address to retrieve locations for.
 * @param {Object} toast - The toast object for displaying error messages.
 * @return {Promise<Array>} - A promise that resolves to an array of location results.
 */
export async function getLocationsForAddress (address, toast) {
    const gmapsQuery = `address=${encodeURIComponent(address)}&key=${GOOGLE_GEOCODING_API_KEY}&result_type=street_address&language=de`
    const gmapsReverseUrl = `https://maps.googleapis.com/maps/api/geocode/json?${gmapsQuery}`
    try {
        const { results } = await $fetch(gmapsReverseUrl)
        return results
    } catch (error) {
        toast.error(error.message)
    }
}

export function handleBackendError ({ error, toast }) {
    Sentry.captureException(error)
    console.log(error.response)
    if (!error.response) {
        toast.error(error.message)
    } else if (error.response.status === 500) {
        toast.error('Es ist ein Fehler passiert.')
    } else if (error.response.status === 404) {
        toast.error('Anfrage am Server nicht gefunden.')
    } else if (error.response._data.msg) {
        toast.error(error.response._data.msg)
    } else {
        error.response._data.errors.forEach((error) => {
            toast.error(error)
        })
    }
}

export function trackGoaffproOrder (user) {
    if (typeof window.goaffproTrackConversion === 'undefined') {
        return
    }
    const { firstName, lastName, contactPhone, contactEmail } = user
    window.goaffpro_order = {
        number: `Registrierung ${user.id}`,
        total: 0.01,
        customer: {
            first_name: firstName,
            last_name: lastName,
            phone: contactPhone,
            email: contactEmail,
            is_new_customer: true
        },
        date: new Date().toISOString()
    }
    window.goaffproTrackConversion(window.goaffpro_order)
}

/**
 * Copy the provided text to the clipboard and show success or error toast messages.
 * @param {Object} options - The options object.
 * @param {string} options.text - The text to be copied to the clipboard.
 * @param {Object} options.toast - The toast notification object.
 * @return {Promise<void>} Promise that resolves once the text is copied to the clipboard.
 */
export async function copyToClipboard ({ text, toast }) {
    try {
        await navigator.clipboard.writeText(text)
        toast.success('In die Zwischenablage kopiert!')
    } catch (err) {
        toast.error('Konnte nicht in die Zwischenablage kopieren!')
        console.error('Failed to copy!', err)
    }
}

export const referralText = (code, mainDomain) => `Schließe dich mir und tausenden Anbietern an und präsentiere dich auf allekommen!
Erhalte zum Start 50 € mit diesem Gutschein-Code: ${code}
Mehr Informationen: ${mainDomain}`

/**
 * A function that shares text, URL, and title using the Web Share API.
 * @param {string} text - The text to share.
 * @param {string} url - The URL to share.
 * @param {string} title - The title to share.
 * @return {Promise<void>} A promise that resolves when the sharing is complete.
 */
export async function share (text, url, title) {
    const toast = useToast()
    if (navigator.share) {
        try {
            await navigator.share({ title, text, url })
        } catch (e) {}
    } else {
        await navigator.clipboard.writeText(url)
        toast.success('Link in die Zwischenablage kopiert!')
    }
}

/**
 * Converts a timestamp in seconds to a formatted date string in the 'de-AT' locale.
 * @param {number} timestampInSeconds - The timestamp in seconds to convert to a date.
 * @param options
 * @return {string} The formatted date string in the 'de-AT' locale.
 */
export function convertTimestampToDate (timestampInSeconds, options) {
    const timestampInMilliseconds = timestampInSeconds * 1000
    const date = new Date(timestampInMilliseconds)
    const optionsToUse = options || { year: 'numeric', month: 'long', day: 'numeric' }
    return date.toLocaleDateString('de-AT', optionsToUse)
}

export function formatCentsPrice (cents) {
    return (cents / 100).toFixed(2)
}

export function getYesterdaysDate () {
    const date = new Date()
    date.setDate(date.getDate() - 1)
    return date.toLocaleDateString('de-AT', { year: 'numeric', month: 'long', day: 'numeric' })
}
