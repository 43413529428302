<template>
    <div>
        <h2>Wähle die Kategorie</h2>
        <ServiceCategorySelector v-model="categoryId" />
        <button class="mt-2" :disabled="!categoryId" @click="next">
            Weiter
        </button>
    </div>
</template>

<script setup>
const emit = defineEmits(['next'])

const { $clientLocalStorage } = useNuxtApp()
const savedState = $clientLocalStorage.getItem({ item: 'newServiceProcess', parseJson: true }) ?? {}

const categoryId = ref(savedState?.categoryId || null)

function next () {
    savedState.categoryId = categoryId.value
    $clientLocalStorage.setItem({
        item: 'newServiceProcess',
        value: savedState
    })
    emit('next')
}
</script>

<style scoped>

</style>
