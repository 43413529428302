<template>
    <section>
        <NavigationGoBack
            :link="localePath('dashboard')"
            text="Zurück zum Dashboard"
        />
        <div class="title-space-between mt-1">
            <h1>Service bearbeiten</h1>
            <a
                :href="servicePublicLink"
                target="_blank"
            >Zum Service auf {{ $myT('mainAppDomain') }}</a>
        </div>
        <BoxForSlot
            class="mt-1"
            :fit-content="true"
        >
            <template #content>
                <div class="flex-row">
                    <ScoreCircleIndicator
                        data-tooltip="Profilstärke"
                        :rating="service.profileScore"
                        :height="5"
                    />
                    <div>
                        <p>
                            Deine aktuelle Profilstärke für diesen Service
                            <br>
                            <span v-if="service.profileScore < 100">Füge mehr Informationen hinzu, um diesen Wert zu verbessern!</span>
                            <span v-else>Du hast ein perfektes Service erstellt!</span>
                        </p>
                        <div v-if="service.profileScore < 100">
                            <p class="mb-0">
                                Tipps für diesen Service:
                            </p>
                            <ul>
                                <li>Achte auf eine umfangreiche Beschreibung (idealerweise 1000 Zeichen)</li>
                                <li>Lade ein Titelbild für die Servicecard hoch</li>
                                <li>Erstelle ein Produkt mit einem Preis</li>
                                <li>Lade drei weiter Service-Bilder hoch</li>
                                <li>Fülle die Informationen zur Nachhaltigkeit aus</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </template>
        </BoxForSlot>

        <div class="flex-row mt-1">
            <NuxtLink
                role="button"
                class="outline"
                :to="basicInfoLink"
            >
                Kategorie & Servicegebiete
            </NuxtLink>
            <NuxtLink
                role="button"
                class="outline"
                :to="detailedInfoLink"
            >
                Detailansicht
            </NuxtLink>
        </div>
        <ServiceEditBasicInfo
            v-if="route.query.view === 'basic' || !route.query.view"
            :service-id="service?.id"
            class="mt-2"
        />
        <LazyServiceEditDetailedInfo
            v-else-if="route.query.view === 'detail'"
            class="mt-2"
        />
    </section>
</template>

<script setup>
const route = useRoute()
const serviceStore = useServiceStore()
const service = computed(() => serviceStore.getServiceById(Number(route.params.id)))
const localePath = useLocalePath()

const basicInfoLink = localePath({
    name: 'dashboard-services-edit-id',
    params: { id: service.value?.id },
    query: { view: 'basic' }
})
const detailedInfoLink = localePath({
    name: 'dashboard-services-edit-id',
    params: { id: service.value?.id },
    query: { view: 'detail' }
})

const toast = useToast()
const businessStore = useBusinessStore()
const router = useRouter()

onMounted(() => {
    if (!businessStore.business) {
        toast.info('Bitte lege zuerst ein Anbieterprofil an.')
        return router.push(localePath('dashboard'))
    }
    if (!service.value) {
        toast.info('Service nicht gefunden.')
        return router.push(localePath('dashboard'))
    }
})

const servicePublicLink = computed(() => {
    return `${businessStore.businessPublicLink}/mobil/${service.value?.permanentName}-${service.value?.id}`
})

useHead({
    title: 'Service bearbeiten'
})
</script>

<style scoped>
.flex-row { justify-content: flex-start; }
.title-space-between {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: var(--spacing);
}
h1 { margin-bottom: 0; }
</style>
