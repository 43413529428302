<template>
    <p class="register-note">
        Mit der Autorisierung stimme ich den
        <a
            :href="`https://www.${$myT('mainAppDomain')}/datenschutz`"
            target="_blank"
        >
            Datenschutzbestimmungen
        </a>
        und den
        <a
            :href="`https://www.${$myT('mainAppDomain')}/nutzungsbedingungen`"
            target="_blank"
        >
            Nutzungsbedingungen
        </a>
        zu.
    </p>
</template>

<script setup>
</script>

<style scoped>
.register-note {
    margin-top: var(--spacing);
    font-size: 0.6rem;
    text-align: center;
}
p { margin: 0; }
</style>
