<template>
    <section class="ptb-1">
        <NavigationGoBack />

        <h1 class="faq-title">
            FAQ - Business
        </h1>

        <div class="faq-item">
            <h3 class="faq-item__question">
                Warum sollte ich als Unternehmen mobile Dienstleistungen anbieten?
            </h3>
            <div class="faq-item__answer">
                Mobile Dienstleistungen bieten Dir viele Vorteile. Hier sind einige Gründe, warum Du
                mobile Dienstleistungen anbieten solltest:
                <ul>
                    <li>
                        Bequemlichkeit für die Kunden: Mobile Dienstleistungen ermöglichen es Dir,
                        Dich von zu Hause aus um Deine Bedürfnisse zu kümmern, ohne dass du Dich
                        auf den Weg machen musst.
                    </li>
                    <li>
                        Erweiterung des Kundenkreises: Durch das Angebot von mobilen
                        Dienstleistungen kannst Du Deinen Kundenkreis erweitern, indem Du Kunden
                        erreichst, die sich an anderen Orten befinden oder nicht in der Lage sind, zu
                        einem festen Geschäftslokal zu kommen.
                    </li>
                    <li>
                        Mehr Flexibilität und Effizienz: Mobile Dienstleistungen ermöglichen es Dir,
                        Deine Arbeitszeiten flexibler zu gestalten und Deine Arbeitsrouten zu
                        optimieren, was zu einer höheren Effizienz beitragen kann.
                    </li>
                    <li>
                        Möglichkeit, Dich von der Konkurrenz abzuheben: Indem Du mobile
                        Dienstleistungen anbietest, kannst Du Dich von der Konkurrenz abheben und
                        dich als innovativ und kundenorientiert positionieren.
                    </li>
                    <li>
                        Steigerung der Sichtbarkeit: Durch das Angebot von mobilen Dienstleistungen
                        auf {{ $myT('mainAppDomain') }} kannst Du deine Sichtbarkeit auf der Plattform erhöhen und
                        somit auch Deine Chancen, von potenziellen Kunden gefunden zu werden,
                        steigern
                    </li>
                </ul>
            </div>
        </div>

        <div class="faq-item">
            <h3 class="faq-item__question">
                Warum ist es wichtig, auf einer Plattform für mobile Dienstleister
                gefunden zu werden?
            </h3>
            <p class="faq-item__answer">
                Weil immer mehr Menschen online nach mobilen Dienstleistungen suchen! Wenn du
                auf einer Plattform für mobile Dienstleister gefunden wirst, hast Du die Chance, von
                potenziellen Kunden entdeckt zu werden und somit neue Geschäftsmöglichkeiten zu
                eröffnen. Das ist mega wichtig! 😎
            </p>
            <p class="faq-item__answer">
                Ein Eintrag auf einer solchen Plattform (wie {{ $myT('mainAppDomain') }}) kann Dir helfen, deine
                Sichtbarkeit zu erhöhen und Dich von der Konkurrenz abzuheben. Wenn du auf einer
                Plattform für mobile Dienstleister gefunden wirst, können potenzielle Kunden Dich
                leichter finden und kontaktieren.
            </p>
        </div>

        <div class="faq-item">
            <h3 class="faq-item__question">
                Was muss gegeben sein, damit ein Unternehmen auf {{ $myT('mainAppDomain') }}
                gelistet werden kann?
            </h3>
            <p class="faq-item__answer">
                Damit ein Unternehmen auf {{ $myT('mainAppDomain') }} gelistet werden kann, muss es über eine
                Website oder eine andere Online-Präsenz verfügen, die von {{ $myT('mainAppDomain') }} verifiziert werden kann. Das ist wichtig, damit wir sicherstellen können, dass das Unternehmen
                echt ist und dass es tatsächlich existiert.
            </p>
            <p class="faq-item__answer">
                Das Unternehmen muss auch bereit sein, Kundenbewertungen und -kommentare auf
                {{ $myT('mainAppDomain') }} zuzulassen. Das ist wichtig, damit potenzielle Kunden sehen können,
                was andere Kunden über das Unternehmen denken.
            </p>
            <p class="faq-item__answer">
                Schließlich darf das Unternehmen keine illegalen oder unethischen Aktivitäten
                betreiben. Wir möchten sicherstellen, dass alle Unternehmen auf {{ $myT('mainAppDomain') }} fair
                und ethisch handeln.
            </p>
        </div>

        <h2 class="faq-subtitle">
            Der Unternehmenseintrag
        </h2>

        <div class="faq-item">
            <h3 class="faq-item__question">
                Wie kannst Du deinen Unternehmenseintrag für Kunden attraktiv
                gestalten?
            </h3>
            <p class="faq-item__answer">
                Um Deinen Unternehmenseintrag auf {{ $myT('mainAppDomain') }} attraktiv zu gestalten, empfehlen
                wir, eine aussagekräftige Beschreibung Deines Unternehmens und Deiner
                Dienstleistungen zu verfassen, relevante Keywords zu verwenden und attraktive
                Bilder hinzuzufügen. Das ist mega wichtig! 😎
            </p>
            <p class="faq-item__answer">
                Der beste Weg, um Deine Dienstleistungen auf {{ $myT('mainAppDomain') }} zu beschreiben, ist es,
                klar und präzise zu sein und die Vorteile deines Angebots hervorzuheben. Vermeide
                Fachjargon und versuche, die Dienstleistungen auf eine Weise zu beschreiben, die für
                den durchschnittlichen Kunden verständlich ist.
            </p>
            <p class="faq-item__answer">
                Kundenbewertungen und -empfehlungen können deinem Unternehmenseintrag auf
                {{ $myT('mainAppDomain') }} eine vertrauenswürdige und glaubwürdige Note verleihen. Sorge dafür,
                dass Deine Kunden deinen Service bei {{ $myT('mainAppDomain') }} bewerten.
            </p>
        </div>

        <div class="faq-item">
            <h3 class="faq-item__question">
                Was macht ein gutes Bild für den Unternehmenseintrag auf {{ $myT('mainAppDomain') }}
                aus?
            </h3>
            <p class="faq-item__answer">
                Für den Unternehmenseintrag auf {{ $myT('mainAppDomain') }} eignen sich am besten Bilder, die das
                Unternehmen oder die Dienstleistungen visuell darstellen und die Aufmerksamkeit
                des Betrachters auf sich ziehen. Das können zum Beispiel Produktfotos, Aufnahmen
                von Deinem Unternehmensgebäude oder Deinen Mitarbeitern oder auch grafische
                Darstellungen Deines Angebots sein. Vermeide allgemeine Stockfotos und achte
                darauf, dass die Bilder einzigartig und relevant für Dein Unternehmen sind.
            </p>
            <p class="faq-item__answer">
                Um sicherzustellen, dass Deine Bilder von hoher Qualität sind, empfehlen wir, sie von
                einem professionellen Fotografen aufnehmen zu lassen oder professionelle
                Bildbearbeitungssoftware zu verwenden. Stelle auch sicher, dass das Bild im
                richtigen Format und der richtigen Größe hochgeladen wird, damit es auf
                {{ $myT('mainAppDomain') }} gut angezeigt wird.
            </p>
            <p class="faq-item__answer">
                Wir bei {{ $myT('mainAppDomain') }} prüfen die Bilder und stellen sicher, dass es unseren
                Qualitätsstandards entspricht. Wenn Du Fragen dazu hast oder Hilfe bei der
                Erstellung Deines Unternehmenseintrags benötigst, lass es uns bitte wissen.
            </p>
        </div>

        <h2 class="faq-subtitle">
            Suchergebnisse
        </h2>

        <div class="faq-item">
            <h3 class="faq-item__question">
                Was beeinflusst das Ranking auf {{ $myT('mainAppDomain') }}?
            </h3>
            <div class="faq-item__answer">
                Das Ranking von Unternehmen auf {{ $myT('mainAppDomain') }} wird von verschiedenen Faktoren
                beeinflusst, darunter:
                <ul>
                    <li>
                        Die Anzahl und die Qualität der Bewertungen, die der Unternehmensservice
                        erhält
                    </li>
                    <li>
                        Die Häufigkeit, mit der das Unternehmen auf {{ $myT('mainAppDomain') }} gesucht und
                        aufgerufen wird
                    </li>
                    <li>
                        Die Relevanz des Unternehmens für die Suchanfrage eines Benutzers
                    </li>
                    <li>
                        Die Qualität der Informationen, die das Unternehmen auf seiner
                        {{ $myT('mainAppDomain') }}-Seite bereitstellt
                    </li>
                </ul>
            </div>
        </div>

        <div class="faq-item">
            <h3 class="faq-item__question">
                Wie kann ich das Ranking meines Unternehmens auf {{ $myT('mainAppDomain') }}
                verbessern?
            </h3>
            <div class="faq-item__answer">
                Um das Ranking deines Unternehmens auf {{ $myT('mainAppDomain') }} zu verbessern, kannst Du
                folgende Schritte unternehmen:
                <ul>
                    <li>
                        Sammle so viele wie möglich positive Bewertungen von zufriedenen Kunden
                    </li>
                    <li>
                        Stelle sicher, dass Deine {{ $myT('mainAppDomain') }}-Seite vollständig und aktuell ist und alle
                        relevanten Informationen enthält, wie Servicegebiet, -zeiten, Kontaktinformationen und Fotos
                    </li>
                    <li>
                        Verwende relevante Keywords in deinem Unternehmensnamen und in der Beschreibung Deines Unternehmens, um bei relevanten Suchen gefunden zu
                        werden
                    </li>
                    <li>
                        Interagiere mit Benutzern, die Dein Unternehmen auf {{ $myT('mainAppDomain') }} bewerten
                        oder suchen, indem Du auf Bewertungen antwortest und Fragen beantwortest
                    </li>
                </ul>
            </div>
        </div>

        <h2 class="faq-subtitle">
            Kundenbewertungen
        </h2>

        <div class="faq-item">
            <h3 class="faq-item__question">
                Wie kann ich zu einer höheren Anzahl an Kundenbewertungen
                kommen?
            </h3>

            <div class="faq-item__answer">
                <ul>
                    <li>
                        Bitte zufriedene Kunden, eine Bewertung zu hinterlassen. Du kannst sie direkt
                        im Geschäft oder auf deiner Website darum bitten.
                    </li>
                    <li>
                        Du kannst auch auf deiner Website oder in deinem Geschäft auf deinen
                        MyLocalVerse-Eintrag hinweisen und darum bitten, dass deine Kunden dort
                        eine Bewertung hinterlassen.
                    </li>
                    <li>
                        Nutze soziale Medien, um über dein Geschäft und seine Dienstleistungen zu
                        informieren und Kunden dazu zu ermutigen, eine Bewertung zu hinterlassen.
                    </li>
                    <li>
                        Mache dich bei lokalen Netzwerkveranstaltungen und Geschäftsverbänden
                        bekannt, um neue Kunden zu gewinnen und ihnen die Möglichkeit zu geben,
                        eine Bewertung zu hinterlassen.
                    </li>
                    <li>
                        Biete Kunden einen Anreiz, eine Bewertung zu hinterlassen. Dies kann
                        beispielsweise ein Rabatt auf ihren nächsten Einkauf sein.
                    </li>
                    <li>
                        Antworte auf alle Bewertungen, sowohl positiv als auch negativ. Dies zeigt,
                        dass dein Geschäft Kundenfeedback ernst nimmt und bietet Kunden die
                        Möglichkeit, sich gehört und verstanden zu fühlen.
                    </li>
                    <li>
                        Stelle sicher, dass dein Geschäft immer einen hervorragenden Kundenservice
                        bietet, damit Kunden motiviert sind, eine positive Bewertung zu hinterlassen.
                    </li>
                </ul>
            </div>
        </div>

        <div class="faq-item">
            <h3 class="faq-item__question">
                Wie zu guten Kundenbewertungen kommen?
            </h3>

            <div class="faq-item__answer">
                Um gute Kundenbewertungen zu erhalten, solltest Du Dich bemühen, Deinen Kunden
                eine hohe Qualität und guten Service zu bieten. Einige Möglichkeiten, wie Du gute
                Bewertungen erhalten kannst, sind:
                <ul>
                    <li>
                        Bemühe Dich, die Bedürfnisse und Wünsche Deiner Kunden zu verstehen und
                        ihnen einen persönlichen und individuellen Service zu bieten.
                    </li>
                    <li>
                        Stelle eine hohe Qualität der Produkte und Dienstleistungen sicher, um die
                        Zufriedenheit Deiner Kunden zu gewährleisten
                    </li>
                    <li>
                        Lege ein freundliches und professionelles Verhalten gegenüber Deinen
                        Kunden an den Tag und stelle sicher, dass sie sich willkommen und
                        wertgeschätzt fühlen.
                    </li>
                    <li>
                        Löse Probleme und Beschwerden Deiner Kunden schnell und effektiv und
                        biete ihnen zufriedenstellende Lösungen an.
                    </li>
                    <li>
                        Ermutige Deine Kunden dazu, ihre Meinung und ihr Feedback mitzuteilen, um
                        Verbesserungsmöglichkeiten zu erkennen und Dein Unternehmen
                        weiterzuentwickeln
                    </li>
                    <li>
                        Bemühe Dich, die Kundenbeziehungen langfristig zu pflegen und auszubauen,
                        um eine treue Kundenbasis aufzubauen.
                    </li>
                </ul>
            </div>
        </div>

        <div class="faq-item">
            <h3 class="faq-item__question">
                Kann ich Kunden für positive Bewertungen belohnen?
            </h3>
            <p class="faq-item__answer">
                Ja, das kannst Du! Du kannst beispielsweise einen Rabatt auf ihren nächsten Einkauf
                oder ein kostenloses Upgrade für ihren nächsten Dienstleistungsauftrag anbieten.
                Auf diese Weise zeigst Du Deinen zufriedenen Kunden, dass Du ihr Feedback schätzt
                und sie für ihre Unterstützung belohnst.
            </p>
        </div>

        <div class="faq-item">
            <h3 class="faq-item__question">
                Wie umgehen mit schlechten Kundenbewertungen?
            </h3>
            <div class="faq-item__answer">
                Es ist wichtig, mit schlechten Kundenbewertungen sorgfältig und professionell
                umzugehen, um das Vertrauen der Kunden in das Unternehmen zu bewahren und
                den Schaden zu minimieren. Einige Möglichkeiten, wie Du mit schlechten
                Bewertungen umgehen kannst, sind:
                <ul>
                    <li>
                        Lies die Bewertungen sorgfältig und versuche zu verstehen, was den Kunden
                        gestört hat und wie das Problem behoben werden kann.
                    </li>
                    <li>
                        Antworte dem Kunden in einer ruhigen und professionellen Art und Weise und
                        bedanke Dich dafür, dass er sich die Zeit genommen hat, eine Bewertung zu
                        schreiben.
                    </li>
                    <li>
                        Bemühe Dich, das Problem des Kunden zu lösen und ihm eine
                        zufriedenstellende Lösung anzubieten.
                    </li>
                    <li>
                        Überzeuge andere Kunden davon, dass Dein Unternehmen sich bemüht,
                        Probleme zu lösen und zufriedenstellende Lösungen anzubieten.
                    </li>
                    <li>
                        Überprüfe regelmäßig die Kundenbewertungen und bemühe Dich, Dein
                        Unternehmen kontinuierlich zu verbessern.
                    </li>
                    <li>
                        Reagiere darauf via {{ $myT('mainAppDomain') }} auf die Kundenbewertungen (nur nach
                        Registrierung des Unternehmens möglich).
                    </li>
                    Es ist wichtig zu beachten, dass schlechte Bewertungen nicht immer negativ sein
                    müssen. Sie können auch als Chance dienen, Dein Unternehmen zu verbessern und
                    das Vertrauen der Kunden zu stärken.
                </ul>
            </div>
        </div>
    </section>
</template>

<script setup>
definePageMeta({
    auth: false,
    layout: 'standard'
})
useHead({
    title: 'Partner FAQ'
})
</script>

<style scoped>
.faq-title {
    margin: var(--spacing);
    text-align: center;
}
.faq-subtitle {
    margin-top: 40px;
    margin-bottom: 10px;
    color: var(--primary);
}
.faq-item {
    margin-top: 20px;
}
.faq-item__question {
    font-weight: bolder;
    font-size: 1rem;
}
.faq-item__answer:last-child {
    margin: 0;
}
.faq-item__answer ul {
    margin: 10px;
}
.faq-item__answer ul li:last-child {
    margin: 0;
}
</style>
