<template>
    <form class="day-container" @submit.prevent="emit('confirm')">
        <div v-for="(entry, index) in openingHours" :key="index">
            <p class="day-name">
                <strong>{{ dayOfWeekString(entry.dayOfWeek) }}</strong>
                <i v-if="entry.uponRequest"> (auf Anfrage)</i>
                <i v-else-if="entry.openingTime === '' && entry.closingTime === ''"> (geschlossen)</i>
                <i v-else> (von, bis)</i>
            </p>
            <div class="time-inputs">
                <input
                    v-model="entry.openingTime"
                    data-name="timeInput"
                    type="time"
                    :disabled="entry.uponRequest"
                    @blur="(event) => validateTimeInput(event.target)"
                >
                <input
                    v-model="entry.closingTime"
                    data-name="timeInput"
                    type="time"
                    :disabled="entry.uponRequest"
                    @blur="(event) => validateTimeInput(event.target)"
                >
                <label :for="'custom' + index" class="custom-checkbox">
                    <input
                        :id="'custom' + index"
                        v-model="entry.uponRequest"
                        type="checkbox"
                        :name="'custom' + index"
                    >
                    Auf Anfrage
                </label>
            </div>
        </div>
        <button :aria-busy="loading" :disabled="timeInvalid" class="mt-1">
            {{ buttonText }}
        </button>
    </form>
</template>

<script setup>
import { WEEKDAYS_MAPPING } from '~/constants'

const emit = defineEmits(['confirm'])
defineProps({
    loading: { type: Boolean, default: false },
    buttonText: { type: String, default: 'Speichern' }
})

const openingHours = defineModel({ type: Array })

onMounted(() => {
    const inputs = document.querySelectorAll('input[data-name="timeInput"]')
    inputs.forEach(input => validateTimeInput(input))
})

const timeInvalid = ref(false)
function validateTimeInput (input) {
    timeInvalid.value = !input.validity.valid
    if (input.value.length === 0) {
        input.removeAttribute('aria-invalid')
        return
    }
    input.validity.valid
        ? input.setAttribute('aria-invalid', 'false')
        : input.setAttribute('aria-invalid', 'true')
}

function dayOfWeekString (dayIndex) {
    return WEEKDAYS_MAPPING[dayIndex]
}
</script>

<style scoped>
.day-name, input[data-name="timeInput"] { margin-bottom: 0.3rem; }
.time-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: var(--spacing);
    width: calc(100% - var(--spacing));
}
.day-container {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing);
    margin-top: var(--spacing);
}
@media (min-width: 768px) {
    .time-inputs {
        grid-template-columns: 1fr 1fr 0.8fr;
        width: calc(100% - 2 * var(--spacing));
    }
    .custom-checkbox, input[data-name="timeInput"] { margin: auto 0; }
}
</style>
