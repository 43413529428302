<template>
    <section>
        <h1>Hallo, {{ data?.firstName }}!</h1>
        <GetStartedWithSubscription
            v-if="!business?.stripeSubscriptionId"
            class="mt-1"
        />
        <ScoreOverallIndicator class="mt-1" />
        <CreditClaimBox
            v-if="data?.availableCreditClaims > 0"
            v-model="data.availableCreditClaims"
            class="mt-1"
        />
        <BoxForSlot
            v-else
            :fit-content="true"
            class="mt-1"
        >
            <template #content>
                <p><b>Hol dir bis zu 585 € in Prämien mit deinem Anbieterprofil!</b></p>
                <p>
                    Das Prämienprogramm von {{ $myT('mainAppDomain') }} für Anbieter: Mit jedem erreichten
                    Meilenstein wartet eine attraktive Guthaben-Prämie auf dich.
                    Lass uns gemeinsam Erfolge feiern!
                </p>
                <NuxtLink
                    role="button"
                    class="yellow small"
                    :to="localePath('rewards')"
                >
                    <img
                        src="/assets/icons/lightbulb-regular.svg"
                        alt="Mehr Infos"
                        title="Mehr Infos"
                        class="inline-image"
                    > Mehr Infos
                </NuxtLink>
            </template>
        </BoxForSlot>
        <section class="mt-2">
            <div class="space-between">
                <h2>Statistiken</h2>
                <NuxtLink :to="localePath('dashboard-statistics')">
                    Mehr Infos
                </NuxtLink>
            </div>
            <StatisticsDashboardSummary />
        </section>
        <section
            id="infos"
            class="mt-2"
        >
            <h2>Mein Anbieterprofil</h2>
            <BoxForSlot>
                <template #content>
                    <h3 class="flex-start">
                        {{ business.name }}
                        <ScoreCircleIndicator :rating="business.profileScore" />
                    </h3>
                    <NuxtLink
                        role="button"
                        :to="localePath('dashboard-business-edit')"
                    >
                        Anbieterprofil bearbeiten
                    </NuxtLink>
                    <div class="space-between mt-1">
                        <InputShareButton
                            button-text="Profil teilen"
                            :url="businessPublicLink"
                            :title="`${business.name} auf ${$myT('mainAppDomain')}`"
                        />
                        <BusinessPublicLink />
                    </div>
                </template>
            </BoxForSlot>
        </section>
        <section class="mt-2">
            <div class="space-between">
                <h2 class="service-title">
                    Meine Services
                </h2>
                <NuxtLink
                    role="button"
                    :to="localePath('dashboard-services-new')"
                >
                    Service erstellen
                </NuxtLink>
            </div>
            <ServiceGridWrapper
                class="mt-1"
                :services-wrapper-small="false"
            >
                <template #content>
                    <MichaelCard />
                    <ServiceSearchCard
                        v-for="service in serviceStore.services.slice(0, 3)"
                        :key="service.id"
                        :service="service"
                    />
                </template>
            </ServiceGridWrapper>
            <NuxtLink
                v-if="totalServices > 3"
                class="block-link mt-1"
                :to="localePath('dashboard-services')"
            >
                Alle Services
            </NuxtLink>
        </section>
    </section>
</template>

<script setup>
const localePath = useLocalePath()
const serviceStore = useServiceStore()
const totalServices = computed(() => serviceStore.totalServices)

const { data } = useAuthState()
const { business, businessPublicLink } = storeToRefs(useBusinessStore())

useHead({
    title: 'Dashboard'
})
</script>

<style scoped>
.dashboard-cards-container a {
    text-decoration: none;
}
h2.service-title { margin-bottom: 0; }
.action-links button { width: fit-content; }
.inline-image {
    width: 1.2rem;
    height: 1.2rem;
}
</style>
