<template>
    <StaticContentLoader name="als-privatanbieter-listen" />
</template>

<script setup>
definePageMeta({
    auth: false,
    layout: 'standard'
})
useHead({
    title: 'Als Privatanbieter listen - sei dabei!'
})
</script>
