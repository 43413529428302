<template>
    <button
        :aria-busy="loading"
        class="small logout-link"
        @click="logout"
    >
        <img src="/assets/icons/logout-white.svg" alt="Logout Icon" loading="lazy">
        <span>Abmelden</span>
    </button>
</template>

<script setup>
const { $backendApi } = useNuxtApp()
const { signOut } = useAuth()
const { refreshToken } = useAuthState()
const toast = useToast()
const loading = ref(false)

async function logout () {
    loading.value = true
    await $backendApi('/auth/logout', { method: 'DELETE', body: { refresh_token: refreshToken.value } })
    await signOut({ redirect: true, callbackUrl: '/' })
    toast.success('Abgemeldet')
    loading.value = false
}
</script>

<style scoped>
a {
    margin-top: auto;
}
.logout-link {
    width: 100%;
    padding: 0 0 0 1.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: transparent;
    border: none;
    margin-top: auto;
    transition: all var(--transition);
    color: var(--white);
}
.logout-link:hover {
    gap: 1.7rem;
}
</style>
