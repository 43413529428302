<template>
    <div class="search-bar">
        <input
            id="search"
            v-model="searchTerm"
            type="search"
            name="search"
            :placeholder="props.placeholder"
            @keyup.enter="emit('search')"
        >
        <button
            class="outline"
            type="button"
            :aria-busy="props.loading"
            @click="emit('search')"
        >
            Suchen
        </button>
    </div>
</template>

<script setup>
const props = defineProps({
    loading: {
        type: Boolean,
        required: true
    },
    placeholder: {
        type: String,
        required: false,
        default: 'Suchbegriff'
    }
})
const searchTerm = defineModel({ type: String })
const emit = defineEmits(['search'])
</script>

<style scoped>
.search-bar {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    place-items: center;
}
button, input[type="search"] { margin: 0; }
#search { grid-area: 1 / 1 / 1 / 3; }
.search-bar button { grid-area: 1 / 3 / 1 / 4; }
</style>
