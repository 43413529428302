<template>
    <div>
        <InputImageUpload
            :selected-file="selectedFile"
            unique-identifier="new-business"
            @remove-file="selectedFile = null"
            @update:selected-file="(file) => selectedFile = file"
        >
            <template #dropzone-card>
                <img
                    src="/assets/icons/camera.svg"
                    alt="Camera Icon"
                    draggable="false"
                >
                Hier klicken, um Businesslogo hochzuladen<br>
                Empfehlung: Verhältnis 4:3, ca. 400 x 300px
            </template>
        </InputImageUpload>
        <button :class="{ 'outline': !selectedFile?.file }" class="mt-1" :aria-busy="uploading" @click="createBusiness">
            {{ selectedFile?.file ? 'Erstellen' : 'Ohne Logo erstellen' }}
        </button>
    </div>
</template>

<script setup>
const emit = defineEmits(['next'])

const { $clientLocalStorage } = useNuxtApp()
const savedState = $clientLocalStorage.getItem({ item: 'newBusinessProcess', parseJson: true }) ?? {}

const toast = useToast()
const businessStore = useBusinessStore()
const selectedFile = ref(null)
const uploading = ref(false)

async function createBusiness () {
    uploading.value = true
    try {
        const socialMediaLinks = {
            facebookLink: savedState?.facebookLink,
            linkedinLink: savedState?.linkedinLink,
            instagramLink: savedState?.instagramLink,
            youtubeLink: savedState?.youtubeLink,
            tiktokLink: savedState?.tiktokLink
        }
        await businessStore.createBusiness({
            name: savedState?.providerName,
            description: savedState?.description,
            address: savedState?.address,
            contactInfo: savedState?.contactInfo,
            logo: selectedFile.value?.file,
            socialMediaLinks
        })
        toast.success('Upload erfolgreich')
        selectedFile.value = null
        emit('next')
    } catch (error) {
        handleBackendError({ error, toast })
    }
    uploading.value = false
}
</script>

<style scoped>

</style>
