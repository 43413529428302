<template>
    <table>
        <tr>
            <td />
            <th>Super günstig</th>
            <th>Fast geschenkt</th>
        </tr>
        <tr>
            <td>Sofort Reichweite</td>
            <td><PricingCheckIcon /></td>
            <td><PricingCheckIcon /></td>
        </tr>
        <tr>
            <td>Anbieterprofil als Mini-Webseite</td>
            <td><PricingCheckIcon /></td>
            <td><PricingCheckIcon /></td>
        </tr>
        <tr>
            <td>Unlimitierte Anzahl an Service-Leistungen</td>
            <td><PricingCheckIcon /></td>
            <td><PricingCheckIcon /></td>
        </tr>
        <tr>
            <td>Unlimitierte Anzahl an Kundenanfragen</td>
            <td><PricingCheckIcon /></td>
            <td><PricingCheckIcon /></td>
        </tr>
        <tr>
            <td>Top-Platzierung in der Suche</td>
            <td><PricingCheckIcon /></td>
            <td />
        </tr>
        <tr>
            <td>Persönlicher Ansprechpartner</td>
            <td><PricingCheckIcon /></td>
            <td />
        </tr>
        <tr>
            <td>Werbefrei</td>
            <td><PricingCheckIcon /></td>
            <td />
        </tr>
        <tr>
            <td>1 Monat geschenkt</td>
            <td><PricingCheckIcon /></td>
            <td />
        </tr>
        <tr>
            <td />
            <td>
                <button class="yellow" :aria-busy="loading" @click="bookTier(2)">
                    {{ annual ? '3 Monate' : '1 Monat' }} geschenkt
                </button>
            </td>
            <td>
                <button class="outline" :aria-busy="loading" @click="bookTier(1)">
                    Jetzt starten
                </button>
            </td>
        </tr>
    </table>
</template>

<script setup>
const { annual } = defineProps({
    annual: { type: Boolean, required: false, default: false }
})

const localePath = useLocalePath()
const { isAuthenticated } = useUser()
function bookTier (tier) {
    if (!isAuthenticated.value) {
        return navigateTo(localePath('login'))
    }
    redirectToStripeCheckout({ tier, annual: annual.value })
}

const { loading, redirectToStripeCheckout } = useStripeCheckout()
</script>

<style scoped>
td, th {
   border-bottom-color: var(--dark-blue);
   text-align: center;
}
tr:last-child td { border-bottom: none; }
th { font-weight: bold; }
td:first-child { text-align: left; }
table {
    box-shadow: var(--small-shadow);
    background-color: var(--white);
    border-radius: var(--border-radius);
    margin-left: auto;
    margin-right: auto;
}
</style>
