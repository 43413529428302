<template>
    <DialogInfo @close="emit('close')">
        <template #title>
            <h3>Anbieterprofil mit 5 Bewertungen</h3>
        </template>
        <template #content>
            <ul>
                <li>
                    Motiviere deine Kunden zur Bewertung deiner Services auf {{ $myT('mainAppDomain') }} und teile dein Anbieterprofil!
                </li>
                <li>
                    Den Meilenstein hast du bereits mit 5 unterschiedlichen Bewertungen erreicht.
                </li>
            </ul>
        </template>
    </DialogInfo>
</template>

<script setup>
const emit = defineEmits(['close'])
</script>
