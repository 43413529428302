export const GOOGLE_MAPS_PUBLIC_API_KEY = 'AIzaSyDBoE_dxtFkg5wSmWt7SEc8eYCm0YFYJ3c'
export const GOOGLE_GEOCODING_API_KEY
    = process.env.GEOCODING_API_KEY ?? 'AIzaSyBRWXlY0mN0qqyyAkClSQvJMHtS_0vhz60'

export const WEEKDAYS_MAPPING = {
    0: 'Montag',
    1: 'Dienstag',
    2: 'Mittwoch',
    3: 'Donnerstag',
    4: 'Freitag',
    5: 'Samstag',
    6: 'Sonntag'
}

export const QUOTES = [
    {
        text: 'Als Physiotherapeut und Osteopath schätze ich die Möglichkeit, meine Services direkt anbieten zu können. allekommen.at ist als Plattform einfach und effizient für Dienstleistungen mit und am Menschen. Danke!',
        author: 'Lukas von embodymental',
        image: 'lukas-small.jpg'
    },
    {
        text: 'allekommen.at bietet Kunden eine super einfachen Zugang und eine optimale Übersicht zu Dienstleistungen in Österreich oder Unternehmen wie VIENNTUS. Wir freuen uns ein Teil der Plattform zu sein und freuen uns über die zukünftige Zusammenarbeit!',
        author: 'Stefan & Adrian, Gründer von VIENNTUS',
        image: 'vienntus.jpg'
    },
    {
        text: 'Die Zusammenarbeit mit allekommen.at  ist wirklich großartig, auch werden jegliche Fragen oder Anliegen innerhalb kürzester Zeit beantwortet. Schön, dass es Partner gibt, die sich auch um einen kümmern. 💪',
        author: 'Geschäftsführer Walter Wallner, CAD Holzbautechnik',
        image: 'walter.jpg'
    }
]

export const COUNTRY_LIST = [
    { label: 'Afghanistan', value: 'Afghanistan' },
    { label: 'Ägypten', value: 'Egypt' },
    { label: 'Albanien', value: 'Albania' },
    { label: 'Algerien', value: 'Algeria' },
    { label: 'Andorra', value: 'Andorra' },
    { label: 'Angola', value: 'Angola' },
    { label: 'Antigua und Barbuda', value: 'Antigua and Barbuda' },
    { label: 'Äquatorialguinea', value: 'Equatorial Guinea' },
    { label: 'Argentinien', value: 'Argentina' },
    { label: 'Armenien', value: 'Armenia' },
    { label: 'Aserbaidschan', value: 'Azerbaijan' },
    { label: 'Äthiopien', value: 'Ethiopia' },
    { label: 'Australien', value: 'Australia' },
    { label: 'Bahamas', value: 'Bahamas' },
    { label: 'Bahrain', value: 'Bahrain' },
    { label: 'Bangladesch', value: 'Bangladesh' },
    { label: 'Barbados', value: 'Barbados' },
    { label: 'Belarus', value: 'Belarus' },
    { label: 'Belgien', value: 'Belgium' },
    { label: 'Belize', value: 'Belize' },
    { label: 'Benin', value: 'Benin' },
    { label: 'Bhutan', value: 'Bhutan' },
    { label: 'Bolivien', value: 'Bolivia' },
    { label: 'Bosnien und Herzegowina', value: 'Bosnia and Herzegovina' },
    { label: 'Botswana', value: 'Botswana' },
    { label: 'Brasilien', value: 'Brazil' },
    { label: 'Brunei', value: 'Brunei' },
    { label: 'Bulgarien', value: 'Bulgaria' },
    { label: 'Burkina Faso', value: 'Burkina Faso' },
    { label: 'Burundi', alue: 'Burundi' },
    { label: 'Cabo Verde', value: 'Cape Verde' },
    { label: 'Chile', value: 'Chile' },
    { label: 'China', value: 'China' },
    { label: 'Costa Rica', value: 'Costa Rica' },
    { label: 'Dänemark', value: 'Denmark' },
    { label: 'Deutschland', value: 'Germany' },
    { label: 'Dominica', value: 'Dominica' },
    { label: 'Dominikanische Republik', value: 'Dominican Republic' },
    { label: 'Dschibuti', value: 'Djibouti' },
    { label: 'Ecuador', value: 'Ecuador' },
    { label: 'Eritrea', value: 'Eritrea' },
    { label: 'Estland', value: 'Estonia' },
    { label: 'Eswatini', value: 'Eswatini (formerly Swaziland)' },
    { label: 'Fidschi', value: 'Fiji' },
    { label: 'Finnland', value: 'Finland' },
    { label: 'Frankreich', value: 'France' },
    { label: 'Gabun', value: 'Gabon' },
    { label: 'Gambia', value: 'Gambia' },
    { label: 'Georgien', value: 'Georgia' },
    { label: 'Ghana', value: 'Ghana' },
    { label: 'Grenada', value: 'Grenada' },
    { label: 'Griechenland', value: 'Greece' },
    { label: 'Guatemala', value: 'Guatemala' },
    { label: 'Guinea', value: 'Guinea' },
    { label: 'Guinea-Bissau', value: 'Guinea-Bissau' },
    { label: 'Guyana', value: 'Guyana' },
    { label: 'Haiti', value: 'Haiti' },
    { label: 'Honduras', value: 'Honduras' },
    { label: 'Indien', value: 'India' },
    { label: 'Indonesien', value: 'Indonesia' },
    { label: 'Irak', value: 'Iraq' },
    { label: 'Iran', value: 'Iran' },
    { label: 'Irland', value: 'Ireland' },
    { label: 'Island', value: 'Iceland' },
    { label: 'Israel', value: 'Israel' },
    { label: 'Italien', value: 'Italy' },
    { label: 'Jamaika', value: 'Jamaica' },
    { label: 'Japan', value: 'Japan' },
    { label: 'Jemen', value: 'Yemen' },
    { label: 'Jordanien', value: 'Jordan' },
    { label: 'Kambodscha', value: 'Cambodia' },
    { label: 'Kamerun', value: 'Cameroon' },
    { label: 'Kanada', value: 'Canada' },
    { label: 'Kasachstan', value: 'Kazakhstan' },
    { label: 'Katar', value: 'Qatar' },
    { label: 'Kenia', value: 'Kenya' },
    { label: 'Kirgisistan', value: 'Kyrgyzstan' },
    { label: 'Kiribati', value: 'Kiribati' },
    { label: 'Kolumbien', value: 'Colombia' },
    { label: 'Komoren', value: 'Comoros' },
    { label: 'Kongo, Demokratische Republik', value: 'Democratic Republic of the Congo' },
    { label: 'Kongo, Republik', value: 'Republic of the Congo' },
    { label: 'Kosovo', value: 'Kosovo' },
    { label: 'Kroatien', value: 'Croatia' },
    { label: 'Kuba', value: 'Cuba' },
    { label: 'Kuwait', value: 'Kuwait' },
    { label: 'Laos', value: 'Laos' },
    { label: 'Lesotho', value: 'Lesotho' },
    { label: 'Lettland', value: 'Latvia' },
    { label: 'Libanon', value: 'Lebanon' },
    { label: 'Liberia', value: 'Liberia' },
    { label: 'Libyen', value: 'Libya' },
    { label: 'Liechtenstein', value: 'Liechtenstein' },
    { label: 'Litauen', value: 'Lithuania' },
    { label: 'Luxemburg', value: 'Luxembourg' },
    { label: 'Madagaskar', value: 'Madagascar' },
    { label: 'Malawi', value: 'Malawi' },
    { label: 'Malaysia', value: 'Malaysia' },
    { label: 'Malediven', value: 'Maldives' },
    { label: 'Mali', value: 'Mali' },
    { label: 'Malta', value: 'Malta' },
    { label: 'Marokko', value: 'Morocco' },
    { label: 'Marshallinseln', value: 'Marshall Islands' },
    { label: 'Mauretanien', value: 'Mauritania' },
    { label: 'Mauritius', value: 'Mauritius' },
    { label: 'Mexiko', value: 'Mexico' },
    { label: 'Mikronesien', value: 'Micronesia' },
    { label: 'Moldawien', value: 'Moldova' },
    { label: 'Monaco', value: 'Monaco' },
    { label: 'Mongolei', value: 'Mongolia' },
    { label: 'Montenegro', value: 'Montenegro' },
    { label: 'Mosambik', value: 'Mozambique' },
    { label: 'Myanmar (Burma)', value: 'Myanmar (Burma)' },
    { label: 'Namibia', value: 'Namibia' },
    { label: 'Nauru', value: 'Nauru' },
    { label: 'Nepal', value: 'Nepal' },
    { label: 'Neuseeland', value: 'New Zealand' },
    { label: 'Nicaragua', value: 'Nicaragua' },
    { label: 'Niederlande', value: 'Netherlands' },
    { label: 'Niger', value: 'Niger' },
    { label: 'Nigeria', value: 'Nigeria' },
    { label: 'Nordmazedonien', value: 'North Macedonia' },
    { label: 'Norwegen', value: 'Norway' },
    { label: 'Oman', value: 'Oman' },
    { label: 'Österreich', value: 'Austria' },
    { label: 'Osttimor', value: 'East Timor' },
    { label: 'Pakistan', value: 'Pakistan' },
    { label: 'Palau', value: 'Palau' },
    { label: 'Panama', value: 'Panama' },
    { label: 'Papua-Neuguinea', value: 'Papua New Guinea' },
    { label: 'Paraguay', value: 'Paraguay' },
    { label: 'Peru', value: 'Peru' },
    { label: 'Philippinen', value: 'Philippines' },
    { label: 'Polen', value: 'Poland' },
    { label: 'Portugal', value: 'Portugal' },
    { label: 'Ruanda', value: 'Rwanda' },
    { label: 'Rumänien', value: 'Romania' },
    { label: 'Russland', value: 'Russia' },
    { label: 'Salomonen', value: 'Solomon Islands' },
    { label: 'Sambia', value: 'Zambia' },
    { label: 'Samoa', value: 'Samoa' },
    { label: 'San Marino', value: 'San Marino' },
    { label: 'São Tomé und Príncipe', value: 'São Tomé and Príncipe' },
    { label: 'Saudi-Arabien', value: 'Saudi Arabia' },
    { label: 'Schweden', value: 'Sweden' },
    { label: 'Schweiz', value: 'Switzerland' },
    { label: 'Senegal', value: 'Senegal' },
    { label: 'Serbien', value: 'Serbia' },
    { label: 'Seychellen', value: 'Seychelles' },
    { label: 'Sierra Leone', value: 'Sierra Leone' },
    { label: 'Simbabwe', value: 'Zimbabwe' },
    { label: 'Singapur', value: 'Singapore' },
    { label: 'Slowakei', value: 'Slovakia' },
    { label: 'Slowenien', value: 'Slovenia' },
    { label: 'Somalia', value: 'Somalia' },
    { label: 'Spanien', value: 'Spain' },
    { label: 'Sri Lanka', value: 'Sri Lanka' },
    { label: 'St. Kitts und Nevis', value: 'St. Kitts and Nevis' },
    { label: 'St. Lucia', value: 'St. Lucia' },
    { label: 'St. Vincent und die Grenadinen', value: 'St. Vincent and the Grenadines' },
    { label: 'Südafrika', value: 'South Africa' },
    { label: 'Sudan', value: 'Sudan' },
    { label: 'Südsudan', value: 'South Sudan' },
    { label: 'Suriname', value: 'Suriname' },
    { label: 'Syrien', value: 'Syria' },
    { label: 'Tadschikistan', value: 'Tajikistan' },
    { label: 'Tansania', value: 'Tanzania' },
    { label: 'Thailand', value: 'Thailand' },
    { label: 'Togo', value: 'Togo' },
    { label: 'Tonga', value: 'Tonga' },
    { label: 'Trinidad und Tobago', value: 'Trinidad and Tobago' },
    { label: 'Tschad', value: 'Chad' },
    { label: 'Tschechien', value: 'Czech Republic' },
    { label: 'Tunesien', value: 'Tunisia' },
    { label: 'Türkei', value: 'Turkey' },
    { label: 'Turkmenistan', value: 'Turkmenistan' },
    { label: 'Tuvalu', value: 'Tuvalu' },
    { label: 'Uganda', value: 'Uganda' },
    { label: 'Ukraine', value: 'Ukraine' },
    { label: 'Ungarn', value: 'Hungary' },
    { label: 'Uruguay', value: 'Uruguay' },
    { label: 'Usbekistan', value: 'Uzbekistan' },
    { label: 'Vanuatu', value: 'Vanuatu' },
    { label: 'Vatikanstadt (Heiliger Stuhl)', value: 'Vatican City (Holy See)' },
    { label: 'Venezuela', value: 'Venezuela' },
    { label: 'Vereinigte Arabische Emirate', value: 'United Arab Emirates' },
    { label: 'Vereinigtes Königreich', value: 'United Kingdom' },
    { label: 'Vereinigte Staaten von Amerika', value: 'United States of America' },
    { label: 'Vietnam', value: 'Vietnam' },
    { label: 'Zentralafrikanische Republik', value: 'Central African Republic' },
    { label: 'Zypern', value: 'Cyprus' }
]
