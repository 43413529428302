<template>
    <BoxForSlot>
        <template #content>
            <NuxtLink :to="localePath('become-partner')" role="button" class="yellow block-link">
                Jetzt mitmachen und Prämien holen!
            </NuxtLink>
        </template>
    </BoxForSlot>
</template>

<script setup>
const localePath = useLocalePath()
</script>

<style scoped>

</style>
