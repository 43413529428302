<template>
    <DialogInfo @close="emit('close')">
        <template #title>
            <h1 class="mt-1">
                Schenke 50 € und erhalte 50 €
            </h1>
        </template>
        <template #content>
            <p>Verdiene 50 € Guthaben pro geworbenen Anbieter.</p>
            <p>
                Lass Anbieter wissen, dass sie 50 € Startguthaben erhalten, wenn sie
                sich bei {{ $myT('mainAppDomain') }} präsentieren.
            </p>
            <BoxForSlot v-if="isAuthenticated">
                <template #content>
                    Teile jetzt deinen individuellen Gutschein-Code beliebig oft:
                    <b>{{ user?.stripeReferralCode }}</b>
                    <img
                        src="/assets/icons/copy.svg"
                        alt="Kopiere den Gutschein-Code"
                        title="Kopiere den Gutschein-Code"
                        class="copy-icon"
                        @click="copyToClipboard({ text: referralText(user?.stripeReferralCode, mainDomain), toast })"
                    >
                </template>
            </BoxForSlot>
            <RegistrationCtaBox v-else />
            <p class="mt-1">
                Egal ob es sich um Lieferdienste, mobile Dienstleister oder andere Unternehmen
                handelt, die Vor-Ort-Services anbieten – wir belohnen deine Empfehlungen großzügig.
            </p>
            <button
                v-if="isAuthenticated"
                class="outline small share-btn"
                @click="share(referralText(user?.stripeReferralCode, mainDomain), `https://${mainDomain}/anbieter-werden`, 'Erhalte 50€ Guthaben')"
            >
                <img
                    src="/assets/icons/share.svg"
                    alt="Teilen"
                    title="Teilen"
                    class="inline-image"
                > Teilen
            </button>
            <p class="mt-4">
                <i>
                    Bedingungen: Dein individueller Gutschein-Code ist beliebig oft einlösbar - du
                    kannst beliebig viele Anbieter darüber werben. Der empfohlene Anbieter muss
                    ein Neukunde sein. Das Guthaben gilt für Klicks von Interessenten oder eine Listung auf
                    {{ $myT('mainAppDomain') }} und kann nicht in bar ausgezahlt werden. Aktion bis auf Widerruf gültig.
                </i>
            </p>
        </template>
    </DialogInfo>
</template>

<script setup>
const { isAuthenticated, user } = useUser()
const toast = useToast()
const emit = defineEmits(['close'])

const mainDomain = useRuntimeConfig().public.mainDomain
</script>

<style>
.share-btn {
    width: fit-content;
}
.copy-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
}
</style>
