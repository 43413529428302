<template>
    <div class="note-box">
        <p>
            <strong>Du willst mehr Neukunden?</strong>
            Jetzt eine kostenfreie Erstberatung buchen!
        </p>
        <NuxtLink :to="localePath('free-consulting')" role="button" target="_blank">
            Mehr Infos
        </NuxtLink>
    </div>
</template>

<script setup>
const localePath = useLocalePath()
</script>

<style scoped>
p {
    margin: 0;
}
.note-box {
    background-color: var(--primary-100);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--spacing);
    border-radius: var(--border-radius);
    margin: auto;
    row-gap: var(--spacing);
    border: 1px solid var(--primary-300);
    width: fit-content;
}
@media (min-width: 768px) {
    .note-box {
        display: grid;
        grid-template-columns: 2fr 1fr;
        column-gap: var(--spacing);
    }
}
</style>
