<template>
    <div class="coupon-card">
        <span class="angebot">Angebot</span>
        <ActionIcon
            icon="pen-solid"
            :custom-styles="{top: 0}"
            @click="emit('edit', coupon)"
        />
        <p class="title">
            {{ coupon.title }}
        </p>
        <p v-if="coupon.couponCode" class="red-text" @click="copyToClipboard({text: coupon.couponCode, toast})">
            Dein Gutscheincode:
            <span>{{ coupon.couponCode }}</span>
            <img src="assets/icons/copy-red.svg" alt="copy icon" loading="lazy">
        </p>
        <p>
            {{ coupon.content }}
        </p>
        <div class="wrapper">
            <p><small>Einlösbar bis:</small><br><span>{{ formattedDate }}</span></p>
            <a role="button" class="small outline" :href="coupon.link" target="_blank">
                Gutschein einlösen
            </a>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    coupon: { type: Object, required: true }
})
const emit = defineEmits(['edit'])
const toast = useToast()

const timestampInSeconds = new Date(props.coupon.expiresAt).getTime() / 1000
const formattedDate = convertTimestampToDate(timestampInSeconds, { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })
</script>

<style scoped>
.coupon-card {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: var(--border-radius);
    padding: var(--spacing);
    box-shadow: var(--small-shadow);
    border: 1px solid var(--red);
    position: relative;
}
.red-text {
    color: var(--red);
    border-radius: var(--border-radius);
    border: 1px solid var(--red);
    padding: 0.5rem;
    cursor: pointer;
    width: fit-content;
    column-gap: 0.3rem;
    display: flex;
}
.red-text img {
    width: 1rem;
}
.title {
    font-size: 1.1rem;
    font-weight: bold;
    margin-top: 1.5rem;
}
.angebot {
    display: grid;
    place-items: center;
    background-color: var(--red);
    color: var(--white);
    position: absolute;
    top: 0;
    left: 0;
    width: 5rem;
    height: 1.7rem;
    text-align: center;
    font-weight: bold;
    border-radius: var(--border-radius) 0 var(--border-radius) 0;
}
.wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: auto;
}
.wrapper p {
    margin: 0;
}
</style>
