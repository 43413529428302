export const useCouponsStore = defineStore('coupons', () => {
    const { $backendApi } = useNuxtApp()

    const businessCoupons = ref([])
    const totalBusinessCoupons = computed(() => businessCoupons.value.length)

    function setBusinessCoupons (coupons) {
        businessCoupons.value = coupons
    }

    async function createBusinessCoupon (couponData) {
        const coupon = await $backendApi('/coupons/business', {
            method: 'POST',
            body: couponData
        })
        businessCoupons.value.unshift(coupon)
    }
    async function updateBusinessCoupon (couponData) {
        const coupon = await $backendApi(`/coupons/business/${couponData.id}`, {
            method: 'PUT',
            body: {
                title: couponData.title,
                content: couponData.content,
                expiresAt: couponData.expiresAt,
                useBusinessLink: couponData.useBusinessLink,
                link: couponData.link,
                couponCode: couponData.couponCode
            }
        })
        const index = businessCoupons.value.findIndex(c => c.id === coupon.id)
        businessCoupons.value[index] = coupon
    }

    return {
        businessCoupons,
        totalBusinessCoupons,
        setBusinessCoupons,
        createBusinessCoupon,
        updateBusinessCoupon
    }
})
