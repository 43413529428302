<template>
    <div class="single-bar">
        <h2>Bekannt aus</h2>
        <div class="logos-wrapper">
            <div v-for="(award, index) in awards" :key="index">
                <img :src="award.imgSrc" :alt="award.altText" :class="['logo-img', award.class]">
            </div>
        </div>
    </div>
</template>

<script setup>
const awards = [
    {
        imgSrc: '/images/gewinn-logo.png',
        altText: 'Gewinn Logo',
        link: 'https://www.gewinn.com/artikel/plattform-fuer-mobile-services'
    },
    {
        imgSrc: '/images/retail-report-logo.png',
        altText: 'Retail report Logo',
        link: 'https://retailreport.at/noch-penny-chef-maerzinger-startet-mit-locaverseat'
    },
    {
        imgSrc: '/images/brutkasten_logo.png',
        altText: 'Brutkasten',
        link: 'https://brutkasten.com/artikel/locaverse-mit-dieser-app-moechte-ex-chef-von-penny-markt-oesterreich-durchstarten'
    }
]
</script>

<style scoped>
h2 {
    font-size: 1.2rem;
    margin: 0;
}
.single-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: var(--white);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
}
.logos-wrapper {
    display: flex;
    gap: calc(var(--spacing) * 2);
    flex-direction: row;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    padding: 0.2rem;
    scroll-padding-left: var(--spacing);
    justify-content: flex-start;
    mask-image: linear-gradient(to right, transparent, black 0, black 90%, transparent);
}
.logos-wrapper > div {
    flex: 0 0 60vw;
    scroll-snap-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    margin-bottom: 1rem;
}
.logo-img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}
@media only screen and (min-width: 768px) {
    .logos-wrapper {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        row-gap: var(--spacing);
        column-gap: calc(2 * var(--spacing));
        mask-image: none;
        overflow-x: hidden;
        padding: var(--spacing);
    }
    .logos-wrapper::before,
    .logos-wrapper::after {
        content: '';
        width: calc((100vw - 80vw) / 2);
        flex-shrink: 0;
    }
    .logos-wrapper > div {
        flex: 1;
        margin: 0;
        max-width: none;
    }
    .logo-img {
        width: auto;
        max-width: 100%;
        height: auto;
    }
}
</style>
