export function useUser () {
    const { data, status } = useAuthState()

    const hasContactInfo = computed(() => {
        if (!data.value) { return false }
        const { firstName, lastName, contactPhone, contactEmail, country, address } = data.value
        return !!firstName && !!lastName && !!contactPhone && !!contactEmail && !!country && !!address
    })
    const isStripeCustomer = computed(() => data.value && data.value.stripeCustomerId)
    const isAuthenticated = computed(() => status.value === 'authenticated')

    return {
        user: data,
        isAuthenticated,
        isStripeCustomer,
        hasContactInfo
    }
}
