<template>
    <DialogInfo @close="emit('close')">
        <template #title>
            <h3>Eine Kategorieseite in Social Media posten</h3>
        </template>
        <template #content>
            <ul>
                <li>
                    Poste eine Kategorieseite deiner Wahl öffentlich auf Social Media Plattformen wie Facebook, LinkedIn oder einer anderen Plattform. Wähle eine beliebige Kategorie aus, z.B.
                    <NuxtLink
                        :to="`https://www.${$myT('mainAppDomain')}/essen-bestellen`"
                        target="_blank"
                    >
                        "Essen bestellen"
                    </NuxtLink> oder
                    <NuxtLink
                        :to="`https://www.${$myT('mainAppDomain')}/haushaltshilfe`"
                        target="_blank"
                    >
                        "Haushaltshilfe"
                    </NuxtLink>, nimm gerne auch die Kategorie, in der du vertreten bist, und präsentiere stolz deine Präsenz auf {{ $myT('mainAppDomain') }}.
                </li>
                <li>
                    Mache einen Screenshot von deinem Beitrag und sende uns diesen zusammen mit einem Link zum Post.
                </li>
            </ul>
        </template>
    </DialogInfo>
</template>

<script setup>
const emit = defineEmits(['close'])
</script>
