<template>
    <section>
        <div class="container">
            <div
                v-if="currentStep <= 2"
                class="form"
            >
                <div>
                    <transition
                        name="fade"
                        mode="out-in"
                    >
                        <div
                            :key="currentStep"
                            class="question-wrapper"
                        >
                            <p
                                v-if="currentStep === 0"
                                class="question"
                            >
                                Findest du, du bist <b>gut darin</b>, was du <b>tust</b>?
                            </p>
                            <p
                                v-if="currentStep === 1"
                                class="question"
                            >
                                Willst du, dass <b>mehr Menschen erfahren</b>, dass du es <b>richtig gut machst</b>?
                            </p>
                            <p
                                v-if="currentStep === 2"
                                class="question"
                            >
                                Stimmst du mir zu, dass je mehr Menschen dein Angebot kennen, desto <b>mehr Kunden</b> zu dir kommen?
                            </p>
                        </div>
                    </transition>
                    <div class="buttons mt-2">
                        <button
                            class="outline"
                            @click="submitAnswer('no')"
                        >
                            Nein
                        </button>
                        <button
                            class="outline scale-on-hover"
                            @click="submitAnswer('yes')"
                        >
                            Ja
                        </button>
                    </div>
                </div>
            </div>
            <div
                v-if="currentStep === 3"
                class="content ptb-1"
            >
                <h2>Dann bist du hier <u>genau richtig!</u></h2>
                <div class="top-row">
                    <div class="top">
                        <img
                            src="/assets/images/michael.jpg"
                            alt="Michael"
                            class="michael-image"
                        >
                        <div>
                            Ich bin Michael 👋<br> Dein Online-Marketing mit {{ $myT('mainAppDomain') }}:
                            <ul>
                                <li>leicht gemacht</li>
                                <li>mit Erfolgsgarantie</li>
                                <li>in Handumdrehen</li>
                                <li>persönlich</li>
                            </ul>
                        </div>
                    </div>
                    <img
                        src="/assets/product-animation.gif"
                        alt="Product Animation"
                        class="product-animation"
                    >
                </div>
                <BoxForSlot class="mt-2">
                    <template #content>
                        <h3 class="centered">
                            Dein Angebot mit <u>sofortiger Reichweite</u>, fast geschenkt & garantiert
                        </h3>
                    </template>
                </BoxForSlot>
                <div class="second mt-2">
                    <div>
                        <h3>
                            Das erreichst du mit allekommen
                        </h3>
                        <p class="mt-05 check-item">
                            ✅ Reichweite mit Geld-zurück-Garantie
                        </p>
                        <p class="check-item">
                            ✅ Vollwertige Onlinepräsenz in Minuten
                        </p>
                        <p class="check-item">
                            ✅ Zusätzliche Tools zur Umsatzsteigerung
                        </p>
                    </div>
                    <div>
                        <h3>Mein Versprechen</h3>
                        <p class="mt-05 check-item">
                            ✅ Super einfache Benutzeroberfläche
                        </p>
                        <p class="check-item">
                            ✅ Persönlicher Ansprechpartner
                        </p>
                        <p class="check-item">
                            ✅ Aktuelle Statistiken über deine Performance
                        </p>
                    </div>
                </div>
                <h3 class="mt-4 centered">
                    Einige unserer überzeugten Partner
                </h3>
                <CustomerQuote
                    v-for="(quote, index) in QUOTES"
                    :key="`quote-${index}`"
                    :text="quote.text"
                    :author="quote.author"
                    :image="quote.image"
                    class="mt-05"
                />
                <NavigationAwardsLinks class="mt-2" />
                <PricingFeaturesTable class="mt-2" />
                <PricingTableComponent class="mt-2" />
                <BoxForSlot class="mt-2">
                    <template #content>
                        <h3 class="centered">
                            50.000 Einträge unserer Partner haben schon insgesamt 1M+ Impressionen erreicht. Wann bist du dabei?
                        </h3>
                        <NuxtLink
                            :to="localePath('become-partner')"
                            role="button"
                            class="yellow block-link mt-1"
                        >
                            Gratis Testphase starten
                        </NuxtLink>
                    </template>
                </BoxForSlot>
            </div>
            <div
                v-if="currentStep === 4"
                class="form"
            >
                <div class="no">
                    <div>
                        <h3 class="bigger">
                            Damit hätte ich (fast) nicht gerechnet 😉
                        </h3>
                        <p class="mt-1">
                            Tatsächlich hängt dein Erfolg von 4 Faktoren ab:
                        </p>
                        <p class="check-item">
                            ✅ Deinem Angebot
                        </p>
                        <p class="check-item">
                            ✅ Deiner Reichweite
                        </p>
                        <p class="check-item">
                            ✅ Deinem Vertrieb
                        </p>
                        <p class="check-item">
                            ✅ Dem Markt, in dem du bist
                        </p>
                        (vgl. Alex Hormozi, CEO Acquisition.com)
                        <p class="mt-05">
                            Kannst du <b>alle</b> Häckchen setzen?
                        </p>
                        Ich freue mich auf einen Austausch mit dir!
                    </div>
                    <div class="no-side">
                        <img
                            src="/assets/images/michael.jpg"
                            alt="Michael"
                            class="michael-image"
                        >
                        <NuxtLink
                            role="button"
                            class="yellow"
                            :to="localePath('free-consulting')"
                        >
                            Mit Michael sprechen
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { QUOTES } from '~/constants'

definePageMeta({
    auth: false,
    layout: 'landing'
})

const router = useRouter()
const route = useRoute()

const localePath = useLocalePath()
const currentStep = ref(0)

function submitAnswer (response) {
    response === 'no'
        ? currentStep.value = 4
        : currentStep.value++
    router.replace({ query: { step: currentStep.value } })
}
onMounted(() => {
    const step = parseInt(route.query.step)
    if (!isNaN(step) && step > 0 && step <= 4) {
        currentStep.value = step
    }
})

useHead({
    title: '3 einfache Fragen um zu wachsen',
    meta: [{ name: 'description', content: 'Du willst endlich dein Potenzial realisieren? Mit nur 3 einfachn Fragen helfe ich dir, die nächsten Schritte einzuleiten.' }]
})
</script>

<style scoped>
ul { padding-left: 1rem; margin-bottom: 0; }
h3 { margin-bottom: 0; }
.check-item {
    margin-bottom: 0.25rem;
}
section {
    background-color: var(--white);
    padding-bottom: 4rem;
}
.form {
    display: grid;
    place-items: center;
    margin-top: auto;
    min-height: 91vh;
}
button { font-weight: bold; }
h1 { font-size: 2.5rem }
.question-wrapper {
    text-align: center;
    margin: 20px;
}
.question {
    font-size: 2rem;
}
.buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.top { display: flex; flex-direction: row; gap: 1rem; }
.michael-image {
    width: 100%;
    max-width: 9rem;
    border-radius: var(--border-radius);
    box-shadow: var(--small-shadow);
}
.second, .top-row {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.michael-image { display: block; }
.no {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.no-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
}
.bigger { font-size: 1.5rem; }
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}
@media (min-width: 768px) {
    .second {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 2rem;
    }
    .top-row {
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        column-gap: 2rem;
    }
    .no {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }
    .product-animation {
        width: 100%;
        max-width: 15rem;
    }
}
</style>
