<template>
    <div>
        <label for="firm_address">
            Adresse (Nur Straße) *
            <input
                id="firm_address"
                v-model="address.streetName"
                type="text"
                name="firm_address"
                placeholder="bspw. Wiedner Gürtel"
                required
                maxlength="100"
            >
        </label>
        <div class="grid">
            <label for="house_number">
                Hausnummer / Stiege / Tür *
                <input
                    id="house_number"
                    v-model="address.houseNumber"
                    type="text"
                    name="house_number"
                    placeholder="bspw. 80/2/10"
                    required
                    maxlength="15"
                >
            </label>
            <label for="zip_code">
                Postleitzahl *
                <input
                    id="zip_code"
                    v-model="address.zipCode"
                    type="text"
                    name="zip_code"
                    placeholder="bspw. 1100"
                    required
                    maxlength="15"
                >
            </label>
            <label for="city">
                Stadt *
                <input
                    id="city"
                    v-model="address.city"
                    type="text"
                    name="city"
                    placeholder="bspw. Wien"
                    required
                    maxlength="100"
                >
            </label>
        </div>
    </div>
</template>

<script setup>
const address = defineModel({
    type: Object
})
</script>

<style scoped>

</style>
