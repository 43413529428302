<template>
    <form @submit.prevent="next">
        <h2>Name und Beschreibung</h2>
        <label for="nameInput">
            Gib hier den Servicenamen ein. Der Name ist sowohl auf der Service-Card in
            den Suchergebnissen, als auch auf der Service-Page sichtbar. *
            <input
                id="nameInput"
                v-model="name"
                type="text"
                name="nameInput"
                placeholder="Servicename eingeben"
                required
                maxlength="70"
            >
        </label>
        <p
            class="chars"
            :class="{ error: name.length > 70 }"
        >
            {{ name.length }} / 70 Zeichen
        </p>
        <InputRichTextDescription
            v-model="info"
            :min-length="MIN_LENGTH"
            ai-purpose="service"
        />
        <p>* Pflichtfelder</p>
        <button
            type="submit"
            :disabled="!allowNext"
        >
            Weiter
        </button>
    </form>
</template>

<script setup>
const emit = defineEmits(['next'])

const { $clientLocalStorage } = useNuxtApp()
const savedState = $clientLocalStorage.getItem({ item: 'newServiceProcess', parseJson: true }) ?? {}

const name = ref(savedState?.name || '')
const info = ref({ text: savedState?.info || '', totalChars: 0 })
const MIN_LENGTH = 250

const allowNext = computed(() => {
    return name.value.length > 0 && info.value.totalChars >= MIN_LENGTH
})

function next () {
    savedState.name = name.value
    savedState.info = info.value.text
    $clientLocalStorage.setItem({
        item: 'newServiceProcess',
        value: savedState
    })
    emit('next')
}
</script>

<style scoped>
input { margin-bottom: 0; }
.error { color: var(--red); }
.chars {
    margin-left: auto;
    width: fit-content;
}
</style>
