<template>
    <span class="rating">
        <img
            v-for="starIndex in filledStars"
            :key="'star_' + props.uniqueIdentifier + starIndex"
            src="/assets/icons/stars/star-primary-solid.svg"
            alt="Star Icon"
        >
        <img
            v-if="hasHalfStar"
            src="/assets/icons/stars/star-half.svg"
            alt="Half Star Icon"
        >
        <img
            v-for="emptyStarIndex in emptyStars"
            :key="'empty_' + props.uniqueIdentifier + emptyStarIndex"
            src="/assets/icons/stars/star-primary.svg"
            alt="Outlined Star Icon"
        >
    </span>
</template>

<script setup>
const props = defineProps({
    rating: { type: Number, required: true },
    uniqueIdentifier: { type: String, required: true }
})

const decimalStars = computed(() => {
    return props.rating - Math.trunc(props.rating)
})

const hasHalfStar = computed(() => {
    return decimalStars.value >= 0.375 && decimalStars.value <= 0.8
})

const filledStars = computed(() => {
    return decimalStars.value >= 0.89 ? Math.trunc(props.rating) + 1 : Math.trunc(props.rating)
})

const emptyStars = computed(() => {
    return hasHalfStar.value ? 5 - filledStars.value - 1 : 5 - filledStars.value
})
</script>

<style scoped>
.rating {
    display: flex;
    align-items: center;
    column-gap: 0.2rem;
}
</style>
