<template>
    <section class="full-height">
        <main>
            <slot />
        </main>
    </section>
</template>

<script setup>
useCustomHead()
</script>

<style scoped>
section { margin-bottom: 0; }
</style>
