<template>
    <img src="/assets/icons/circle-check-solid.svg" alt="Check!">
</template>

<script setup>
</script>

<style scoped>
img {
    width: 1.25rem;
    height: 1.25rem;
}
</style>
