<template>
    <label for="country-select">Land
        <select id="country-select" v-model="selectedCountry" required>
            <option value="">
                -- Bitte wähle ein Land aus --
            </option>
            <option v-for="c in countries" :key="c.value" :value="c.value">
                {{ c.label }}
            </option>
        </select>
    </label>
</template>

<script setup>
import { COUNTRY_LIST } from '~/constants'

const props = defineProps({
    country: { type: String, required: false, default: 'Austria' }
})

const countries = ref(COUNTRY_LIST)
const selectedCountry = ref(props.country)

watch(() => props.country, (newCountry) => {
    selectedCountry.value = newCountry
})

const emit = defineEmits(['new-country'])
watch(selectedCountry, (newCountry) => {
    emit('new-country', newCountry)
})
</script>
