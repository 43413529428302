<template>
    <dialog open>
        <article>
            <CloseIcon @close="emit('close')" />
            <h3>Orte ({{ selectedLocations.length }} aktiv)</h3>
            <p class="hint">
                Drücke auf "Hinzufügen", um Orte zu selektieren und bestätige mit "Fertig".
            </p>
            <ServiceLocationPolygonSelector v-model="selectedLocations" />
            <button :aria-busy="loading" class="mt-1" @click="confirm">
                Fertig
            </button>
        </article>
    </dialog>
</template>

<script setup>
const emit = defineEmits(['close'])

const props = defineProps({
    confirmCallback: { type: Function, required: true }
})

const selectedLocations = defineModel({ type: Array })
const loading = ref(false)

async function confirm () {
    loading.value = true
    await props.confirmCallback()
    loading.value = false
}
</script>

<style scoped>

</style>
