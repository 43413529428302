<template>
    <a
        :role="props.role"
        :href="businessPublicLink"
        target="_blank"
    >
        Zum live Profil
    </a>
</template>

<script setup>
const props = defineProps({
    role: { type: String, required: false, default: 'link' }
})
const { businessPublicLink } = storeToRefs(useBusinessStore())
</script>
