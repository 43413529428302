<template>
    <div>
        <PricingTableComponent v-model="annual" class="mt-2" />
        <h2 class="mt-2">
            Übersicht
        </h2>
        <PricingPackagesTable :annual="annual" />
        <PricingFeaturesTable class="mt-2" />
        <button class="block-link mt-2" @click="showCard = true">
            Ich habe Fragen!
        </button>
        <DialogInfo v-if="showCard" @close="showCard = false">
            <template #content>
                <MichaelCard class="mt-2" />
            </template>
        </DialogInfo>
    </div>
</template>

<script setup>
const annual = ref(false)
const showCard = ref(false)
</script>

<style scoped>

</style>
