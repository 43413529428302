<template>
    <section class="container ptb-1">
        <div v-if="error.statusCode === 404">
            <h1 class="centered">
                Stand heute gibt es diese Seite nicht!
            </h1>
        </div>
        <div v-else>
            <h1 class="centered">
                Es ist ein Fehler passiert.
            </h1>
            <p>{{ error.message }}</p>
        </div>
        <button @click="clearError({ redirect: redirectPath })">
            Zurück
        </button>
    </section>
</template>

<script setup>
import * as Sentry from '@sentry/vue'

const props = defineProps({ error: { type: Object, default: null } })

const { isAuthenticated } = useUser()
const redirectPath = isAuthenticated ? '/dashboard' : '/'

onMounted(() => {
    Sentry.captureException(props.error)
})
</script>
