<template>
    <div class="table">
        <PolygonOnMap
            v-if="showMapDialog"
            :geojson="parsedGeojson"
            :center="mapCenter"
            :zoom="zoom"
            :name="polygonName"
            :loading="loadingMap"
            @close="showMapDialog = false"
        />
        <div v-if="selectedLocations.length !== 0" class="dashed-card-container">
            <div
                v-for="(item, index) in selectedLocations"
                :key="index"
                class="dashed-card"
            >
                {{ item.name }}
                <div
                    class="icon-container centered-block"
                    @click="showMap(item)"
                >
                    <img src="/assets/icons/map-icon.svg" alt="Map Icon">
                </div>
                <div class="icon-container" @click="removeLocation(index)">
                    <img src="/icons/trash-can-solid.svg" alt="Delete Icon" loading="lazy">
                </div>
            </div>
        </div>
        <hr>
        <SearchBar v-model="searchTerm" :loading="loading" class="mt-1" @search="globalSearch" />
        <DataTable class="small-table">
            <template #headers>
                <th scope="col" class="name-cell">
                    Name
                </th>
                <th scope="col" class="small-cell centered">
                    Karte Anzeigen
                </th>
                <th scope="col" class="small-cell centered">
                    Hinzufügen
                </th>
            </template>
            <template #content>
                <tr v-for="(item, index) in availableLocations" :key="index">
                    <td>
                        {{ item.name }}
                    </td>
                    <td class="icon-cell">
                        <div
                            class="icon-container centered-block"
                            @click="showMap(item)"
                        >
                            <img src="/assets/icons/map-icon.svg" alt="Map Icon">
                        </div>
                    </td>
                    <td class="icon-cell">
                        <div class="icon-container centered-block" @click="addLocation(item)">
                            <img src="/icons/plus-solid.svg" alt="Plus Icon">
                        </div>
                    </td>
                </tr>
            </template>
            <template #loading>
                <client-only>
                    <InfiniteLoading @infinite="load">
                        <template #complete>
                            <p class="centered">
                                Das sind alle Ergebnisse!
                            </p>
                        </template>
                        <template #error>
                            <p class="centered">
                                Es ist leider ein Fehler passiert.
                            </p>
                        </template>
                    </InfiniteLoading>
                </client-only>
            </template>
        </DataTable>
    </div>
</template>

<script setup>
import InfiniteLoading from 'v3-infinite-loading'

const selectedLocations = defineModel({ type: Array })

const availableLocations = computed(() => {
    return loadedPolygons.value.filter((c) => {
        return !selectedLocations.value.some(location => location.name === c.name)
    })
})

const { $backendApi } = useNuxtApp()
const loading = ref(false)
const searchTerm = ref('')
const loadedPolygons = ref([])
let hasNextPage = true
let page = 1
async function fetchPolygons () {
    const { polygons, hasNext } = await $backendApi('/locations/', {
        query: { page, name: searchTerm.value }
    })
    loadedPolygons.value.push(...polygons)
    hasNextPage = hasNext
}

async function load ($state) {
    try {
        await fetchPolygons()
        if (!hasNextPage) { $state.complete() } else {
            $state.loaded()
        }
        page++
    } catch (error) {
        console.log(error)
        $state.error()
    }
}

async function globalSearch () {
    page = 1
    loadedPolygons.value = []
    await fetchPolygons()
}

function addLocation (location) {
    selectedLocations.value.push(location)
}
function removeLocation (index) {
    selectedLocations.value.splice(index, 1)
}

const toast = useToast()
const showMapDialog = ref(false)
const polygonName = ref('')
const loadingMap = ref(false)
const parsedGeojson = ref({})
const mapCenter = ref([])
const zoom = ref(0)
async function showMap (item) {
    loadingMap.value = true
    polygonName.value = item.name
    showMapDialog.value = true
    try {
        const { geojson, center } = await $backendApi(`/locations/${item.id}/geojson`)
        parsedGeojson.value = JSON.parse(geojson)
        mapCenter.value = center
        zoom.value = Number((item.adminLevel ?? 4)) + 8
    } catch (error) {
        toast.error('Karte kann nicht angezeigt werden.')
        showMapDialog.value = false
    }
    loadingMap.value = false
}
</script>

<style scoped>
.dashed-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
    max-height: 10rem;
    overflow-y: auto;
}
.icon-cell {
    width: 10rem;
    text-align: center;
    vertical-align: middle;
}
.centered-block {
    margin: 0 auto;
}
.small-table {
    max-height: 20rem!important;
}
</style>
