<template>
    <div class="contact-form-toggle">
        <dialog :open="showContactFormInfo">
            <article>
                <CloseIcon @close="showContactFormInfo = false" />
                <h3 class="dialog-title">
                    Kontaktformular aktivieren (empfohlen)
                </h3>
                <p>
                    Um direkt Kundenafragen anzunehmen, empfehlen wir sehr, das Kontaktformular zu aktivieren.
                </p>
                <p>
                    Es wird auf deinem Anbieterprofil, sowie auf all deinen Angebotsseiten angezeigt.
                    Sobald ein Interessent es abschickt, bekommst du es direkt in deine Kontaktanfragen und kannst sofort antworten.
                    Wenn du das nicht willst, kannst du das Kontaktformular deaktivieren.
                </p>
                <button @click="showContactFormInfo = false">
                    Alles klar!
                </button>
            </article>
        </dialog>
        <label for="enableContactForm">
            <input
                id="enableContactForm"
                v-model="business.enableContactForm"
                type="checkbox"
                role="switch"
                name="enableContactForm"
            >
            Kontaktformular {{ business.enableContactForm ? 'aktiv' : 'inaktiv' }}
        </label>
        <span role="link" @click="showContactFormInfo = true">Mehr Infos</span>
    </div>
</template>

<script setup>
const businessStore = useBusinessStore()
const { business } = storeToRefs(businessStore)

const showContactFormInfo = ref(false)

const toast = useToast()
const { $backendApi } = useNuxtApp()
watch(() => business.value.enableContactForm, async () => {
    try {
        await $backendApi(`/businesses/${business.value.id}/enable-contact-form`, {
            method: 'PUT', body: { enableContactForm: business.value.enableContactForm }
        })
    } catch (error) {
        handleBackendError({ error, toast })
    }
})
</script>

<style scoped>
.contact-form-toggle {
    display: flex;
    column-gap: 0.5rem;
    flex-wrap: wrap;
}
.contact-form-toggle span, label { cursor: pointer; }
label { margin-bottom: 0; }
</style>
