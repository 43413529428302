<template>
    <section>
        <div class="space-between mb-1">
            <h2>Häufig gestellte Fragen</h2>
            <button v-if="!showFaqForm" class="small new-faq-btn" @click="createFaq">
                Faq erstellen
            </button>
        </div>
        <FaqForm
            v-if="showFaqForm"
            :faq="selectedFaq"
            :scope="scope"
            @close="showFaqForm = false"
            @update="updateFaq"
            @create="addFaq"
        />
        <div v-if="!showFaqForm">
            <AccordionComponent
                v-for="faq in data.faqs"
                :key="faq.id"
                :use-layout="false"
                :white-theme="false"
            >
                <template #title>
                    <div class="faq-title">
                        <h3>{{ faq.question }}</h3>
                        <div>
                            <ActionIcon :position-absolute="false" icon="pen-solid" @click="selectFaq(faq)" />
                            <ActionIcon :position-absolute="false" class="ml-05" icon="trash-can-solid" @click="deleteFaq(faq.id)" />
                        </div>
                    </div>
                </template>
                <template #links>
                    <div v-html="faq.answer" />
                </template>
            </AccordionComponent>
        </div>
    </section>
</template>

<script setup>
const { scope } = defineProps({
    scope: { type: String, required: true }
})

const { data } = await useIFetch(`/content/faqs/${scope}`)
const showFaqForm = ref(false)
const selectedFaq = ref(null)
function selectFaq (faq) {
    selectedFaq.value = faq
    showFaqForm.value = true
}
function updateFaq (faq) {
    const index = data.value.faqs.findIndex(f => f.id === faq.id)
    data.value.faqs.splice(index, 1, faq)
    showFaqForm.value = false
    selectedFaq.value = null
}
function createFaq () {
    selectedFaq.value = null
    showFaqForm.value = true
}
function addFaq (newFaq) {
    data.value.faqs.unshift(newFaq)
    showFaqForm.value = false
    selectedFaq.value = null
}
const toast = useToast()
const { $backendApi } = useNuxtApp()
async function deleteFaq (faqId) {
    if (!confirm('Willst du diese Faq wirklich löschen?')) {
        return
    }
    try {
        await $backendApi(`/content/faqs/${faqId}`, { method: 'DELETE' })
        toast.success('Faq erfolgreich gelöscht.')
        data.value.faqs = data.value.faqs.filter(faq => faq.id !== faqId)
    } catch (error) {
        handleBackendError({ error, toast })
    }
}
</script>

<style scoped>
.space-between {
    row-gap: var(--spacing);
    flex-wrap: wrap;
}
.space-between h2, .faq-title h3 { margin-bottom: 0; }
.new-faq-btn { width: fit-content; }
.faq-title {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;
}
</style>
