<template>
    <section class="ptb-1">
        <div v-if="!failed">
            <h1>E-Mail wird bestätigt...</h1>
            <progress />
        </div>
        <div v-else>
            <h1>Neuen Link anfragen</h1>
            <p>
                Leider ist es zu einem Fehler gekommen. Bitte überprüfe den Link, er könnte falsch oder
                abgelaufen sein.
            </p>
            <FormResendEmailConfirmation />
        </div>
    </section>
</template>

<script setup>

definePageMeta({
    auth: {
        unauthenticatedOnly: true,
        navigateAuthenticatedTo: '/dashboard'
    },
    layout: 'standard'
})
const route = useRoute()
const router = useRouter()
const toast = useToast()
const { $backendApi } = useNuxtApp()
const localePath = useLocalePath()

const failed = ref(false)

onMounted(async () => {
    try {
        const token = route.params.emailConfirmToken
        const query = new URLSearchParams(route.query).toString()
        await $backendApi(`auth/confirm-email/validate/${token}?${query}`)
        toast.success('Bestätigung erfolgreich.')
        await router.push(localePath('login'))
    } catch (error) {
        failed.value = true
        handleBackendError({ error, toast })
    }
})

useHead({
    title: 'E-Mail bestätigen',
    meta: [{ name: 'robots', content: 'noindex' }]
})
</script>
