<template>
    <DialogInfo @close="emit('close')">
        <template #title>
            <h3>Kommt zu dir?</h3>
        </template>
        <template #content>
            <p>
                Die Beantwortung dieser Frage ist unsere Kernkompetenz. Wenn du eine mobile
                Dienstleistung oder Lieferservice anbietest, hast du dein Gebiet, wo du hinkommst oder
                zustellst. Dieses Gebiet kannst du für jedes deiner mobilen Services separat definieren, wenn
                du sie neu erstellst oder bearbeitest. Sollte der User auf {{ $myT('mainAppDomain') }} dann eine Suche starten
                und sich zumindest in einem der von dir angegebenen Gebiete befinden, so werden deine Services mit
                dem Marker "Kommt zu dir" versehen.
            </p>
            <p>
                Auf deinem Anbieterprofil wird dieser Marker ebenfalls angezeigt, sobald
                mindestens eines deiner Services (du kannst mehrere erstellen) mit dem Standort des Users
                zusammenpasst.
            </p>
        </template>
    </DialogInfo>
</template>

<script setup>
const emit = defineEmits(['close'])
</script>

<style scoped>

</style>
