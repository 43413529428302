<template>
    <div>
        <div class="some-input">
            <img :src="`/icons/some-color/${someName}-color.svg`" :alt="someName + ' Icon'">
            <input
                v-model="link.link"
                type="url"
                rel="nofollow"
                :name="someName"
                :aria-invalid="String(link.invalid)"
                :placeholder="placeholder"
                @blur="invalidSocialLink"
            >
        </div>
        <small v-if="link.invalid">
            Link nicht erkannt, bitte mit https anfangen und das Format {{ placeholder }} beachten.
        </small>
    </div>
</template>

<script setup>

const props = defineProps({
    someName: { type: String, required: true },
    placeholder: { type: String, required: true },
    mustStartWith: { type: Array, required: true }
})

const link = defineModel({ type: Object, required: true })

function invalidSocialLink () {
    if (!link.value.link) {
        link.value.invalid = ''
        return
    }
    const startsWithHttps = link.value.link.startsWith('https')
    const startsWithMustStartWith = props.mustStartWith.some(prefix => link.value.link.startsWith(prefix))
    link.value.invalid = !startsWithHttps || !startsWithMustStartWith
}
</script>

<style scoped>
.some-input {
    display: flex;
    flex-direction: row;
    column-gap: var(--spacing);
    align-items: center;
}
.some-input input { margin: 0; }
.some-input img {
    width: 2rem;
    height: auto;
}
</style>
