<template>
    <section>
        <h2>Kategorie & Servicegebiete</h2>
        <ServiceCategorySelector v-model="service.category.id" />
        <h3>Deine Servicegebiete</h3>
        <p class="mt-1">
            Sind jene Orte, wo du von Kunden gefunden
            werden möchtest. Du kannst beliebig viele Orte wählen (bspw. Wien oder 1140)
        </p>
        <LazyServiceLocationPolygonsDialog
            v-if="showLocationsDialog"
            v-model="locations"
            :confirm-callback="updatePolygons"
            @close="showLocationsDialog = false"
        />
        <ServiceLocationNationalAvailabilityToggle
            v-model="service.national"
            :loading="loadingNational"
        />
        <ServiceLocationOverviewCards
            :locations="locations"
            :disable="service.national"
            @show-locations-dialog="showLocationsDialog = true"
        />
        <LazyDialogConfirmAction
            v-if="showNationalToggleDialog"
            :loading="loadingNational"
            @cancel="cancelNationalUpdate"
            @confirm="updateNational"
        >
            <template #title>
                <h3>{{ nationalHeading }}</h3>
            </template>
            <template #content>
                <div v-if="service.national">
                    Das heißt, du kommst/lieferst in ganz {{ $myT('country') }}. <b>Achtung:</b> Wenn du bisher schon Servicegebiete
                    definiert hast, werden diese im nächsten Schritt überschrieben!
                </div>
                <div v-else>
                    Falls du dein Service nicht mehr nationalweit anbieten möchtest, denke bitte daran,
                    <b>neue Servicegebiete anzugeben</b>, damit Interessenten dich besser finden!
                </div>
            </template>
        </LazyDialogConfirmAction>
    </section>
</template>

<script setup>
const toast = useToast()
const serviceStore = useServiceStore()
const { serviceId } = defineProps({ serviceId: { type: Number, required: true } })
const service = computed(() => serviceStore.getServiceById(serviceId))

const locations = ref(service.value.locations)

const loading = ref(false)
watch(() => service.value.category.id, async (newCategoryId) => {
    loading.value = true
    try {
        await serviceStore.updateCategory({ newCategoryId, serviceId })
        toast.success('Gespeichert.')
    } catch (error) {
        handleBackendError({ error, toast })
    }
    loading.value = false
})

const nationalHeading = computed(() => {
    return service.value.national
        ? 'Service nationalweit verfügbar machen?'
        : 'Service nicht mehr national anbieten?'
})
const showNationalToggleDialog = ref(false)
const stopWatchingNational = ref(false)
const loadingNational = ref(false)
watch(() => service.value.national, () => {
    showNationalToggleDialog.value = !stopWatchingNational.value && true
    stopWatchingNational.value = false
})
async function updateNational () {
    loadingNational.value = true
    try {
        await serviceStore.updateNational({ newNational: service.value.national, serviceId })
        toast.success('Gespeichert.')
    } catch (error) {
        stopWatchingNational.value = true
        service.value.national = !service.value.national
        handleBackendError({ error, toast })
    }
    showNationalToggleDialog.value = false
    loadingNational.value = false
}
function cancelNationalUpdate () {
    stopWatchingNational.value = true
    service.value.national = !service.value.national
    showNationalToggleDialog.value = false
}

const showLocationsDialog = ref(false)
async function updatePolygons () {
    const selectedIds = locations.value.map(l => l.id)
    try {
        await serviceStore.updatePolygons({ serviceId, locationIds: selectedIds })
        toast.success('Gespeichert.')
        showLocationsDialog.value = false
    } catch (error) {
        handleBackendError({ error, toast })
    }
}
</script>

<style scoped>
</style>
