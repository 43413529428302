<template>
    <section class="content-container">
        <LazyDialogComingToYouExplained
            v-if="showComingToYouDialog"
            @close="showComingToYouDialog = false"
        />
        <LazyDialogInfo
            v-if="showDescriptionDialog"
            @close="showDescriptionDialog = false"
        >
            <template #title>
                <h3>Beschreibung deines Anbieterprofils</h3>
            </template>
            <template #content>
                <p>
                    Dieses Anbieterprofil wird live auf {{ $myT('mainAppDomain') }} erscheinen und auch über Google oder andere
                    Suchmaschinen erreichbar sein. Damit du besser gefunden wirst und mehr Aufmerksamkeit der User
                    kriegst, empfehlen wir, die Beschreibung mindestens 200 Wörter lang zu machen und dabei die
                    für dich wesentlichen Botschaften abzubilden.
                </p>
                <p>
                    Eine gute und ausführliche Beschreibung ist ein wesentlicher Hebel für deine Auffindbarkeit auf
                    Google. Du hast somit die Möglichkeit, deine Sichtbarkeit sehr stark positiv zu beeinflussen!
                    Verpasse sie nicht!
                </p>
            </template>
        </LazyDialogInfo>
        <section class="info-card">
            <p class="coming-to-you">
                <span>
                    <img
                        src="/assets/images/allekommen-icon.svg"
                        title="Kommt zu dir"
                        alt="Kommt zu dir"
                        loading="lazy"
                    >
                    Kommt zu dir
                </span>
                <InfoIcon @click="showComingToYouDialog = true" />
            </p>
            <div>
                <small>Anbieter</small>
                <div class="business-name">
                    <div class="input-wrapper">
                        <h1 v-if="!editName">
                            {{ providerName }}
                        </h1>
                        <input
                            v-else
                            id="providerName"
                            ref="nameInput"
                            v-model="providerName"
                            type="text"
                            name="providerName"
                            placeholder="Anbietername"
                            maxlength="255"
                        >
                        <ActionIcon
                            v-if="editName && providerName.length > 2"
                            icon="check-solid"
                            @click="updateName"
                        />
                    </div>
                    <div
                        v-if="editName"
                        class="message-wrapper"
                    >
                        <p
                            v-if="nameLengthExceeded"
                            class="warning"
                        >
                            Der Name ist sehr lang, wir empfehlen weniger als 100 Zeichen zu verwenden.
                        </p>
                        <p :class="{ chars: true, warning: nameLengthExceeded }">
                            {{ providerName.length }} Zeichen
                        </p>
                    </div>
                </div>
            </div>
            <RatingStarsWithCount
                :average-rating="business.averageRating"
                :total-reviews="business.totalReviews"
            />
            <ActionIcon
                v-if="!editName"
                icon="pen-solid"
                @click="enableNameInput"
            />
        </section>
        <BusinessContactInfoCard class="contact-container" />
        <section class="mt-2">
            <h2>Mobile Services</h2>
            <ServiceGridWrapper
                class="mt-1"
                :services-wrapper-small="true"
            >
                <template #content>
                    <ServiceSearchCard
                        v-for="service in services.slice(0, 3)"
                        :key="service.id"
                        :service="service"
                    />
                </template>
            </ServiceGridWrapper>
        </section>
        <h2 class="mt-2 info-heading">
            Über den Anbieter
            <InfoIcon @click="showDescriptionDialog = true" />
            <ActionIcon
                v-if="!editDescription"
                icon="pen-solid"
                :position-absolute="false"
                @click="editDescription = true"
            />
        </h2>
        <div v-if="editDescription">
            <InputRichTextDescription
                v-model="description"
                ai-purpose="business"
            />
            <div class="flex-row mt-1">
                <button
                    class="outline"
                    @click="editDescription = false"
                >
                    Abbrechen
                </button>
                <button
                    :disabled="descriptionTooShort"
                    :aria-busy="loading"
                    @click="saveDescription"
                >
                    Speichern
                </button>
            </div>
        </div>
        <div
            v-else
            v-html="description.text"
        />
        <BusinessSustainabilitySection
            class="mt-1"
            :business-id="business.id"
        />
        <FaqFormSection
            :scope="`business-${business.permanentName}-${business.id}`"
            class="mt-2"
        />
        <BusinessAddressSection :business-address="business.address" />
    </section>
</template>

<script setup>
const { services } = storeToRefs(useServiceStore())

// common composables
const toast = useToast()
const businessStore = useBusinessStore()
const { business } = storeToRefs(businessStore)

// dialog triggers
const showComingToYouDialog = ref(false)
const showDescriptionDialog = ref(false)

// business name logic
const editName = ref(false)
const providerName = ref(business.value.name)
const nameInput = ref(null)
function enableNameInput () {
    editName.value = true
    nextTick(() => {
        nameInput.value.focus()
    })
}

async function updateName () {
    try {
        await businessStore.updateBusinessName({
            newName: providerName.value
        })
        toast.success('Erfolgreich gespeichert!')
        editName.value = false
    } catch (error) {
        handleBackendError({ error, toast })
    }
}
const nameLengthExceeded = computed(() => providerName.value.length > 100)
// business description logic
const {
    loading, description, editDescription, descriptionTooShort, saveDescription
} = useEditDescription(business.value.description, businessStore.updateBusinessDescription)
</script>

<style scoped>
h1 { margin: 0; }
section, #providerName { margin-bottom: 0; }

.info-heading {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0.5rem;
}

/* length name warning message styling */
.business-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 0.5rem;
    cursor: default;
}
.input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.message-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.5rem;
}
.chars {
    margin-left: auto;
    white-space: nowrap;
}
.warning {
    color: var(--red);
    align-self: flex-start;
    flex-grow: 1;
    flex-shrink: 1;
}
</style>
