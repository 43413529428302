<template>
    <div>
        <LazyBusinessAddressEditDialog
            v-if="showAddressDialog"
            @close="showAddressDialog = false"
        />
        <div id="map" class="location-heading mt-2">
            <h2>Unternehmensstandort</h2>
            <ActionIcon
                icon="pen-solid"
                :position-absolute="false"
                @click="showAddressDialog = true"
            />
        </div>
        {{ businessAddressString }}
        <iframe
            class="location-map mt-2"
            style="border:0"
            loading="lazy"
            allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"
            :src="locationForMaps"
        />
    </div>
</template>

<script setup>
import { GOOGLE_MAPS_PUBLIC_API_KEY } from '~/constants'

const { businessAddress } = defineProps({
    businessAddress: { type: Object, required: true }
})

const showAddressDialog = ref(false)

// business location logic
const businessAddressString = computed(() => {
    const { streetName, houseNumber, zipCode, city } = businessAddress
    return `${streetName} ${houseNumber}, ${zipCode}, ${city}`
})
const locationForMaps = computed(() => {
    return `https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_PUBLIC_API_KEY}&q=${businessAddressString.value}`
})
</script>

<style scoped>
.location-heading h2 { margin-bottom: 0; }
.location-map {
    width: 100%;
    height: 20rem;
}
.location-heading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    row-gap: 0.5rem;
    column-gap: var(--spacing);
}
.route-icon { width: 1rem; height: 1rem; }
.route-link {  column-gap: 0.3rem; }
</style>
