<template>
    <div class="slider-wrapper">
        <LazyDialogConfirmAction
            v-if="showDeleteImageDialog"
            :loading="loading"
            @cancel="showDeleteImageDialog = false"
            @confirm="deleteImage"
        >
            <template #title>
                <h3>Bild unwiderruflich löschen?</h3>
            </template>
            <template #content>
                <p>
                    Das Bild wird unwiderruflich gelöscht und kann nicht mehr wiederhergestellt werden.
                </p>
            </template>
        </LazyDialogConfirmAction>
        <div class="images-content">
            <img
                class="navigation-arrow left"
                src="/assets/icons/chevron-left-border.svg"
                alt="Left arrow"
                loading="lazy"
                @click="slideLeft"
            >
            <div class="fade" :style="{ backgroundImage: `url(${currentImg})` }">
                <ActionIcon
                    :custom-styles="{top: '0'}"
                    icon="plus-solid"
                    :class="moreImagesBorder"
                    @click="emit('add-image')"
                />
                <ActionIcon
                    icon="trash-can-solid"
                    class="red-border"
                    @click="showDeleteImageDialog = true"
                />
            </div>
            <img
                class="navigation-arrow right"
                src="/assets/icons/chevron-right-border.svg"
                alt="Right arrow"
                loading="lazy"
                @click="slideRight"
            >
        </div>
        <div class="dots">
            <div
                v-for="i in images.length"
                :key="i"
                class="dot"
                :class="{ active: currentIndex === i - 1 }"
            />
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['add-image'])

const props = defineProps({
    images: { type: Array, default: () => [] },
    serviceId: { type: Number, required: true }
})

const currentIndex = ref(0)
const currentImg = computed(() => {
    return cdnPath(props.images[currentIndex.value].image)
})
const moreImagesBorder = computed(() => {
    return props.images.length > 2 ? 'green-border' : 'yellow-border'
})

const spacesUrl = useRuntimeConfig().public.spacesUrl
function cdnPath (image) {
    return spacesUrl + '/images/mobile_service/' + image
}
function setIndex () {
    currentIndex.value = (currentIndex.value + props.images.length) % props.images.length
}

function slideLeft () {
    currentIndex.value--
    setIndex()
}

function slideRight () {
    currentIndex.value++
    setIndex()
}

const showDeleteImageDialog = ref(false)
const toast = useToast()
const loading = ref(false)
const serviceStore = useServiceStore()
async function deleteImage () {
    loading.value = true
    try {
        const indexValue = currentIndex.value
        if (currentIndex.value !== 0) { slideLeft() }
        await serviceStore.deleteServiceImage({
            serviceId: props.serviceId,
            imageId: props.images[indexValue].id
        })
        toast.success('Bild gelöscht.')
    } catch (error) {
        handleBackendError({ error, toast })
        slideRight()
    }
    loading.value = false
    showDeleteImageDialog.value = false
}

</script>

<style scoped>
.images-content {
    width: 100%;
    height: 100%;
    column-gap: var(--spacing);
    display: grid;
    place-items: center;
    grid-template-columns: 1.5rem auto 1.5rem;
}
.fade {
    grid-column: 2;
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--border-radius);
    transition: .3s all ease-in-out;
}
.dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: var(--spacing);
    gap: var(--spacing);
}
.dot {
    width: .5rem;
    height: .5rem;
    background-color: var(--secondary);
    border-radius: 50%;
    transition: .3s all ease-in-out;
}
.active {
    background-color: var(--primary);
    transform: scale(1.4);
}
.slider-wrapper {
    width: 100%;
    height: 12rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.navigation-arrow {
    background-color: var(--dark-blue);
    border-radius: var(--border-radius);
    padding: 0.3rem;
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.navigation-arrow.left {
    left: 0;
}
.navigation-arrow.right {
    right: 0;
}
@media (min-width: 768px) {
    .slider-wrapper {
        height: 18rem;
    }
}
@media (min-width: 1024px) {
    .slider-wrapper {
        height: 24rem;
    }
}
</style>
