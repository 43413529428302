export const useStatisticsStore = defineStore('statistics', () => {
    const { $backendApi } = useNuxtApp()
    const toast = useToast()

    const stopFetching = ref(false)
    const statistics = ref(null)
    const invoice = ref(null)
    const customer = ref(null)
    const subscription = ref(null)
    const balanceError = ref(false)

    async function fetchStatistics (businessId) {
        if (stopFetching.value) { return }
        try {
            statistics.value = await $backendApi(`/statistics/businesses/${businessId}/aggregated-clicks-and-impressions`)
        } catch (error) {
            stopFetching.value = error.status === 404
        }
    }

    async function fetchInvoice (stripeCustomerId) {
        try {
            invoice.value = await $fetch('/api/stripe/upcoming-invoice', {
                method: 'GET',
                query: { customerId: stripeCustomerId },
                pick: ['subtotal', 'amount_due', 'period_start', 'period_end', 'next_payment_attempt', 'lines']
            })
        } catch (error) {}
    }

    async function fetchCustomer (stripeCustomerId) {
        try {
            customer.value = await $fetch('/api/stripe/customer', {
                method: 'GET',
                query: { customerId: stripeCustomerId },
                pick: ['balance']
            })
        } catch (error) {
            balanceError.value = true
            toast.error('Kunde nicht gefunden.')
        }
    }

    async function fetchSubscription (stripeSubscriptionId) {
        try {
            subscription.value = await $fetch('/api/stripe/subscription', {
                method: 'GET',
                query: { subscriptionId: stripeSubscriptionId },
                pick: ['discounts']
            })
        } catch (error) {
            toast.error('Abonnement nicht gefunden.')
        }
    }

    return {
        statistics,
        invoice,
        customer,
        subscription,
        fetchStatistics,
        fetchInvoice,
        fetchCustomer,
        fetchSubscription
    }
})
