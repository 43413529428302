<template>
    <p class="white-text">
        {{ $myT('homepageTopLine') }} -
        <a
            :href="`https://www.${$myT('mainAppDomain')}`"
            target="_blank"
        >{{ $myT('mainAppDomain') }}</a>
    </p>
</template>

<script setup>

</script>

<style scoped>
.white-text a {  color: var(--white); text-decoration: underline; }
</style>
