<template>
    <div>
        <h2>Service-Zeiten</h2>
        <p>
            Hinweis: hier kannst du für jeden Tag deine Öffnungs- bzw. Arbeitszeiten definieren.
            Wenn du nichts angibst, wird angenommen, dass du an diesem Tag geschlossen hast.
            Falls du keine fixen Zeiten hast, kannst du auch die Option "Auf Anfrage" wählen.
        </p>
        <FormServiceOpeningHours
            v-model="openingHours"
            button-text="Weiter"
            @confirm="next"
        />
    </div>
</template>

<script setup>
const emit = defineEmits(['next'])

const { $clientLocalStorage } = useNuxtApp()
const savedState = $clientLocalStorage.getItem({ item: 'newServiceProcess', parseJson: true }) ?? {}

// Create an object to hold opening hours for each day and populate it
const openingHours = ref(initializeOpeningHours([], savedState.openingHours))

function next () {
    $clientLocalStorage.setItem({
        item: 'newServiceProcess',
        value: JSON.stringify({ ...savedState, openingHours: openingHours.value })
    })
    emit('next')
}
</script>

<style scoped>

</style>
