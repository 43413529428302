<template>
    <div>
        <h2>Buchung und Kontakt</h2>
        <FormServiceBookingInfo
            v-model="bookingLink"
            button-text="Weiter"
            @submit="next"
        />
    </div>
</template>

<script setup>
const emit = defineEmits(['next'])

const { $clientLocalStorage } = useNuxtApp()
const savedState = $clientLocalStorage.getItem({ item: 'newServiceProcess', parseJson: true }) ?? {}

const bookingLink = ref(savedState?.bookingLink || '')

function next () {
    savedState.bookingLink = bookingLink.value
    $clientLocalStorage.setItem({
        item: 'newServiceProcess',
        value: savedState
    })
    emit('next')
}
</script>

<style scoped>

</style>
