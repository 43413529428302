<template>
    <NoteBox>
        <template #content>
            <b>Werde für mehr Interessenten sichtbar!</b><br>
            Jetzt noch -40% Rabatt sichern sichern.
        </template>
        <template #action>
            <NuxtLink role="button" class="yellow btn" :to="localePath('dashboard-activate-profile')">
                Gratis Testphase starten
            </NuxtLink>
        </template>
    </NoteBox>
</template>

<script setup>
const localePath = useLocalePath()
</script>

<style scoped>

</style>
