<template>
    <div>
        <DarkOverlay
            :active="isNavVisible"
            @click="toggleMobileNav"
        />
        <nav class="container-fluid">
            <ul>
                <li>
                    <NuxtLink :to="localePath(logoPath)">
                        <img
                            class="navbar-logo"
                            src="/assets/images/allekommen-partner-logo.svg"
                            alt="Partner Logo"
                            loading="lazy"
                        >
                    </NuxtLink>
                </li>
            </ul>
            <ul class="hamburger-container">
                <li
                    class="hamburger"
                    @click="toggleMobileNav"
                >
                    <img
                        class="hamburger-icon"
                        :src="isNavVisible ? '/icons/xmark-solid-white.svg' : '/icons/bars-solid.svg'"
                        alt="Hamburger menu"
                    >
                </li>
            </ul>
            <ul
                v-if="showNavigationLinks"
                class="navigation-links"
                :class="isNavVisible ? 'show' : 'hide'"
            >
                <li>
                    <NuxtLink
                        :to="localePath('prices')"
                        class="nav_links"
                    >
                        Preise
                    </NuxtLink>
                </li>
                <li>
                    <NuxtLink
                        :to="localePath('free-consulting')"
                        class="nav_links"
                    >
                        Kontakt
                    </NuxtLink>
                </li>
                <li v-if="isAuthenticated">
                    <NuxtLink
                        :to="localePath('dashboard')"
                        role="button"
                        class="nav-icon-container small nav_links"
                    >
                        <img
                            src="/assets/icons/home-primary-solid.svg"
                            alt="Dashboard"
                            loading="lazy"
                        >
                        <span>Dashboard</span>
                    </NuxtLink>
                </li>
                <li>
                    <NuxtLink
                        role="button"
                        :to="localePath('rewards')"
                        class="small"
                        :class="{ yellow: isAuthenticated }"
                    >
                        Bis 585 € Prämien
                    </NuxtLink>
                </li>
                <li v-if="isAuthenticated">
                    <NuxtLink
                        :to="localePath('dashboard-settings')"
                        role="button"
                        class="small nav-icon-container nav_links"
                    >
                        <img
                            src="/assets/icons/settings-primary-solid.svg"
                            alt="Settings Icon"
                            loading="lazy"
                        >
                        <span>Einstellungen</span>
                    </NuxtLink>
                </li>
                <li>
                    <NavigationLoginAndLogoutLink />
                </li>
            </ul>
        </nav>
    </div>
</template>

<script setup>
const localePath = useLocalePath()
const { isAuthenticated } = useUser()
const { showNavigationLinks } = useOnboardingDone()

const logoPath = computed(() => isAuthenticated.value ? 'dashboard' : 'index')

const isNavVisible = ref(false)

function toggleMobileNav () {
    isNavVisible.value = !isNavVisible.value
}
const route = useRoute()
watch(() => route.path, () => {
    isNavVisible.value = false
})
</script>

<style scoped>
nav {
    position: fixed;
    display: flex;
    background-color: var(--dark-blue);
    justify-content: space-between;
    height: 4rem;
    overflow-x: hidden;
    z-index: 10;
}
nav ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    list-style: none;
    padding-inline-start: 0;
}
.navigation-links { transition: all ease-in-out .2s; }
.navigation-links.show {
    position: fixed;
    z-index: 10;
}
nav li { margin: 0; }

.nav_links {
    color: var(--white);
    text-decoration: none;
}
.hamburger { cursor: pointer;}
.hamburger-icon { height: 2.5rem; width: 2.5rem; }
.navbar-logo { width: 12rem; height: 2rem; }
.hamburger-container {
    justify-content: flex-end;
    gap: var(--spacing);
}
.show, .hide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 4rem;
    left: 0;
    width: 100vw;
    height: auto;
    background-color: var(--dark-blue);
    gap: 1rem;
    transform: translateX(0);
    padding: calc(var(--spacing) * 1.5);
}
.hide {
    transform: translateX(100%);
}
.nav-icon-container {
    padding: 0;
    display: flex;
    align-items: center;
    column-gap: var(--spacing);
    width: 100%;
}
@media (min-width: 768px) {
    .hamburger {
        display: none;
    }
    .navigation-links {
        position: static;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0;
    }
    .hide { transform: translateX(0); }
    .navbar-logo { height: 3rem; }
    nav { position: relative; }
    nav ul { padding: 0; }
    .navigation-links.show {
        position: relative;
    }
    .nav-icon-container span {display: none; }
}
</style>
