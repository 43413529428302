<template>
    <section>
        <NavigationGoBack />
        <div class="space-between mt-1">
            <h1>Alle Services ({{ totalServices }})</h1>
            <NuxtLink role="button" :to="localePath('dashboard-services-new')">
                Neues Service erstellen
            </NuxtLink>
        </div>
        <ServiceGridWrapper class="center mt-2" :services-wrapper-small="false">
            <template #content>
                <MichaelCard />
                <ServiceSearchCard
                    v-for="service in serviceStore.services"
                    :key="service.id"
                    :service="service"
                />
            </template>
        </ServiceGridWrapper>
    </section>
</template>

<script setup>
const localePath = useLocalePath()
const serviceStore = useServiceStore()

const totalServices = computed(() => serviceStore.totalServices)

useHead({
    title: 'Deine Services'
})
</script>

<style scoped>
h1 { margin-bottom: 0; }
.space-between {
    flex-wrap: wrap;
    column-gap: var(--spacing);
}
.center { margin-left: auto; margin-right: auto; }
</style>
