<template>
    <a
        :href="`${apiBase}/auth/oauth2/authorize/${provider}/company`"
        role="button"
        class="outline social-sign-in-button"
    >
        <img :src="`/icons/oauth-icons/${icon}`" :alt="providerLabel"> {{ providerLabel }}
    </a>
</template>

<script setup>
defineProps({
    provider: { type: String, required: true },
    providerLabel: { type: String, required: true },
    icon: { type: String, required: true }
})
const apiBase = useRuntimeConfig().public.apiBase
</script>

<style scoped>
.social-sign-in-button {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
}
.social-sign-in-button img {
    width: 2rem;
    height: 2rem;
}
</style>
