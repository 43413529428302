<template>
    <form @submit.prevent="next">
        <!-- TODO: <LazyDialogConfirmAction v-if="showConfirmDialog" @cancel="showConfirmDialog = false" /> -->
        <label for="providerName">
            Anbietername *
            <input
                id="providerName"
                v-model="providerName"
                type="text"
                name="providerName"
                required
                maxlength="255"
                placeholder="Name der Firma oder Vor- und Nachname bei Einzelunternehmern"
            >
        </label>
        <div
            v-if="nameLengthExceeded"
            class="wrapper"
        >
            <p class="warning">
                Der Name ist sehr lang, wir empfehlen weniger als 150 Zeichen zu verwenden.
            </p>
            <p :class="{ chars: true, warning: nameLengthExceeded }">
                {{ providerName.length }} Zeichen
            </p>
        </div>
        <InputRichTextDescription
            v-model="description"
            :min-length="MIN_LENGTH"
            ai-purpose="business"
        />
        <p>* Pflichtfelder</p>
        <button
            type="submit"
            :disabled="!allowNext"
        >
            Weiter
        </button>
    </form>
</template>

<script setup>
const emit = defineEmits(['next'])
const { $clientLocalStorage } = useNuxtApp()
const savedState = $clientLocalStorage.getItem({ item: 'newBusinessProcess', parseJson: true }) ?? {}

const providerName = ref(savedState?.providerName || '')
const description = ref({ text: savedState?.description || '', totalChars: 0 })
const MIN_LENGTH = 250
// name length validation
const nameLengthExceeded = computed(() => providerName.value.length > 100)

const allowNext = computed(() => {
    return providerName.value.length > 0 && description.value.totalChars >= MIN_LENGTH
})

function next () {
    savedState.providerName = providerName.value
    savedState.description = description.value.text
    $clientLocalStorage.setItem({
        item: 'newBusinessProcess',
        value: savedState
    })
    emit('next')
}
</script>

<style scoped>
.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.chars {
    margin-left: auto;
    align-self: flex-end;
}
.warning {
    color: var(--red);
    align-self: flex-start;
    flex-grow: 0;
}
</style>
