<template>
    <div class="services-wrapper" :class="{ 'services-wrapper-small': props.servicesWrapperSmall }">
        <slot name="content" />
    </div>
</template>

<script setup>
const props = defineProps({
    showAddressNote: { type: Boolean, default: true },
    servicesWrapperSmall: { type: Boolean, default: false }
})

</script>

<style scoped>
.services-wrapper {
    width: 100%;
    display: grid;
    column-gap: var(--spacing);
    row-gap: var(--spacing);
    grid-template-columns: 1fr 1fr;
}
@media screen and (min-width: 425px) {
    .services-wrapper {
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (min-width: 768px) {
    .services-wrapper {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media screen and (min-width: 1024px) {
    .services-wrapper {
        grid-template-columns: repeat(auto-fit, 13rem);
    }
}
@media screen and (min-width: 1440px) {
    .services-wrapper {
        grid-template-columns: repeat(auto-fit, 15rem);
    }
}
</style>
