<template>
    <div
        :class="['rating', ratingClass]"
        :style="{
            height: `${height}rem`,
            'border-width': `${height / 7}rem`,
            'font-size': `${height / 3}rem`
        }"
        data-tooltip="Profilstärke"
    >
        <span>{{ rating }}</span>
    </div>
</template>

<script setup>
const props = defineProps({
    rating: { type: Number, required: true },
    height: { type: Number, required: false, default: 2.5 }
})

const ratingClass = computed(() => {
    return {
        good: props.rating >= 80,
        meh: props.rating >= 40 && props.rating < 80,
        bad: props.rating < 40
    }
})

</script>

<style scoped>
.rating {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    aspect-ratio: 1/1;
    border: 4px solid var(--neutral-200);
    font-weight: bold;
}

/* Classes to give different colors to ratings, based on their score */
.good {
    border-color: var(--success);
}

.meh {
    border-color: var(--yellow);
}

.bad {
    border-color: var(--red);
}
</style>
