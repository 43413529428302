<template>
    <div>
        <SocialLoginButton icon="google-icon.png" provider-label="Weiter mit Google" provider="google" />
        <SocialLoginButton class="mt-05" icon="microsoft-icon.png" provider-label="Weiter mit Microsoft" provider="microsoft" />
        <SocialLoginButton class="mt-05" icon="facebook-icon.png" provider-label="Weiter mit Facebook" provider="facebook" />
    </div>
</template>

<script setup>
</script>

<style scoped>
.mt-05 { margin-top: 0.5rem; }
</style>
