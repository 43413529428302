
// @ts-nocheck


export const localeCodes =  [
  "de"
]

export const localeLoaders = {
  "de": []
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "de",
      "language": "de-AT"
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "become-partner": {
      "de": "/anbieter-werden"
    },
    "cooperations": {
      "de": "/kooperationen"
    },
    "boomende-online-on-demand-heimdienstleistungen": {
      "de": "/boomende-online-on-demand-heimdienstleistungen"
    },
    "start": {
      "de": "/start"
    },
    "reset-password/index": {
      "de": "/passwort-zuruecksetzen"
    },
    "reset-password/[passwordResetToken]": {
      "de": "/passwort-zuruecksetzen/[passwordResetToken]"
    },
    "register/index": {
      "de": "/registrieren"
    },
    "register/[emailConfirmToken]": {
      "de": "/registrieren/[emailConfirmToken]"
    },
    "dashboard/business/coupons": {
      "de": "/dashboard/business/angebote-verwalten"
    },
    "dashboard/business/edit": {
      "de": "/dashboard/business/bearbeiten"
    },
    "dashboard/activate-profile": {
      "de": "/dashboard/profil-aktivieren"
    },
    "dashboard/business/new": {
      "de": "/dashboard/business/neu"
    },
    "dashboard/business/success": {
      "de": "/dashboard/business/erfolgreich"
    },
    "dashboard/inbox": {
      "de": "/dashboard/kontaktanfragen"
    },
    "dashboard/onboarding": {
      "de": "/dashboard/onboarding"
    },
    "dashboard/services/edit/[id]": {
      "de": "/dashboard/services/bearbeiten/[id]"
    },
    "dashboard/services/new": {
      "de": "/dashboard/services/neu"
    },
    "dashboard/services/index": {
      "de": "/dashboard/services"
    },
    "dashboard/settings/index": {
      "de": "/dashboard/einstellungen"
    },
    "dashboard/settings/change-password": {
      "de": "/dashboard/einstellungen/passwort-aendern"
    },
    "dashboard/settings/optin-settings": {
      "de": "/dashboard/einstellungen/optin"
    },
    "dashboard/statistics": {
      "de": "/dashboard/statistiken"
    },
    "dashboard/settings/contact-info": {
      "de": "/dashboard/einstellungen/kontaktinformation"
    },
    "customer-support/business-faq": {
      "de": "/kundensupport/business-faq"
    },
    "prices": {
      "de": "/preise"
    },
    "rewards": {
      "de": "/praemienprogramm"
    },
    "free-consulting": {
      "de": "/austausch"
    },
    "free-listing": {
      "de": "/kostenfrei-listen"
    },
    "restaurants-mit-lieferservice": {
      "de": "/restaurants-mit-lieferservice"
    },
    "privatanbieter": {
      "de": "/private-anbieter"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de",
    "language": "de-AT",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
