<template>
    <section class="timeline-container timeline">
        <div v-for="(point, index) in props.points" :key="point.title" class="timeline-block">
            <div class="timeline-img" :class="{ 'timeline-img-border': !point.completed }">
                <img v-if="point.completed" src="/assets/icons/circle-check-solid.svg" alt="Erledigt!" title="Erledigt!">
                <span v-else>{{ index + 1 }}</span>
            </div>
            <div class="timeline-content">
                <h2>{{ point.title }}</h2>
                <p>{{ point.text }}</p>
                <NuxtLink v-if="!point.disabled" :role="primaryCtaIndex === index ? 'button' : 'link'" :to="point.linkUrl" class="read-more">
                    {{ point.linkText }}
                </NuxtLink>
            </div>
        </div>
    </section>
</template>

<script setup>
const props = defineProps({
    points: { type: Array, required: true }
})

const primaryCtaIndex = computed(() => {
    return props.points.findIndex(point => !point.completed)
})
</script>

<style scoped>
/* --------------------------------
Main components
-------------------------------- */
.timeline-block::before {
    /* this is the vertical line */
    content: '';
    position: absolute;
    top: 37px;
    left: 18px;
    height: 97%;
    width: 3px;
    background: var(--primary);
}
.timeline-block:last-of-type::before {
    height: 0;
}

.timeline-block {
    position: relative;
    margin: 2em 0;
}
.timeline-img-border {
    border: 3px solid var(--primary);
}
.timeline-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
    color: var(--primary);
    font-size: 1.5rem;
    font-weight: bold;
    background-color: var(--white);
}

.timeline-content {
    margin-left: 40px;
    padding: var(--spacing);
    text-align: start;
}

@media (min-width: 425px) {
    .timeline-img {
        width: 56px;
        height: 56px;
        font-size: 2rem;
    }
    .timeline-block::before {
        /* this is the vertical line */
        top: 48px;
        left: 26px;
    }
    .timeline-content {
        margin-left: 60px;
    }
}
@media (min-width: 1024px) {
    .timeline-img {
        width: 72px;
        height: 72px;
        font-size: 2.5rem;
    }
    .timeline-content { padding-left: calc(1.5 * var(--spacing)); }
    .timeline-block::before {
        /* this is the vertical line */
        top: 70px;
        left: 35px;
    }
}
</style>
