<template>
    <DialogInfo @close="emit('close')">
        <template #title>
            <h3>Dein Anbieterprofil in Social Media posten</h3>
        </template>
        <template #content>
            <ul>
                <li>
                    Zeige der Welt dein optimiertes Anbieterprofil, indem du es öffentlich auf Social Media Plattformen wie Facebook, LinkedIn oder einer anderen Plattform deiner Wahl postest.
                </li>
                <li>
                    Mache einen Screenshot von deinem Beitrag und sende diesen zusammen mit einem Link zum Post an uns.
                </li>
            </ul>
        </template>
    </DialogInfo>
</template>

<script setup>
const emit = defineEmits(['close'])
</script>
