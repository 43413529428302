<template>
    <div>
        <h1>Es ist leider ein Fehler während der Anmeldung passiert.</h1>
        <NuxtLink :to="localePath('index')">
            Zurück zur Startseite
        </NuxtLink>
        <!--<p>{{ error }}</p>-->
    </div>
</template>

<script setup>
definePageMeta({
    auth: false
})
const localePath = useLocalePath()
const error = useRoute().query.error
</script>

<style scoped>

</style>
