<template>
    <section>
        <section class="white full-width">
            <div class="container">
                <h1 class="centered">
                    Unser Austausch: Sprich direkt mit Michael - Gründer von {{ $myT('mainAppDomain') }}
                </h1>
                <NoteBox
                    class="mt-2 quick-links-box"
                    :show-action="false"
                >
                    <template #content>
                        <div class="quick-links">
                            <NuxtLink to="#termin">
                                Persönliches Gespräch
                            </NuxtLink>
                            | <NuxtLink to="#formular">
                                Kontaktformular
                            </NuxtLink>
                        </div>
                    </template>
                </NoteBox>
                <h2
                    id="termin"
                    class="centered mt-2"
                >
                    Wähle einen Termin und lass uns sprechen
                </h2>
                <HubspotMeetingWidget class="mt-2" />
            </div>
        </section>
        <section class="full-width white">
            <div class="container form-wrapper">
                <h2
                    id="formular"
                    class="centered"
                >
                    Hier kannst du uns auch schriftlich kontaktieren
                </h2>
                <FormHubspotContact />
            </div>
        </section>
    </section>
</template>

<script setup>
definePageMeta({
    auth: false,
    layout: 'full-width'
})

useHead({
    title: 'Kostenfreie Erstberatung'
})
</script>

<style scoped>
.full-width {
   padding: 1rem 0;
}
.white {background-color: var(--white);}
.quick-links-box { margin-left: auto; margin-right: auto; }
.quick-links {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: var(--spacing);
    flex-wrap: wrap;
    font-size: 1.5rem;
}
.wa-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: calc(var(--spacing) * 1.5);
}
.wa-container h2 {
    width: 100%;
}
.wa-qr-code {
    width: 12.5rem;
    box-shadow: var(--shadow);
    margin-bottom: var(--spacing);
}
.wa-text a {
    width: 100%;
    box-shadow: var(--shadow);
}
.desktop-button {
    display: none;
}
.mobile-button {
    width: 100%;
}
.form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form-wrapper span {
    color: var(--dark-blue);
}
@media (min-width: 768px) {
    .wa-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        text-align: left;
        margin: auto 5rem;
    }
    .wa-qr-code {
        width: calc(50% - var(--block-spacing-horizontal) * 2);
        border: 1rem solid var(--white);
    }
    .wa-text {
        width: 50%;
    }
    .desktop-button {
        display: block;
    }
    .mobile-button {
        display: none;
    }
}

@media (min-width: 992px) {
    .wa-text p {
        font-size: 1.2rem;
    }
    .wa-qr-code{
        border-width: 2rem 3rem;
    }
}

@media (min-width: 1200px) {
    .wa-qr-code {
        width: 24rem;
    }
}
</style>
