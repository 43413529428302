<template>
    <NuxtLink
        v-if="!isAuthenticated"
        :to="localePath('login')"
        role="button"
        class="yellow small"
    >
        Anmelden
    </NuxtLink>
    <button
        v-else
        :aria-busy="loading"
        class="small nav-icon-container"
        @click="logout"
    >
        <img src="/assets/icons/logout-white.svg" alt="Logout Icon" loading="lazy">
        <span class="white">Abmelden</span>
    </button>
</template>

<script setup>
const { isAuthenticated } = useUser()

const { $backendApi } = useNuxtApp()
const localePath = useLocalePath()
const { signOut } = useAuth()
const { refreshToken } = useAuthState()
const toast = useToast()
const loading = ref(false)

async function logout () {
    loading.value = true
    await $backendApi('/auth/logout', { method: 'DELETE', body: { refresh_token: refreshToken.value } })
    await signOut({ redirect: true, callbackUrl: '/' })
    toast.success('Abgemeldet')
    loading.value = false
}
</script>

<style scoped>
.nav-icon-container {
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    gap: var(--spacing);
}
button {
    background-color: transparent;
    border-color: var(--white);
}
.white { color: var(--white); }
@media (min-width: 768px) {
    .nav-icon-container span {
        display: none;
    }
}
</style>
