<template>
    <section>
        <h1 class="centered big-heading">
            {{ decodeURIComponent(route.query.title ?? 'Preise') }}
        </h1>
        <stripe-pricing-table
            :pricing-table-id="route.params.stripePricingTableId"
            publishable-key="pk_live_51MZTd9Iv9lzFDcDsazKVvcRfRzV2JeXbXd5Nv0fAFExMinsYB485hnt8ZCVzyKJyIIxqlc4iiMQWr9OpYwrnJT7X00YPkstHoH"
        />
    </section>
</template>

<script setup>
definePageMeta({
    auth: false,
    layout: 'standard'
})

const route = useRoute()

useHead({
    script: [{ async: true, src: 'https://js.stripe.com/v3/pricing-table.js' }],
    meta: [{ name: 'robots', content: 'noindex' }]
})
</script>

<style scoped>
.big-heading {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
}
</style>
