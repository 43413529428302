<template>
    <div>
        <div class="space-between">
            <h2 class="heading">
                Wir achten auf Nachhaltigkeit
                <img class="sustainability-icon" src="/assets/icons/sustainability.svg" alt="Nachhaltigkeitsbadge">
            </h2>
            <ActionIcon
                v-if="!editSustainability"
                icon="pen-solid"
                :position-absolute="false"
                @click="editSustainability = true"
            />
        </div>
        <BusinessSustainabilityHint
            v-if="sustainabilityDescription.text.length === 0 || editSustainability"
            class="mt-1"
        />
        <InputRichTextDescription
            v-if="editSustainability"
            v-model="sustainabilityDescription"
            :show-hint="false"
            class="mt-1"
        />
        <div v-else v-html="sustainabilityDescription.text" />
        <div v-if="editSustainability" class="flex-row ">
            <button class="outline" @click="editSustainability = false">
                Abbrechen
            </button>
            <button :aria-busy="loading" @click="saveSustainability">
                Speichern
            </button>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    businessId: { type: Number, required: true }
})

const businessStore = useBusinessStore()
const { business } = storeToRefs(businessStore)

const toast = useToast()
const sustainabilityDescription = ref({ text: business.value.sustainabilityDescription || '' })
const editSustainability = ref(false)
const loading = ref(false)
async function saveSustainability () {
    loading.value = true
    try {
        await businessStore.updateSustainabilityInfo({
            businessId: props.businessId, info: sustainabilityDescription.value.text
        })
        toast.success('Gespeichert.')
        editSustainability.value = false
    } catch (error) {
        handleBackendError({ error, toast })
    }
    loading.value = false
}
</script>

<style scoped>
h2 { margin-bottom: 0; display: flex; align-items: center; column-gap: 0.5rem; }
</style>
