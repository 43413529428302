<template>
    <div>
        <h1>Du wirst gleich weitergeleitet...</h1>
        <progress />
    </div>
</template>

<script setup>
definePageMeta({
    auth: {
        unauthenticatedOnly: true,
        navigateAuthenticatedTo: '/dashboard'
    }
})
const query = useRoute().query
if (!query.key) {
    throw createError({
        statusCode: 400,
        statusMessage: 'Need to pass a valid key parameter.'
    })
}
const { data, error } = await useIFetch('/auth/oauth2/tokens', {
    method: 'POST', query: { key: query.key }
})
if (error.value) { throw error.value }

const { data: user, rawToken, rawRefreshToken } = useAuthState()
rawToken.value = data.value.accessToken
rawRefreshToken.value = data.value.refreshToken
user.value = data.value.user
</script>

<style scoped>

</style>
