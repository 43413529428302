export const useBusinessStore = defineStore('business', () => {
    const { $backendApi, $myT } = useNuxtApp()
    const couponsStore = useCouponsStore()

    const business = ref(null)
    const stopFetching = ref(false)
    function $reset () {
        business.value = null
    }

    const hasStripeSubscription = computed(() => {
        if (!business.value) {
            return false
        }
        return !!business.value.stripeSubscriptionId
    })
    const businessPublicLink = computed(() => {
        return `https://www.${$myT('mainAppDomain')}/anbieter/${business.value?.permanentName}-${business.value?.id}`
    })

    function getSocialMediaLinks () {
        return {
            facebook: business.value.facebookLink,
            instagram: business.value.instagramLink ? `https://www.instagram.com/${business.value.instagramLink}` : null,
            linkedin: business.value.linkedinLink,
            tiktok: business.value.tiktokLink,
            youtube: business.value.youtubeLink
        }
    }

    async function getBusiness () {
        if (stopFetching.value) {
            return
        }
        try {
            business.value = await $backendApi('/businesses/my-business')
            couponsStore.setBusinessCoupons(business.value.coupons)
        } catch (error) {
            stopFetching.value = error.status === 404
        }
    }
    async function createBusiness ({ name, description, address, contactInfo, logo, socialMediaLinks }) {
        const formData = new FormData()
        formData.append('name', name)
        formData.append('description', description)
        if (logo) {
            formData.append('logo', logo)
        }
        Object.keys(address).forEach((key) => {
            formData.append(key, address[key])
        })
        Object.keys(contactInfo).forEach((key) => {
            formData.append(key, contactInfo[key])
        })
        Object.keys(socialMediaLinks).forEach((key) => {
            if (socialMediaLinks[key]) {
                formData.append(key, socialMediaLinks[key])
            }
        })
        business.value = await $backendApi('/businesses/new', {
            method: 'POST', body: formData
        })
    }
    async function updateBusinessName ({ newName }) {
        business.value = await $backendApi(`/businesses/${business.value.id}/name`, {
            method: 'PUT', body: { name: newName }
        })
    }
    async function updateSustainabilityInfo ({ businessId, info }) {
        business.value = await $backendApi(`/businesses/${businessId}/sustainability-info`, {
            method: 'PUT', body: { info }
        })
    }
    async function updateSocialMedia ({ socialMedia }) {
        business.value = await $backendApi(`/businesses/${business.value.id}/social-media`, {
            method: 'PUT', body: socialMedia
        })
    }
    async function updateBusinessContactInfo ({ contactInfo }) {
        Object.keys(contactInfo).forEach((key) => {
            if (contactInfo[key] === '') {
                delete contactInfo[key]
            }
        })
        business.value = await $backendApi(`businesses/${business.value.id}/contact-info`, {
            method: 'PUT', body: contactInfo
        })
    }
    async function updateBusinessAddressInfo ({ address, lat, lon }) {
        business.value = await $backendApi(`businesses/${business.value.id}/address`, {
            method: 'PUT', body: { ...address, lat, lon }
        })
    }
    async function updateBusinessDescription ({ description }) {
        business.value = await $backendApi(`/businesses/${business.value.id}/description`, {
            method: 'PUT', body: { description }
        })
    }
    async function deleteBusinessLogo () {
        await $backendApi(`/businesses/${business.value.id}/logo`, { method: 'DELETE' })
        business.value.logo = null
    }
    async function uploadBusinessLogo ({ file }) {
        const formData = new FormData()
        formData.append('file', file)
        business.value = await $backendApi(`/businesses/${business.value.id}/logo`, {
            method: 'PUT', body: formData
        })
    }

    return {
        $reset,
        business,
        getBusiness,
        createBusiness,
        businessPublicLink,
        hasStripeSubscription,
        getSocialMediaLinks,
        updateSocialMedia,
        updateBusinessName,
        updateSustainabilityInfo,
        updateBusinessContactInfo,
        updateBusinessAddressInfo,
        updateBusinessDescription,
        deleteBusinessLogo,
        uploadBusinessLogo
    }
})
