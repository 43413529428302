export function initializeOpeningHours (editableObject, sourceObject) {
    for (let i = 0; i < 7; i++) {
        editableObject[i] = {
            dayOfWeek: i,
            openingTime: '',
            closingTime: '',
            uponRequest: false
        }
    }
    // Populate opening hours with data from the prop
    (sourceObject || []).forEach((entry) => {
        editableObject[entry.dayOfWeek].openingTime = entry.openingTime
        editableObject[entry.dayOfWeek].closingTime = entry.closingTime
        editableObject[entry.dayOfWeek].uponRequest = entry.uponRequest
    })
    return editableObject
}

export function formatOpeningHoursForBackend (openingHours) {
    // filter out only valid hours
    const validHours = openingHours.filter(h =>
        (h.openingTime && h.closingTime) || h.uponRequest
    )
    // set hours to null if uponRequest is chosen
    validHours.forEach((h) => {
        if (h.uponRequest) {
            h.openingTime = null
            h.closingTime = null
        }
    })
    // fix format for backend
    const currentDate = new Date().toISOString().split('T')[0]
    return validHours.map((h) => {
        // If 'uponRequest' is true, return the object as is
        if (h.uponRequest) {
            return { ...h } // Using spread syntax to create a shallow copy
        }
        // If 'uponRequest' is false, modify the opening and closing times
        return {
            ...h, // Copy all properties from the original object
            openingTime: `${currentDate}T${h.openingTime}`,
            closingTime: `${currentDate}T${h.closingTime}`
        }
    })
}

export function createFormDataForNewService ({ dataFields, serviceCardImage }) {
    delete dataFields.step
    const formData = new FormData()
    if (serviceCardImage) { formData.append('serviceCardImage', serviceCardImage) }
    formData.append('locations', JSON.stringify(dataFields.locations?.map(l => l.id)))
    formData.append('openingHours', JSON.stringify(formatOpeningHoursForBackend(dataFields.openingHours)))

    // Append the data fields to the FormData object
    for (const [key, value] of Object.entries(dataFields)) {
        if (['openingHours', 'locations'].includes(key)) { continue }
        formData.append(key, value)
    }
    return formData
}
