<template>
    <div>
        <LazyBusinessContactInfoEditDialog
            v-if="showContactInfoDialog"
            @close="showContactInfoDialog = false"
        />
        <p class="space-between mt-1">
            <b>Dein Kontakt zum Anbieter</b>
            <ActionIcon
                class="action-icon"
                :position-absolute="false"
                icon="pen-solid"
                @click="showContactInfoDialog = true"
            />
        </p>
        <p class="contact-line">
            <img src="/assets/icons/mail-solid.svg" alt="Email Icon">
            <a v-if="business?.email" :href="'mailto:' + business?.email" target="_blank">
                {{ business?.email }}
            </a>
            <i v-else>E-Mail nicht angegeben</i>
        </p>
        <p class="contact-line">
            <img src="/assets/icons/phone.svg" alt="Phone Icon">
            <a v-if="business?.phone" :href="'tel:' + business?.phone" class="phone">
                {{ business?.phone }}
            </a>
            <i v-else>Telefonnummer nicht angegeben</i>
        </p>
        <p class="contact-line">
            <img src="/assets/icons/new-tab.svg" alt="External Link Icon">
            <a v-if="business?.customerServiceLink" :href="business?.customerServiceLink" target="_blank" rel="nofollow">
                Zum Kundenservice
            </a>
            <i v-else>Kundenservice nicht angegeben</i>
        </p>
        <div
            v-if="showLink"
            class="block-link yellow contact-line"
            role="button"
            @click="redirectToBusinessWebsite"
        >
            Zum Anbieter
        </div>
    </div>
</template>

<script setup>
const { business } = defineProps({
    business: { type: Object, required: true }
})

const showContactInfoDialog = ref(false)

// external business link
const showLink = computed(() => {
    return business.websiteLink && !business.websiteLink.includes('firmen.wko')
})
function redirectToBusinessWebsite () {
    window.open(business.websiteLink, '_blank')
}
</script>

<style scoped>
.contact-line {
    margin-top: 0.5rem;
}
</style>
