<template>
    <div class="statistics-container">
        <StatisticsSummaryCard
            :figure="statistics.totalImpressions"
            description="Einblendungen gesamt"
            icon="einblendungen-gesamt.svg"
        />
        <StatisticsSummaryCard
            :figure="statistics.monthlyImpressions"
            description="Einblendungen in diesem Monat"
            icon="einblendungen-monatlich.svg"
        />
        <StatisticsSummaryCard
            :figure="statistics.serviceClicks + statistics.anbieterClicks"
            description="Interessenten-Klicks gesamt"
            icon="clicks.svg"
        />
        <StatisticsSummaryCard
            :figure="statistics.totalServices"
            description="Services gesamt"
            icon="services.svg"
        />
        <small>Zahlen bis inklusive {{ getYesterdaysDate() }}</small>
    </div>
</template>

<script setup>
const { business } = storeToRefs(useBusinessStore())
const statisticsStore = useStatisticsStore()
const { statistics } = storeToRefs(statisticsStore)

if (!statistics.value) {
    await statisticsStore.fetchStatistics(business.value.id)
}
</script>

<style scoped>
.statistics-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}
small {
    grid-column: span 2;
}
@media (min-width: 1024px) {
    .statistics-container {
        grid-template-columns: repeat(4, 1fr);
    }
}
</style>
