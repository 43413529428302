<template>
    <section>
        <NavigationGoBack />
        <LazyDialogInfo v-if="showForm" @close="showForm = false">
            <template #title>
                <h3>Angebot erstellen</h3>
            </template>
            <template #content>
                <FormBusinessCoupon v-model="couponData" :method="method" @done="showForm = false" />
            </template>
        </LazyDialogInfo>
        <div class="space-between">
            <h1 class="mt-1">
                Verwalte deine Angebote & Aktionen ({{ totalBusinessCoupons }})
            </h1>
            <button @click="openNewForm">
                Angebot erstellen
            </button>
        </div>
        <div class="coupons-wrapper mt-1">
            <BusinessCouponCode
                v-for="coupon in businessCoupons"
                :key="coupon.id"
                :coupon="coupon"
                @edit="editCoupon"
            />
        </div>
    </section>
</template>

<script setup>
const { businessCoupons, totalBusinessCoupons } = storeToRefs(useCouponsStore())

const showForm = ref(false)
const method = ref('POST')
const emptyState = {
    title: '',
    content: '',
    couponCode: '',
    expiresAt: '',
    useBusinessLink: true,
    link: ''
}
const couponData = ref(emptyState)

function editCoupon (coupon) {
    method.value = 'PUT'
    couponData.value = coupon
    showForm.value = true
}

function openNewForm () {
    method.value = 'POST'
    couponData.value = emptyState
    showForm.value = true
}
</script>

<style scoped>
.space-between {
    flex-wrap: wrap;
}
.space-between button { width: fit-content; }
.coupons-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing);
}
@media (min-width: 425px) {
    .space-between {
        flex-wrap: nowrap;
        column-gap: var(--spacing);
    }
}
@media (min-width: 1440px) {
    .coupons-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: var(--spacing);
    }
}
</style>
