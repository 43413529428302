export function useCustomHead () {
    /* useHead({
        titleTemplate: '%s',
         script: [{
            key: 'hubspot-chat',
            src: '//js-eu1.hs-scripts.com/26998599.js',
            defer: true,
            async: true
        }]
    }) */
    onMounted(() => {
        const _hsp = window._hsp || []
        _hsp.push(['addPrivacyConsentListener', function (consent) {
            // update consent for google tag manager
            const consentMode = {
                functionality_storage: consent.categories.functionality ? 'granted' : 'denied',
                security_storage: consent.categories.functionality ? 'granted' : 'denied',
                ad_storage: consent.categories.advertisement ? 'granted' : 'denied',
                analytics_storage: consent.categories.analytics ? 'granted' : 'denied',
                personalization: consent.categories.advertisement ? 'granted' : 'denied'
            }
            // gtag('consent', 'update', consent)
            localStorage.setItem('consentMode', JSON.stringify(consentMode))
        }])

        // Taboola Pixel Code
        window._tfa = window._tfa || []
        window._tfa.push({ notify: 'event', name: 'page_view', id: 1642596 })
        // eslint-disable-next-line no-unused-expressions
        !(function (t, f, a, x) {
            if (!document.getElementById(x)) {
                t.async = 1; t.src = a; t.id = x; f.parentNode.insertBefore(t, f)
            }
        }(document.createElement('script'),
            document.getElementsByTagName('script')[0],
            '//cdn.taboola.com/libtrc/unip/1642596/tfa.js',
            'tb_tfa_script'))

        // Bing Conversion Tracking
        try {
            (function (w, d, t, r, u) {
                let f, n, i
                w[u] = w[u] || [], f = function () {
                    const o = { ti: '187117330', enableAutoSpaTracking: true }
                    o.q = w[u], w[u] = new UET(o), w[u].push('pageLoad')
                },
                n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function () {
                    const s = this.readyState
                    s && s !== 'loaded' && s !== 'complete' || (f(), n.onload = n.onreadystatechange = null)
                },
                i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i)
            })(window, document, 'script', '//bat.bing.com/bat.js', 'uetq')
        } catch (e) {
            console.error(e)
        }

        // eslint-disable-next-line camelcase
        const _linkedin_partner_id = '5231746'
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        (function (l) { if (!l) { window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) }; window.lintrk.q = [] } const s = document.getElementsByTagName('script')[0]; const b = document.createElement('script'); b.type = 'text/javascript'; b.async = true; b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'; s.parentNode.insertBefore(b, s) })(window.lintrk)
    })
}
