export function useStripeCheckout () {
    const { data } = useAuthState()
    const toast = useToast()
    const { $captureEvent } = useNuxtApp()
    const loading = ref(false)
    async function redirectToStripeCheckout ({ tier, annual }) {
        loading.value = true
        try {
            const { url } = await $fetch('/api/stripe/create-session', {
                method: 'POST',
                body: {
                    customerEmail: data.value.email,
                    customerId: data.value.stripeCustomerId,
                    tier,
                    annual
                }
            })
            $captureEvent('initiate-checkout')
            window.location = url
        } catch (error) {
            toast.error(error.message)
        }
        loading.value = false
    }
    return { redirectToStripeCheckout, loading }
}
