<template>
    <Teleport to="body">
        <dialog open>
            <article>
                <CloseIcon @close="emit('close')" />
                <h3>Kontaktinformationen</h3>
                <InputBusinessContactInfo v-model="newContactInfo" />
                <small>* Pflichtfelder</small>
                <button :disabled="disableSubmit" :aria-busy="loading" class="mt-1" @click="confirm">
                    Fertig
                </button>
            </article>
        </dialog>
    </Teleport>
</template>

<script setup>
const emit = defineEmits(['close'])

// common composables
const toast = useToast()
const businessStore = useBusinessStore()
const { business } = storeToRefs(businessStore)

const newContactInfo = ref({
    email: business.value?.email || '',
    phone: business.value?.phone || '',
    websiteLink: business.value?.websiteLink || '',
    customerServiceLink: business.value?.customerServiceLink || ''
})
const { linksDifferentAndValid } = useBusinessContactInfo(newContactInfo.value)

const disableSubmit = computed(() => {
    return !linksDifferentAndValid.value || (newContactInfo.value.enableContactForm && !newContactInfo.value.email)
})

const loading = ref(false)
async function confirm () {
    loading.value = true
    try {
        await businessStore.updateBusinessContactInfo({
            contactInfo: newContactInfo.value
        })
        toast.success('Erfolgreich gespeichert!')
        emit('close')
    } catch (error) {
        handleBackendError({ error, toast })
    }
    loading.value = false
}
</script>

<style scoped>
@media (min-width: 768px) {
    article {
        width: 80%;
        max-width: 800px;
    }
}
</style>
