<template>
    <DialogInfo @close="emit('close')">
        <template #title>
            <h3>Vollständiges Anbieterprofil</h3>
        </template>
        <template #content>
            <ul>
                <li>
                    Ein vollständiges Profil wird besser gefunden: Achte insbesondere auf genügend Bilder, ausreichend lange Texte und Vollständigkeit deines Profils.
                </li>
                <li>
                    Je mehr Daten und Bilder du angibst, desto höher ist deine Profilstärke.
                </li>
                <li>
                    Den Meilenstein hast du bei einem durchschnittlichen Profilstärke von über 80 erreicht.
                </li>
            </ul>
            <ScoreOverallIndicator
                v-if="isAuthenticated"
                class="center"
            />
            <RegistrationCtaBox v-else />
        </template>
    </DialogInfo>
</template>

<script setup>
const emit = defineEmits(['close'])
const { isAuthenticated } = useUser()
</script>

<style scoped>
.center {
    margin-left: auto;
    margin-right: auto;
}
</style>
