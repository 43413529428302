<template>
    <div>
        <div class="period-toggle flex-center">
            <div
                :class="{ bold: !annual }"
                @click="annual = false"
            >
                Monatlich
            </div>
            <input
                v-model="annual"
                type="checkbox"
                role="switch"
            >
            <div
                :class="{ bold: annual }"
                class="flex-center"
                @click="annual = true"
            >
                Jährlich  <PricingSaleTag off="18" />
            </div>
        </div>
        <div class="pricing-table mt-1">
            <div class="tier">
                <h2 class="tier-header">
                    Fast geschenkt
                </h2>
                <div class="tier-body">
                    <PricingTierItem
                        text="Sofort Reichweite"
                        :bold="true"
                    />
                    <PricingTierItem text="Anbieterprofil als Mini-Webseite" />
                    <PricingTierItem text="Unlimitierte Anzahl an Service-Leistungen" />
                    <PricingTierItem text="Unlimitierte Anzahl an Kundenanfragen" />
                </div>
                <div class="cta-wrapper">
                    <PricingPriceBlock
                        v-if="annual"
                        old-price="249,99"
                        new-price="175,00"
                        off="30"
                        label="Jahr"
                    />
                    <PricingPriceBlock
                        v-else
                        old-price="24,99"
                        new-price="17,50"
                        off="30"
                        label="Monat"
                    />
                    <button
                        :aria-busy="loading"
                        class="mt-1 outline"
                        @click="bookTier(1)"
                    >
                        Jetzt starten
                    </button>
                </div>
            </div>
            <div class="tier special scale-on-hover">
                <h2 class="tier-header">
                    Super günstig
                </h2>
                <div class="tier-body">
                    <PricingTierItem
                        text="Sofort Reichweite"
                        :bold="true"
                    />
                    <PricingTierItem
                        text="Erfolgsgarantie"
                        :bold="true"
                    />
                    <PricingTierItem
                        text="Top-Platzierung in der Suche"
                        :bold="true"
                    />
                    <PricingTierItem text="Persönlicher Ansprechpartner" />
                    <PricingTierItem text="Werbefrei" />
                </div>
                <div class="cta-wrapper">
                    <PricingPriceBlock
                        v-if="annual"
                        old-price="349,99"
                        new-price="199,99"
                        off="43"
                        label="Jahr"
                    />
                    <PricingPriceBlock
                        v-else
                        old-price="34,99"
                        new-price="19,99"
                        off="43"
                        label="Monat"
                    />
                    <button
                        :aria-busy="loading"
                        class="mt-1"
                        @click="bookTier(2)"
                    >
                        {{ annual ? '3 Monate' : '1 Monat' }} geschenkt
                    </button>
                </div>
            </div>
            <div class="tier">
                <h2 class="tier-header">
                    Leistung 🚀
                </h2>
                <div class="tier-body">
                    <PricingTierItem
                        text="Keine Fixkosten"
                        :bold="true"
                    />
                    <PricingTierItem
                        text="Kein Risiko"
                        :bold="true"
                    />
                    <PricingTierItem
                        text="Komplett Leistungsorientiert"
                        :bold="true"
                    />
                    <PricingTierItem text="Persönlicher Ansprechpartner" />
                    <PricingTierItem text="Effektive Werbung" />
                </div>
                <div class="cta-wrapper">
                    <PricingPriceBlock
                        new-price="0,95"
                        label="Klick"
                    />
                    <button
                        :aria-busy="loading"
                        class="mt-1 yellow"
                        @click="bookTier(3)"
                    >
                        <b>Jetzt Platz sichern</b>
                    </button>
                </div>
            </div>
        </div>
        <i class="block mt-1">Alle Preise verstehen sich zuzüglich der gesetzlichen Umsatzsteuer.</i>
    </div>
</template>

<script setup>
const localePath = useLocalePath()
const annual = defineModel({ type: Boolean, default: false })

const { isAuthenticated } = useUser()
function bookTier (tier) {
    if (!isAuthenticated.value) {
        return navigateTo(localePath('become-partner'))
    }
    redirectToStripeCheckout({ tier, annual: annual.value })
}

const { loading, redirectToStripeCheckout } = useStripeCheckout()
</script>

<style scoped>
del { font-weight: bold; }
h2 { margin-bottom: 0; }
.pricing-table {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
    justify-content: flex-start;
}
.tier {
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    background-color: var(--white);
    border: 1px solid var(--dark-blue);
    display: flex;
    flex-direction: column;
}
.special { background-color: var(--primary-100); }
.tier-header {
    background-color: var(--dark-blue);
    width: 100%;
    height: 3rem;
    color: var(--yellow);
    display: grid;
    place-items: center;
}
.tier-body {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 0.75rem;
}
.cta-wrapper {
    margin-top: auto;
    padding: 0.5rem;
}
.period-toggle {
    font-size: 1.5rem;
    cursor: pointer;
}
.period-toggle input[type=checkbox] {
    font-size: 2rem;
    margin-right: 0;
}
.bold { font-weight: bold; }
@media (min-width: 768px) {
    .pricing-table {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: flex-start;
        column-gap: 1rem;
        row-gap: 1rem;
    }
}
</style>
