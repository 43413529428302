<template>
    <form v-if="!emailUnconfirmed" method="post" @submit.prevent="login">
        <label for="email">
            E-Mail Adresse *
            <input
                id="email"
                v-model="email"
                type="email"
                name="email"
                placeholder="E-Mail Adresse eingeben"
                required
            >
        </label>
        <div class="space-between">
            <span>Passwort *</span>
            <NuxtLink :to="localePath('reset-password')">
                Passwort vergessen?
            </NuxtLink>
        </div>
        <label for="password">
            <input
                id="password"
                v-model="password"
                type="password"
                name="password"
                placeholder="Passwort eingeben"
                required
            >
        </label>
        <button :aria-busy="submitting" type="submit">
            Einloggen
        </button>
    </form>
    <div v-else>
        <p>
            Falls du den Bestätigungslink nicht erhalten hast, kannst du ihn hier nochmals anfragen.
            Folge dann bitte dem Link, um Deine E-Mailadresse zu bestätigen.
        </p>
        <FormResendEmailConfirmation />
        <a class="centered" @click="emailUnconfirmed = false">
            Zurück
        </a>
    </div>
</template>

<script setup>
const emailUnconfirmed = ref(false)

onMounted(() => {
    emailUnconfirmed.value = useRoute().query.emailUnconfirmed
})

const store = useBusinessStore()
const serviceStore = useServiceStore()
const { business } = storeToRefs(store)

const toast = useToast()
const { signIn } = useAuth()
const localePath = useLocalePath()
const submitting = ref(false)
const email = ref('')
const password = ref('')
async function login () {
    submitting.value = true
    // Check if email and password are provided
    if (email.value === '' || password.value === '') {
        submitting.value = false
        return toast.error('Bitte Form vollständig ausfüllen.')
    }
    try {
        await signIn(
            { email: email.value, password: password.value },
            { redirect: false }
        )
        toast.success('Authentifizierung erfolgreich.')
        try {
            await store.getBusiness()
            await serviceStore.getServices(business.value.id)
        } catch (err) {
            store.$reset()
            serviceStore.$reset()
        }
        useRouter().push(localePath('dashboard'))
    } catch (err) {
        submitting.value = false
        email.value = ''
        password.value = ''
        console.log(err)
        const msg = err.data?.message
        if (msg === 'Bitte zuerst E-Mail bestätigen.') {
            emailUnconfirmed.value = true
        }
        toast.error(`Login fehlgeschlagen. ${msg}`)
    }
}
</script>

<style scoped>
a { display: block; }
</style>
