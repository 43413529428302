<template>
    <dialog open>
        <article>
            <CloseIcon @close="emit('close')" />
            <h3 :aria-busy="props.loading">
                {{ props.name }} auf der Karte
            </h3>
            <div v-if="!props.loading" id="map-wrap">
                <client-only>
                    <LMap
                        :zoom="props.zoom"
                        :center="props.center"
                    >
                        <LTileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
                        <LGeoJson :geojson="props.geojson" />
                    </LMap>
                </client-only>
            </div>
        </article>
    </dialog>
</template>

<script setup>
const props = defineProps({
    geojson: { type: Object, required: true },
    center: { type: Array, required: true },
    zoom: { type: Number, required: false, default: 12 },
    name: { type: String, required: true },
    loading: { type: Boolean, required: false, default: false }
})
const emit = defineEmits(['close'])
</script>

<style scoped>
article {
    padding: var(--spacing);
    position: relative;
    background-color: var(--white);
    width: 80%;
    height: 80%;
}
#map-wrap {
    height: calc(100% - 2.5rem);
    width: 100%;
}
</style>
