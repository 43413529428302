<template>
    <form class="some-container" @submit.prevent="confirm">
        <div class="some-input">
            <img src="/icons/some-color/instagram-color.svg" alt="Instagram Icon">
            <input
                v-model="newSocialMedia.instagramUsername"
                type="text"
                placeholder="dein_insta_username (ohne @)"
            >
        </div>
        <span>
            <img class="easy-icon" src="/assets/icons/circle-check-solid.svg" alt="Easy!">
            Wir betten dein Instagram auf deinem Profil ein - easy mehr Reichweite!
        </span>
        <hr>
        <b>Links zu anderen Social Media</b>
        <InputSocialMedia
            v-model="newSocialMedia.facebookLink"
            some-name="facebook"
            :must-start-with="['https://www.facebook.com/']"
            placeholder="https://www.facebook.com/"
        />
        <InputSocialMedia
            v-model="newSocialMedia.linkedinLink"
            some-name="linkedin"
            :must-start-with="['https://www.linkedin.com/']"
            placeholder="https://www.linkedin.com/"
        />
        <InputSocialMedia
            v-model="newSocialMedia.tiktokLink"
            some-name="tiktok"
            :must-start-with="['https://www.tiktok.com/', 'https://tiktok.com/']"
            placeholder="https://www.tiktok.com/"
        />
        <InputSocialMedia
            v-model="newSocialMedia.youtubeLink"
            some-name="youtube"
            :must-start-with="['https://www.youtube.com/', 'https://youtu.be/']"
            placeholder="https://www.youtube.com/"
        />
        <button class="mt-1" :aria-busy="loading" :disabled="invalidLinks">
            {{ buttonText }}
        </button>
    </form>
</template>

<script setup>
const emit = defineEmits(['submit'])
defineProps({
    loading: { type: Boolean, default: false },
    buttonText: { type: String, default: 'Speichern' }
})

const newSocialMedia = defineModel({ type: Object })

const invalidLinks = computed(() => {
    return Object.values(newSocialMedia.value).some(value => value.invalid === true)
})

function confirm () {
    if (invalidLinks.value) {
        return toast.error('Alle Links müssen mit https anfangen.')
    }
    emit('submit')
}
</script>

<style scoped>
hr {
    height: 0;
    border: 0;
    width: 100%;
    border-top: 1px solid var(--muted-color);
    color: inherit;
}
.some-container {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}
.some-input {
    display: flex;
    flex-direction: row;
    column-gap: var(--spacing);
    align-items: center;
}
.some-input input { margin: 0; }
.some-input img {
    width: 2rem;
    height: auto;
}
.easy-icon { width: 1rem; }
</style>
