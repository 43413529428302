<template>
    <div class="service-card scale-on-hover">
        <div v-if="editImage || !service.mainImage">
            <InputImageUpload
                class="placeholder-image"
                :selected-file="selectedFile"
                :unique-identifier="String(service.id)"
                @remove-file="selectedFile = null"
                @update:selected-file="(file) => selectedFile = file"
            >
                <template #dropzone-card>
                    <img
                        src="/assets/icons/camera.svg"
                        alt="Camera Icon"
                        draggable="false"
                    >
                    Hier klicken, um Bild für <br>die Service-Card hochzuladen.
                    Empfehlung: Verhältnis 4:3, ca. 400 x 300px
                </template>
            </InputImageUpload>
            <button v-if="selectedFile" :aria-busy="loading" class="mt-1 save-button" @click="save">
                Speichern
            </button>
        </div>
        <div @click="navigate">
            <div v-if="service.mainImage && !editImage" class="image-cont">
                <img :src="image" :alt="service.name" class="service-image">
                <ActionIcon
                    v-if="!useLink"
                    icon="pen-solid"
                    :position-absolute="false"
                    class="edit-image green-border scale-on-hover"
                    @click="editImage = true"
                />
                <ActionIcon
                    v-if="!useLink"
                    :icon="deleteIcon"
                    :position-absolute="false"
                    class="trash-image red-border scale-on-hover"
                    @click="deleteImage"
                />
                <span v-if="service.hasCoupons" class="angebot">Angebot</span>
            </div>
            <div class="service-card-content">
                <div>
                    <p v-if="service.minPrice">
                        <strong>ab {{ service.minPrice.toFixed(2) }} €</strong>
                    </p>
                    <div class="edit-name">
                        <p class="service-name">
                            {{ service.name }}
                            <img
                                v-if="service.hasSustainability"
                                src="/assets/icons/sustainability.svg"
                                alt="Nachhaltigkeitsbadge"
                                loading="lazy"
                                class="verified-icon"
                            >
                        </p>
                    </div>
                </div>
                <div>
                    <p class="business-name-text">
                        {{ service.business.name }}
                    </p>
                </div>
            </div>
            <div class="mt-auto service-card-content">
                <p v-if="service.totalReviews > 0" class="rating">
                    <RatingStarImages :unique-identifier="String(service.id)" :rating="service.rating" />
                    <span>{{ truncatedRating }}</span>
                    <span class="rating-count">({{ totalReviewsCount }})</span>
                </p>
                <div class="mt-auto buttons">
                    <button class="small outline">
                        Bearbeiten
                    </button>
                    <ScoreCircleIndicator :rating="service.profileScore" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    service: { type: Object, required: true },
    useLink: { type: Boolean, required: false, default: true }
})

const loading = ref(false)
const selectedFile = ref(null)
const editImage = ref(false)
const askingForDelete = ref(false)
const deleting = ref(false)

const localePath = useLocalePath()
const link = localePath({
    name: 'dashboard-services-edit-id',
    params: { id: props.service.id },
    query: { view: 'basic' }
})

const deleteIcon = computed(() => {
    return askingForDelete.value ? 'check-solid' : 'trash-can-solid'
})

const spacesUrl = useRuntimeConfig().public.spacesUrl
const image = computed(() => {
    return props.service.mainImage
        ? `${spacesUrl}/images/${props.service.serviceType}_service/${props.service.mainImage}`
        : null
})

const truncatedRating = computed(() => {
    return props.service.rating === null ? '' : props.service.rating.toFixed(1)
})

const totalReviewsCount = computed(() => {
    return props.service.totalReviews > 20
        ? '20+'
        : props.service.totalReviews
})

function navigate () {
    if (props.useLink) { navigateTo(link) }
}

const toast = useToast()
const serviceStore = useServiceStore()
async function save () {
    loading.value = true
    if (selectedFile.value?.file) {
        try {
            await serviceStore.updateServiceCardImage({ image: selectedFile.value.file, serviceId: props.service?.id })
            toast.success('Bild hochgeladen und gespeichert.')
            selectedFile.value = null
            editImage.value = false
        } catch (error) {
            handleBackendError({ error, toast })
        }
    }
    loading.value = false
}

async function deleteImage () {
    if (!askingForDelete.value) {
        askingForDelete.value = true
        return
    }
    deleting.value = true
    // do delete after confirmation
    try {
        await serviceStore.deleteServiceCardImage(props.service?.id)
        toast.success('Bild gelöscht.')
    } catch (error) {
        handleBackendError({ error, toast })
    }
    askingForDelete.value = false
    deleting.value = false
}
</script>

<style scoped>
a {
    text-decoration: none;
}
p {
    margin-bottom: 0;
}
p[role=link] { color: var(--color); }
label {
    margin-bottom: calc(var(--spacing)* 0.15);
}
.image-cont {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    position: relative;
    min-height: 160px;
}
.angebot {
    display: grid;
    place-items: center;
    background-color: var(--red);
    color: var(--white);
    position: absolute;
    top: 0;
    left: 0;
    width: 5rem;
    height: 1.7rem;
    text-align: center;
    font-weight: bold;
    border-radius: var(--border-radius) 0 var(--border-radius) 0;
}
.service-image {
    width: 100%;
    height: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
}
.service-card {
    height: 100%;
    display: grid;
    border-radius: var(--border-radius);
    background-color: var(--white);
    box-shadow: var(--small-shadow);
    cursor: pointer;
    max-width: 15rem;
    color: var(--dark-blue);
}
.service-card > div{
    display: grid;
}

.service-card-content {
   padding: 0.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}
.service-name {
    font-size: 1rem;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.business-name-text {
    margin-bottom: auto;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rating {
    display: flex;
    align-items: center;
    column-gap: 0.2rem;
    color: var(--dark-blue);
    font-size: .9rem;
    margin-top: auto;
}
.rating-count {
    color: var(--muted-color);
}
.mt-auto { margin-top: auto; }
.placeholder-image {
    position: relative;
    display: grid;
    text-align: center;
    place-items: center;
    font-size: calc(0.6 * var(--font-size));
    border-radius: var(--border-radius);
    border: 2px dashed var(--neutral-200);
    background-color: var(--neutral-100);
    overflow: hidden;
    width: 100%;
}
.edit-image {
    position: absolute;
    top: 0;
    right: 0;
}
.trash-image {
    position: absolute;
    top: 0;
    right: 3rem;
}
.edit-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.save-button {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}
.buttons {
    display: grid;
    grid-template-columns: 6fr 1fr;
    column-gap: 0.3rem;
    align-items: center;
}
</style>
