<template>
    <div class="contact">
        <LazyServiceBookingInfoEditDialog
            v-if="showBookingInfoDialog"
            :service-id="service.id"
            @close="showBookingInfoDialog = false"
        />
        <LazyServicePaymentMethodsDialog
            v-if="showPaymentMethodsDialog"
            :service-id="service.id"
            @close="showPaymentMethodsDialog = false"
        />
        <img
            class="contact-image"
            :src="cardImage"
            :alt="service.name"
        >
        <BusinessSocialMedia />
        <p class="contact-line mt-1">
            <img
                src="/assets/icons/new-tab.svg"
                alt="External Link Icon"
            >
            <a
                v-if="service.bookingLink"
                :href="service.bookingLink"
                target="_blank"
                rel="nofollow"
            >
                Online buchen
            </a>
            <i v-else>Webseite für Buchung nicht angegeben</i>
        </p>
        <ActionIcon
            class="action-icon indent-up"
            icon="pen-solid"
            :position-absolute="false"
            @click="showBookingInfoDialog = true"
        />
        <p class="space-between">
            <b>Akzeptierte Zahlungsmethoden</b>
            <ActionIcon
                icon="pen-solid"
                :position-absolute="false"
                @click="showPaymentMethodsDialog = true"
            />
        </p>
        <div
            v-if="paymentMethods?.length > 0"
            class="methods-container"
        >
            <div
                v-for="(paymentMethod, index) in paymentMethods"
                :key="index"
                class="method"
                :data-tooltip="paymentMethod.displayName"
            >
                <img
                    :src="`${spacesUrl}/images/payment-methods/${paymentMethod.logo}`"
                    :alt="paymentMethod.displayName"
                >
            </div>
        </div>
        <i v-else>Keine Zahlungsmethoden angegeben</i>
    </div>
</template>

<script setup>
const props = defineProps({
    serviceId: { type: Number, required: true }
})

const serviceStore = useServiceStore()
const service = computed(() => serviceStore.getServiceById(props.serviceId))

const showBookingInfoDialog = ref(false)

const spacesUrl = useRuntimeConfig().public.spacesUrl

const cardImage = computed(() => {
    if (!service.value.mainImage) {
        return `${spacesUrl}/images/categories/${service.value.fallbackImage}`
    }
    return service.value.provider !== 'allekommen'
        ? service.value.mainImage
        : `${spacesUrl}/images/${service.value.serviceType}_service/${service.value.mainImage}`
})

const showPaymentMethodsDialog = ref(false)
const paymentMethods = computed(() => service.value.paymentMethods)
</script>

<style scoped>
p { margin-bottom: 0; }
.indent-up { margin-top: -2.7rem; }
.methods-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    flex-wrap: wrap;
}
.method {
    background-color: var(--white);
    padding: 0.5rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--neutral-200);
}
.method img {
    height: 1.5rem;
    width: auto;
}
.contact-image {
    width: 100%;
    border-radius: var(--border-radius);
}
</style>
