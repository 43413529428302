<template>
    <section>
        <NavigationGoBack />
        <div class="top-content">
            <div>
                <h1 class="mt-1">
                    Erreiche Meilensteine und sichere dir bis 585 € Prämien
                </h1>
                <p><strong>Das Prämienprogramm von {{ $myT('mainAppDomain') }} für Anbieter: Mit jedem erreichten Meilenstein wartet eine attraktive Guthaben-Prämie auf dich. Lass uns gemeinsam Erfolge feiern!</strong></p>
            </div>
            <img
                src="/assets/images/dashboard/reward-system.png"
                alt="Das Prämienprogramm von Locaverse für Anbieter"
            >
        </div>
        <table class="mt-1">
            <tr>
                <th>Meilenstein</th>
                <th class="centered">
                    Prämie
                </th>
                <th class="centered">
                    Häufigkeit
                </th>
            </tr>
            <tr>
                <td class="info-cell">
                    Vollständiges Anbieterprofil <InfoIcon
                        class="info-icon"
                        @click="showAnbiterProfil = true"
                    />
                </td>
                <td class="centered">
                    <b>5 €</b>
                </td>
                <td class="centered">
                    einmalig
                </td>
            </tr>
            <tr>
                <td class="info-cell">
                    Poste dein Anbieterprofil in Social Media <InfoIcon
                        class="info-icon"
                        @click="showProfilSocial = true"
                    />
                </td>
                <td class="centered">
                    <b>10 €</b>
                </td>
                <td class="centered">
                    einmalig
                </td>
            </tr>
            <tr>
                <td class="info-cell">
                    Poste eine Kategorieseite in Social Media <InfoIcon
                        class="info-icon"
                        @click="showKategorieSeite = true"
                    />
                </td>
                <td class="centered">
                    <b>10 €</b>
                </td>
                <td class="centered">
                    5 mal
                </td>
            </tr>
            <tr>
                <td class="info-cell">
                    Anbieterprofil mit 5 Bewertungen <InfoIcon
                        class="info-icon"
                        @click="showAnbieterBewertung = true"
                    />
                </td>
                <td class="centered">
                    <b>20 €</b>
                </td>
                <td class="centered">
                    einmalig
                </td>
            </tr>
            <tr>
                <td class="info-cell">
                    Schenke 50 € und erhalte 50 € <InfoIcon
                        class="info-icon"
                        @click="showGiveAndGet = true"
                    />
                </td>
                <td class="centered">
                    <b>50 €</b>
                </td>
                <td class="centered">
                    10 mal
                </td>
            </tr>
        </table>
        <p>Die Häufigkeit gibt an, wie oft du dir die Prämie sichern kannst.</p>
        <p v-if="isAuthenticated">
            Wenn du einen Meilenstein erreicht hast, kontaktiere uns hier und sende uns die notwendigen Informationen.
        </p>
        <LazyDialogAnbieterProfil
            v-if="showAnbiterProfil"
            @close="showAnbiterProfil = false"
        />
        <LazyDialogProfilSocial
            v-if="showProfilSocial"
            @close="showProfilSocial = false"
        />
        <LazyDialogKategorieSeite
            v-if="showKategorieSeite"
            @close="showKategorieSeite = false"
        />
        <LazyDialogAnbieterBewertung
            v-if="showAnbieterBewertung"
            @close="showAnbieterBewertung = false"
        />
        <LazyDialogGiveAndGet
            v-if="showGiveAndGet"
            @close="showGiveAndGet = false"
        />
        <div
            v-if="isAuthenticated"
            class="container form-wrapper"
        >
            <h2
                id="contact"
                class="mt-2"
            >
                Kontaktiere uns, um deine Prämie zu erhalten!
            </h2>
            <FormHubspotContact />
        </div>
        <RegisterCtaBox v-else />
        <p class="mt-1">
            <i>Bedingungen: Das Guthaben gilt für Klicks von Interessenten oder eine Listung auf {{ $myT('mainAppDomain') }} und kann nicht in bar ausgezahlt werden. Aktion bis auf Widerruf gültig.</i>
        </p>
    </section>
</template>

<script setup>
definePageMeta({
    layout: 'standard',
    auth: false
})
const { isAuthenticated } = useUser()
const showAnbiterProfil = ref(false)
const showProfilSocial = ref(false)
const showKategorieSeite = ref(false)
const showAnbieterBewertung = ref(false)
const showGiveAndGet = ref(false)
</script>

<style scoped>
.top-content {
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: var(--spacing);
    flex-direction: column;
}
.top-content img { height: 8rem; }
table {
    box-shadow: var(--small-shadow);
    background-color: var(--white);
    border-radius: var(--border-radius);
    margin-left: auto;
    margin-right: auto;
}
th {
    font-weight: bold;
}
.info-cell {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    justify-content: space-between;
}
.form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form-wrapper h2 {
    text-align: center;
}
@media (min-width: 768px) {
    .top-content {
        flex-direction: row;
        justify-content: space-between;
        column-gap: var(--spacing);
    }
}
</style>
