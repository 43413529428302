<template>
    <section>
        <NavigationGoBack />
        <h1 class="mt-1">
            Statistiken
        </h1>
        <StatisticsDashboardSummary />
        <h2 class="mt-1">
            Finanzielles
        </h2>
        <GetStartedWithSubscription v-if="!business?.stripeSubscriptionId" />
        <div
            v-else-if="loading"
            aria-busy="true"
        >
            Lade...
        </div>
        <div v-else>
            <h3 v-if="!balanceError">
                <ins>Dein Guthaben: {{ formatCentsPrice(-customer.balance) }} €</ins>
            </h3>
            <p v-if="couponsSummary">
                <b>Angewandte Gutscheine:</b> {{ couponsSummary.names }} für insgesamt {{ formatCentsPrice(couponsSummary.amount) }} €
            </p>
            <div v-if="invoice">
                <ul>
                    <li>Rechnungsperiode: von {{ convertTimestampToDate(invoice.period_start) }} bis {{ convertTimestampToDate(invoice.period_end) }}</li>
                    <li>Nächste Rechnung am: {{ convertTimestampToDate(invoice.period_end) }}</li>
                    <li>Rechnungssumme: {{ formatCentsPrice(invoice.subtotal) }} Netto €</li>
                    <li>Zu zahlennder Betrag (abzgl. Guthaben und Rabatten): {{ formatCentsPrice(invoice.amount_due) }} Brutto €</li>
                </ul>
                <b>Positionen</b>
                <p
                    v-for="line in invoice.lines.data"
                    :key="line.id"
                >
                    {{ line.description }}: {{ formatCentsPrice(line.amount) }} €
                </p>
            </div>
            <small>Zahlen bis inklusive {{ getYesterdaysDate() }}</small>
            <InputMonthlyBudget
                v-if="business.cpc"
                class="mt-1"
            />
        </div>
        <a
            v-if="data?.stripeCustomerId"
            class="block mt-1"
            href="https://billing.stripe.com/p/login/4gw000fuC3RL8HCeUU"
            target="_blank"
        >
            Zahlungsmittel ändern oder Rechnungshistorie: Zum Abrechnungsportal
        </a>
        <h2 class="mt-2">
            So erhältst du mehr Klicks
        </h2>
        <div class="suggestions">
            <BoxForSlot>
                <template #content>
                    <p><b>Optimiere dein Anbieterprofil</b></p>
                    <p>
                        Ein vollständiges Profil wird besser gefunden: Achte insbesondere
                        auf genügend Bilder, ausreichend lange Texte und Vollständigkeit deines Profils.
                    </p>
                    <NuxtLink
                        role="button"
                        class="outline small"
                        :to="localePath('dashboard-business-edit')"
                    >
                        Anbieterprofil bearbeiten
                    </NuxtLink>
                </template>
            </BoxForSlot>
            <BoxForSlot>
                <template #content>
                    <p><b>Rankingfaktoren</b></p>
                    <p>
                        Das Ranking ist abhängig von Qualitätsfaktoren wie Bewertungen.
                        Stelle sicher, dass du viele positive Bewertungen erhältst.
                    </p>
                    <InputShareButton
                        button-text="Profil teilen"
                        :url="businessPublicLink"
                        :title="`${business.name} auf ${$myT('mainAppDomain')}`"
                    />
                </template>
            </BoxForSlot>
            <BoxForSlot>
                <template #content>
                    <p><b>Partner Success Team</b></p>
                    <p>
                        Wir helfen dir gerne persönlich.
                    </p>
                    <NuxtLink :to="localePath('free-consulting')">
                        Kontakt
                    </NuxtLink>
                </template>
            </BoxForSlot>
        </div>
        <h2 class="mt-2">
            Definitionen
        </h2>
        <NoteBox>
            <template #content>
                <p class="note">
                    <b>Einblendungen:</b> Deine angelegten Services generierten Einblendungen auf unserer Plattform.
                </p>
                <p class="note">
                    <b>Interessenten-Klicks:</b> Ein Nutzer hat Interesse an deinem Angebot und hat auf deine Webseite geklickt.
                </p>
            </template>
            <template #action>
                <img
                    class="statistics-image"
                    src="assets/images/dashboard/statistiken.png"
                    alt="Statistiken"
                >
            </template>
        </NoteBox>
    </section>
</template>

<script setup>
const localePath = useLocalePath()
const { business, businessPublicLink } = storeToRefs(useBusinessStore())
const { data } = useAuthState()
const statisticsStore = useStatisticsStore()
const { invoice, customer, balanceError, subscription } = storeToRefs(statisticsStore)

const loading = ref(true)
onMounted(async () => {
    if (data.value.stripeCustomerId) {
        if (!customer.value) {
            await statisticsStore.fetchCustomer(data.value.stripeCustomerId)
        }
        if (!invoice.value) {
            await statisticsStore.fetchInvoice(data.value.stripeCustomerId)
        }
    }
    if (!subscription.value && business.value.stripeSubscriptionId) {
        await statisticsStore.fetchSubscription(business.value.stripeSubscriptionId)
    }
    loading.value = false
})

const couponsSummary = computed(() => {
    if (subscription.value?.discounts?.length === 0) {
        return null
    }
    return {
        amount: subscription.value?.discounts?.reduce((total, discount) => total + discount.coupon.amount_off, 0) || 0,
        names: subscription.value?.discounts?.map(discount => discount.coupon.name).join(', ') || ''
    }
})
</script>

<style scoped>
.suggestions {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing);
}
.statistics-image {
    width: 10rem;
    display: block;
    margin: auto;
}
p.note {
    margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
    .statistics-image {
        width: 7rem;
    }
}
@media (min-width: 1024px) {
    .suggestions {
        flex-direction: row;
        column-gap: var(--spacing);
    }
}
</style>
