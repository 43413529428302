export function useBusinessContactInfo (contactInfo) {
    const linksEqual = computed(() => {
        if (!contactInfo.websiteLink && !contactInfo.customerServiceLink) {
            return false
        }
        return contactInfo.websiteLink === contactInfo.customerServiceLink
    })

    const validLinks = computed(() => {
        const websiteLink = contactInfo.websiteLink
        const customerServiceLink = contactInfo.customerServiceLink
        const validWebsiteLink = !websiteLink || websiteLink.startsWith('https')
        const validCustomerServiceLink = !customerServiceLink || customerServiceLink.startsWith('https')
        return validWebsiteLink && validCustomerServiceLink
    })

    const linksDifferentAndValid = computed(() => {
        return !linksEqual.value && validLinks.value
    })

    return { linksEqual, validLinks, linksDifferentAndValid }
}
