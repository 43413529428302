<template>
    <section>
        <div class="container mt-1">
            <StaticContentLoader name="kooperationen" />
        </div>
        <div class="partner-section">
            <NavigationPartnerLogos class="container" />
        </div>
        <div class="container mt-2">
            <h2>Entdecke in der Präsentation Integrationsmöglichkeiten:</h2>
            <iframe
                src="https://26998599.fs1.hubspotusercontent-eu1.net/hubfs/26998599/B2B%20Unterlagen/Locaverse%20for%20Business%20-%20Feed-Partner.pdf#toolbar=0"
                class="pdf-iframe"
            />
            <NuxtLink
                class="block-link mt-1"
                role="button"
                :to="localePath('free-consulting')"
            >
                Kontaktiere uns!
            </NuxtLink>
        </div>
    </section>
</template>

<script setup>
definePageMeta({
    auth: false,
    layout: 'standard'
})
const localePath = useLocalePath()
</script>

<style scoped>
.pdf-iframe {
    width: 100%;
    height: 65vh;
}
.partner-section {
    background-color: var(--white);
    padding-top: calc(2 * var(--spacing));
    padding-bottom: calc(2 * var(--spacing));
}
</style>
