<template>
    <NoteBox>
        <template #content>
            Deine Empfehlung war ein voller Erfolg - herzlichen Dank dafür!
            Du kannst noch <b>{{ availableClaims }}</b> mal dein Guthaben einlösen!
        </template>
        <template #action>
            <button :aria-busy="loading" @click="claimCredit">
                Jetzt einlösen
            </button>
        </template>
    </NoteBox>
</template>

<script setup>
const availableClaims = defineModel({ type: Number })

const { $backendApi } = useNuxtApp()
const toast = useToast()
const loading = ref(false)
async function claimCredit () {
    loading.value = true
    try {
        await $backendApi('/users/claim-referral-credit', { method: 'PUT' })
        toast.success('Erfolgreich eingelöst!')
        availableClaims.value--
    } catch (error) {
        handleBackendError({ error, toast })
    }
    loading.value = false
}
</script>

<style scoped>

</style>
