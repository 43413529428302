<template>
    <section>
        <NavigationGoBack />
        <h1 class="mt-1">
            Deine Kontaktdaten
        </h1>
        <p>Damit wir dir schnell und effektiv helfen können, freuen wir uns auf einen Kontakt mit dir!</p>
        <i>Hinweis: diese Informationen werden <b>nicht</b> auf {{ $myT('mainAppDomain') }} veröffentlicht.</i>
        <form
            class="mt-1"
            method="post"
            @submit.prevent="saveInfo"
        >
            <div class="grid">
                <label for="first_name">
                    Vorname der Ansprechperson *
                    <input
                        id="first_name"
                        v-model="data.firstName"
                        type="text"
                        name="first_name"
                        placeholder="z.B. Rainer"
                        maxlength="100"
                        :aria-invalid="String(!data.firstName)"
                        required
                    >
                </label>
                <label for="last_name">
                    Nachname der Ansprechperson *
                    <input
                        id="last_name"
                        v-model="data.lastName"
                        type="text"
                        name="last_name"
                        placeholder="z.B. Bosch"
                        maxlength="100"
                        :aria-invalid="String(!data.lastName)"
                        required
                    >
                </label>
            </div>
            <div class="grid">
                <label for="contactPhone">
                    Telefonnummer der Ansprechperson *
                    <input
                        id="contactPhone"
                        v-model="data.contactPhone"
                        type="tel"
                        name="contactPhone"
                        placeholder="z.B. +43 000 00 00 00"
                        maxlength="40"
                        minlength="6"
                        :aria-invalid="String(!data.contactPhone)"
                        required
                    >
                </label>
                <label for="contactEmail">
                    E-Mail der Ansprechperson *
                    <input
                        id="contactEmail"
                        v-model="data.contactEmail"
                        type="email"
                        name="contactEmail"
                        placeholder="z.B. example@email.com"
                        maxlength="255"
                        :aria-invalid="String(!data.contactEmail)"
                        required
                    >
                </label>
            </div>
            <label for="firm_address">
                Offizielle Firmenadresse *
                <input
                    id="firm_address"
                    v-model="data.address"
                    type="text"
                    name="firm_address"
                    placeholder="Adresse eingeben"
                    maxlength="255"
                    required
                    :aria-invalid="String(!data.address)"
                >
            </label>
            <InputCountrySelect
                :country="data.country"
                @new-country="(country) => data.country = country"
            />
            <p>* Pflichtfelder</p>
            <button
                :aria-busy="data.submitting"
                type="submit"
            >
                Speichern
            </button>
        </form>
    </section>
</template>

<script setup>
const userInfo = useAuthState().data.value

const data = reactive({
    firstName: '',
    lastName: '',
    contactPhone: '',
    contactEmail: '',
    country: 'Austria',
    address: '',
    submitting: false
})

onMounted(() => {
    data.firstName = userInfo.firstName
    data.lastName = userInfo.lastName
    data.contactPhone = userInfo.contactPhone
    data.contactEmail = userInfo.contactEmail
    data.address = userInfo.address
    if (userInfo.country) {
        data.country = userInfo.country
    }
})

const { $backendApi } = useNuxtApp()
const { getSession } = useAuth()
const toast = useToast()
const router = useRouter()
async function saveInfo () {
    data.submitting = true
    try {
        await $backendApi('/users/contact-info', {
            method: 'PUT',
            body: {
                firstName: data.firstName,
                lastName: data.lastName,
                contactPhone: data.contactPhone,
                contactEmail: data.contactEmail,
                address: data.address,
                country: data.country
            }
        })
        await getSession()
        toast.success('Deine Kontaktdaten wurden aktualisiert')
        router.go(-1)
    } catch (error) {
        if (error.response._data.msg) {
            toast.error(error.response._data.msg)
        } else {
            error.response._data.errors.forEach((error) => {
                toast.error(error)
            })
        }
    }
    data.submitting = false
}
</script>
