<template>
    <section>
        <NavigationGoBack text="Zurück zum Dashboard" />
        <div class="flex-row mt-1">
            <h1>Anbieterprofil bearbeiten</h1>
            <BusinessPublicLink />
        </div>
        <BoxForSlot
            class="mt-1"
            :fit-content="true"
        >
            <template #content>
                <div class="flex-row">
                    <ScoreCircleIndicator
                        data-tooltip="Profilstärke"
                        :rating="business.profileScore"
                        :height="5"
                    />
                    <div>
                        <p>
                            Deine aktuelle Profilstärke für das Anbieterprofil
                            <br>
                            <span v-if="business.profileScore < 100">Füge mehr Informationen hinzu, um diesen Wert zu verbessern!</span>
                            <span v-else>Du hast ein perfektes Profil erstellt!</span>
                        </p>
                        <div v-if="business.profileScore < 100">
                            <p class="mb-0">
                                Tipps für das Anbieterprofil:
                            </p>
                            <ul>
                                <li>Achte auf eine umfangreiche Beschreibung (idealerweise 1000 Zeichen)</li>
                                <li>Gib einen Link zu deiner Webseite an</li>
                                <li>Lade ein Logo hoch</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </template>
        </BoxForSlot>
        <p class="mt-1">
            Das ist eine Vorschau deines Anbieterprofils auf {{ $myT('mainAppDomain') }}
        </p>
        <BusinessEditForm class="mt-2" />
    </section>
</template>

<script setup>
const { business } = storeToRefs(useBusinessStore())
useHead({
    title: 'Anbieterprofil bearbeiten'
})
</script>

<style scoped>
.flex-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: var(--spacing);
}
h1 { margin-bottom: 0; }
</style>
