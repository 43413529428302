<template>
    <div class="sustainability-hint">
        <p>
            Hier kannst du beschreiben, was du für die Nachhaltigkeit tust. Kunden legen Wert
            auf Unternehmen, die nachhaltig agieren. Wenn du diese Sektion ausfüllst, wird
            deinem Profil auch der Nachhaltigkeitsbadge
            <img class="sustainability-icon-small" src="/assets/icons/sustainability.svg" alt="Nachhaltigkeitsbadge">
            hinzugefügt (sowohl auf der Service-Page, als auch auf der Service-Card in den
            Suchergebnissen), sodass deine Bemühungen nicht unbemerkt bleiben.
        </p>
        <strong>Ideen für dich:</strong>
        <ul>
            <li>Du verwendest umweltfreundliches Verbrauchsmaterial</li>
            <li>
                Du kompensierst deine CO<sub>2</sub> Emissionen
            </li>
            <li>
                Du achtest auf die Optimierung deiner Anfahrtswege und die Wahl
                umweltfreundlicher Transportmittel
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'SustainabilityHint'
}
</script>

<style scoped>
.sustainability-icon-small { width: 1.5rem; height: 1.5rem; }
.sustainability-hint {
    border: 1px solid var(--primary);
    border-radius: var(--border-radius);
    padding: var(--spacing);
    background-color: var(--primary-100);
}
</style>
