<template>
    <form @submit.prevent="next">
        <h2>
            Nachhaltigkeit
            <img class="sustainability-icon" src="/assets/icons/sustainability.svg" alt="Nachhaltigkeitsbadge">
            <small>(optional)</small>
        </h2>
        <BusinessSustainabilityHint class="mt-1" />
        <TiptapEditor
            id="description"
            v-model="sustainabilityDescription.text"
            class="mt-1"
        />
        <button class="mt-1">
            Weiter
        </button>
    </form>
</template>

<script setup>
const emit = defineEmits(['next'])

const { $clientLocalStorage } = useNuxtApp()
const savedState = $clientLocalStorage.getItem({ item: 'newBusinessProcess', parseJson: true }) ?? {}

const sustainabilityDescription = ref(savedState?.sustainabilityDescription || { text: '' })

function next () {
    $clientLocalStorage.setItem({
        item: 'newBusinessProcess',
        value: JSON.stringify({ ...savedState, sustainabilityDescription: sustainabilityDescription.value })
    })
    emit('next')
}
</script>

<style scoped>
h2 { margin-bottom: 0; display: flex; align-items: center; column-gap: 0.5rem; }
</style>
