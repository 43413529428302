<template>
    <Teleport to="body">
        <dialog open>
            <article>
                <CloseIcon @close="emit('cancel')" />
                <div class="title">
                    <slot name="title" />
                </div>
                <slot name="content" />
                <div class="buttons-container mt-1">
                    <button class="outline" @click="emit('cancel')">
                        Abbrechen
                    </button>
                    <button :aria-busy="props.loading" @click="emit('confirm')">
                        Bestätigen
                    </button>
                </div>
            </article>
        </dialog>
    </Teleport>
</template>

<script setup>
const props = defineProps({
    loading: { type: Boolean, required: false, default: false }
})
const emit = defineEmits(['cancel', 'confirm'])
</script>

<style scoped>
.buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: var(--spacing);
    width: calc(100% - var(--spacing));
}
</style>
