<template>
    <div class="category-location-container mt-2">
        <div v-if="disable" class="blocking-overlay">
            <img src="assets/icons/lock-solid.svg" alt="Deaktiviert" title="Deaktiviert">
        </div>
        <div class="card" @click="emit('show-locations-dialog')">
            <div class="card-header">
                <span>Orte ändern ({{ totalLocations }})</span>
                <ActionIcon
                    icon="pen-solid"
                    :position-absolute="false"
                    class="yellow-border"
                    @click="emit('show-locations-dialog')"
                />
            </div>
            <img :class="{ 'not-selected-overlay': totalLocations === 0 }" src="assets/images/free-listing/service-area-polygon.jpg" alt="Servicegebiet">
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['show-locations-dialog'])
const props = defineProps({
    locations: { type: Array, required: true },
    disable: { type: Boolean, default: false }
})
const totalLocations = computed(() => props.locations?.length || 0)
</script>

<style scoped>
.card {
    max-width: 20rem;
    background-color: var(--white);
    border-radius: var(--border-radius);
    padding: calc(var(--spacing) * 1);
    box-shadow: var(--small-shadow);
    cursor: pointer;
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    transition: .3s ease-in-out;
}
.card-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(var(--spacing) * 2);
}
.card > img {
    width: 100%;
}
.not-selected-overlay {
    filter: grayscale(100%);
}
.category-location-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: calc(var(--spacing) * 3);
    width: fit-content;
}
.blocking-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.4);;
    border-radius: var(--border-radius);
    z-index: 2;
}
.blocking-overlay img { width: 3rem; }
@media (min-width: 576px) {
    .card-header {
        margin-bottom: var(--spacing);
    }
}
@media (min-width: 768px) {
    .category-location-container {
        flex-direction: row;
    }
}
</style>
