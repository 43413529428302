import { default as become_45partnerV6qzrTejYWMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/become-partner.vue?macro=true";
import { default as boomende_45online_45on_45demand_45heimdienstleistungenyL6FEXTWnUMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/boomende-online-on-demand-heimdienstleistungen.vue?macro=true";
import { default as cooperationshrz260NrrMMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/cooperations.vue?macro=true";
import { default as business_45faqF5s1uGLAhWMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/customer-support/business-faq.vue?macro=true";
import { default as activate_45profileY5QgHNRD9UMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/activate-profile.vue?macro=true";
import { default as couponsfn8RYYn4zBMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/business/coupons.vue?macro=true";
import { default as edit1QI8hWicejMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/business/edit.vue?macro=true";
import { default as newaGMSiJq5RIMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/business/new.vue?macro=true";
import { default as inbox9tQlHCB0d9Meta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/inbox.vue?macro=true";
import { default as indexaXxdnuyFvKMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/index.vue?macro=true";
import { default as onboardingEnmtBRJyCDMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/onboarding.vue?macro=true";
import { default as _91id_9374liL4IoCBMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/services/edit/[id].vue?macro=true";
import { default as indexgL3H2RK9BDMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/services/index.vue?macro=true";
import { default as newNVA4cBwrkoMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/services/new.vue?macro=true";
import { default as change_45passwordDsPFsxbyhoMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/settings/change-password.vue?macro=true";
import { default as contact_45infoqJab36VafOMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/settings/contact-info.vue?macro=true";
import { default as indexUS9wJHjqK9Meta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/settings/index.vue?macro=true";
import { default as optin_45settingseUQmzUBGwbMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/settings/optin-settings.vue?macro=true";
import { default as statisticsGUD3NutgkdMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/statistics.vue?macro=true";
import { default as stripe_45postcheckoutj1fRH5Hq5yMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/stripe-postcheckout.vue?macro=true";
import { default as free_45consultingvaBtg6suDcMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/free-consulting.vue?macro=true";
import { default as free_45listingHo4Mwu0CczMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/free-listing.vue?macro=true";
import { default as indexlybgS29giaMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/index.vue?macro=true";
import { default as _91stripePricingTableId_93cVBEyG1a0DMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/individual/[stripePricingTableId].vue?macro=true";
import { default as loginAavoGtTFjkMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/login.vue?macro=true";
import { default as callbackUlrQBgeRS2Meta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/oauth/callback.vue?macro=true";
import { default as errorOZHjamzhE7Meta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/oauth/error.vue?macro=true";
import { default as pricesUXcBpzwqo6Meta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/prices.vue?macro=true";
import { default as privatanbieterbp4SMw7h5IMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/privatanbieter.vue?macro=true";
import { default as indexWyTvq9BdVLMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/products/index.vue?macro=true";
import { default as _91stripePricingTableId_93w3uT1LeGhKMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/products/individual/[stripePricingTableId].vue?macro=true";
import { default as _91emailConfirmToken_93d3zvc4smI8Meta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/register/[emailConfirmToken].vue?macro=true";
import { default as indexiUB1qcc0YzMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/register/index.vue?macro=true";
import { default as _91passwordResetToken_93V9zbsl7QV7Meta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/reset-password/[passwordResetToken].vue?macro=true";
import { default as indexMaAiq9xAMyMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/reset-password/index.vue?macro=true";
import { default as restaurants_45mit_45lieferservicep330xy6kz2Meta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/restaurants-mit-lieferservice.vue?macro=true";
import { default as rewardsK81DgxCOcRMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/rewards.vue?macro=true";
import { default as startCj7zHoo3JTMeta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/start.vue?macro=true";
import { default as component_45stubsEKtCGs0j4Meta } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubsEKtCGs0j4 } from "/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "become-partner___de",
    path: "/anbieter-werden",
    meta: become_45partnerV6qzrTejYWMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/become-partner.vue").then(m => m.default || m)
  },
  {
    name: "boomende-online-on-demand-heimdienstleistungen___de",
    path: "/boomende-online-on-demand-heimdienstleistungen",
    meta: boomende_45online_45on_45demand_45heimdienstleistungenyL6FEXTWnUMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/boomende-online-on-demand-heimdienstleistungen.vue").then(m => m.default || m)
  },
  {
    name: "cooperations___de",
    path: "/kooperationen",
    meta: cooperationshrz260NrrMMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/cooperations.vue").then(m => m.default || m)
  },
  {
    name: "customer-support-business-faq___de",
    path: "/kundensupport/business-faq",
    meta: business_45faqF5s1uGLAhWMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/customer-support/business-faq.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-activate-profile___de",
    path: "/dashboard/profil-aktivieren",
    meta: activate_45profileY5QgHNRD9UMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/activate-profile.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-business-coupons___de",
    path: "/dashboard/business/angebote-verwalten",
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/business/coupons.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-business-edit___de",
    path: "/dashboard/business/bearbeiten",
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/business/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-business-new___de",
    path: "/dashboard/business/neu",
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/business/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-inbox___de",
    path: "/dashboard/kontaktanfragen",
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/inbox.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___de",
    path: "/dashboard",
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-onboarding___de",
    path: "/dashboard/onboarding",
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-services-edit-id___de",
    path: "/dashboard/services/bearbeiten/:id()",
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/services/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-services___de",
    path: "/dashboard/services",
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/services/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-services-new___de",
    path: "/dashboard/services/neu",
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/services/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-change-password___de",
    path: "/dashboard/einstellungen/passwort-aendern",
    meta: change_45passwordDsPFsxbyhoMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/settings/change-password.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-contact-info___de",
    path: "/dashboard/einstellungen/kontaktinformation",
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/settings/contact-info.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings___de",
    path: "/dashboard/einstellungen",
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-optin-settings___de",
    path: "/dashboard/einstellungen/optin",
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/settings/optin-settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-statistics___de",
    path: "/dashboard/statistiken",
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/statistics.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-stripe-postcheckout___de",
    path: "/dashboard/stripe-postcheckout",
    meta: stripe_45postcheckoutj1fRH5Hq5yMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/dashboard/stripe-postcheckout.vue").then(m => m.default || m)
  },
  {
    name: "free-consulting___de",
    path: "/austausch",
    meta: free_45consultingvaBtg6suDcMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/free-consulting.vue").then(m => m.default || m)
  },
  {
    name: "free-listing___de",
    path: "/kostenfrei-listen",
    meta: free_45listingHo4Mwu0CczMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/free-listing.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    meta: indexlybgS29giaMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "individual-stripePricingTableId___de",
    path: "/individual/:stripePricingTableId()",
    meta: _91stripePricingTableId_93cVBEyG1a0DMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/individual/[stripePricingTableId].vue").then(m => m.default || m)
  },
  {
    name: "login___de",
    path: "/login",
    meta: loginAavoGtTFjkMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback___de",
    path: "/oauth/callback",
    meta: callbackUlrQBgeRS2Meta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "oauth-error___de",
    path: "/oauth/error",
    meta: errorOZHjamzhE7Meta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/oauth/error.vue").then(m => m.default || m)
  },
  {
    name: "prices___de",
    path: "/preise",
    meta: pricesUXcBpzwqo6Meta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/prices.vue").then(m => m.default || m)
  },
  {
    name: "privatanbieter___de",
    path: "/private-anbieter",
    meta: privatanbieterbp4SMw7h5IMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/privatanbieter.vue").then(m => m.default || m)
  },
  {
    name: "products___de",
    path: "/products",
    meta: indexWyTvq9BdVLMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "products-individual-stripePricingTableId___de",
    path: "/products/individual/:stripePricingTableId()",
    meta: _91stripePricingTableId_93w3uT1LeGhKMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/products/individual/[stripePricingTableId].vue").then(m => m.default || m)
  },
  {
    name: "register-emailConfirmToken___de",
    path: "/registrieren/:emailConfirmToken()",
    meta: _91emailConfirmToken_93d3zvc4smI8Meta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/register/[emailConfirmToken].vue").then(m => m.default || m)
  },
  {
    name: "register___de",
    path: "/registrieren",
    meta: indexiUB1qcc0YzMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-passwordResetToken___de",
    path: "/passwort-zuruecksetzen/:passwordResetToken()",
    meta: _91passwordResetToken_93V9zbsl7QV7Meta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/reset-password/[passwordResetToken].vue").then(m => m.default || m)
  },
  {
    name: "reset-password___de",
    path: "/passwort-zuruecksetzen",
    meta: indexMaAiq9xAMyMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "restaurants-mit-lieferservice___de",
    path: "/restaurants-mit-lieferservice",
    meta: restaurants_45mit_45lieferservicep330xy6kz2Meta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/restaurants-mit-lieferservice.vue").then(m => m.default || m)
  },
  {
    name: "rewards___de",
    path: "/praemienprogramm",
    meta: rewardsK81DgxCOcRMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/rewards.vue").then(m => m.default || m)
  },
  {
    name: "start___de",
    path: "/start",
    meta: startCj7zHoo3JTMeta || {},
    component: () => import("/home/runner/work/b2b-frontend-nuxt-3/b2b-frontend-nuxt-3/pages/start.vue").then(m => m.default || m)
  },
  {
    name: component_45stubsEKtCGs0j4Meta?.name,
    path: "/robots.txt",
    component: component_45stubsEKtCGs0j4
  },
  {
    name: component_45stubsEKtCGs0j4Meta?.name,
    path: "/kostenfreie-erstberatung",
    component: component_45stubsEKtCGs0j4
  },
  {
    name: component_45stubsEKtCGs0j4Meta?.name,
    path: "/gratis-beratung",
    component: component_45stubsEKtCGs0j4
  },
  {
    name: component_45stubsEKtCGs0j4Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubsEKtCGs0j4
  },
  {
    name: component_45stubsEKtCGs0j4Meta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubsEKtCGs0j4
  },
  {
    name: component_45stubsEKtCGs0j4Meta?.name,
    path: "/de-sitemap.xml",
    component: component_45stubsEKtCGs0j4
  }
]