<template>
    <section>
        <NavigationGoBack text="Zurück zum Onboarding" :link="localePath('dashboard-onboarding')" />
        <h1 class="mt-1">
            Erstelle dein Anbieterprofil
        </h1>
        <i>Hinweis: Diese Anbieter-Daten sind auf deiner <b>öffentlichen Anbieter-Seite</b> für alle Kunden zu sehen.</i>
        <client-only>
            <ProgressBar class="mt-1" :max="LAST_STEP" :current="step" @prev="changeStepBy(-1)" />
            <BusinessNewNameDescriptionStep v-if="step === 1" class="mt-1" @next="changeStepBy(1)" />
            <LazyBusinessNewAddressStep v-else-if="step === 2" class="mt-1" @next="changeStepBy(1)" />
            <LazyBusinessNewContactInfoStep v-else-if="step === 3" class="mt-1" @next="changeStepBy(1)" />
            <LazyBusinessNewSocialMediaStep v-else-if="step === 4" class="mt-1" @next="changeStepBy(1)" />
            <LazyBusinessNewSustainabilityStep v-else-if="step === 5" class="mt-1" @next="changeStepBy(1)" />
            <LazyBusinessNewLogoStep v-else-if="step === 6" class="mt-1" @next="handleFinalStep" />
            <div v-else-if="step === LAST_STEP">
                <img class="super-image" src="/assets/images/business/profil-fertig.png" alt="Super, gut gemacht!" title="Super, gut gemacht!">
                <h2 class="mt-1">
                    Dein Anbieterprofil ist fertig!
                </h2>
                <NuxtLink class="block" role="button" :to="localePath('dashboard-onboarding')">
                    Weiter
                </NuxtLink>
            </div>
        </client-only>
    </section>
</template>

<script setup>
const step = ref(1)
const LAST_STEP = 7

const toast = useToast()
const businessStore = useBusinessStore()
const localePath = useLocalePath()

const { $clientLocalStorage } = useNuxtApp()

onMounted(() => {
    if (businessStore.business) {
        toast.info('Du hast bereits ein Anbieterprofil angelegt.', { timeout: 5000 })
        return navigateTo(localePath('dashboard'))
    }
    const savedState = $clientLocalStorage.getItem({ item: 'newBusinessProcess', parseJson: true })
    step.value = savedState?.step || 1
    if (step.value > 1) {
        toast.info('Mache wieder dort weiter, wo du aufgehört hast.')
    }
    if (step.value === LAST_STEP) {
        $clientLocalStorage.removeItem({ item: 'newBusinessProcess' })
    }
})

function changeStepBy (delta) {
    step.value += delta
    const savedState = $clientLocalStorage.getItem({ item: 'newBusinessProcess', parseJson: true })
    savedState.step = step.value
    $clientLocalStorage.setItem({ item: 'newBusinessProcess', value: savedState })
}

function handleFinalStep () {
    step.value++
    $clientLocalStorage.removeItem({ item: 'newBusinessProcess' })
}

useHead({
    titleTemplate: 'Neues Anbieterprofil erstellen'
})
</script>

<style scoped>
section { padding-bottom: 2rem; }
.super-image {
    width: 15rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
</style>
