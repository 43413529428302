<template>
    <img src="/assets/icons/info-primary-solid.svg" alt="Info Icon" @click="emit('click')">
</template>

<script setup>
const emit = defineEmits(['click'])
</script>

<style scoped>
img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    -webkit-transition: .2s ease-in-out;  /* Chrome, Safari 3.1+ */
    -moz-transition: .2s ease-in-out;  /* Firefox 3.5+ */
    -ms-transition: .2s ease-in-out;  /* IE 9 */
    -o-transition: .2s ease-in-out;  /* Opera 10.50-12.00 */
    transition: .2s ease-in-out;  /* Firefox 16+, IE 10+, Opera 12.10+ */
}
img:hover {
    -webkit-transform: scale(1.04);  /* Chrome, Safari 3.1+ */
    -moz-transform: scale(1.04);  /* Firefox 3.5+ */
    -ms-transform: scale(1.04);  /* IE 9 */
    -o-transform: scale(1.04);  /* Opera 10.50-12.00 */
    transform: scale(1.1);  /* Firefox 16+, IE 10+, Opera 12.10+ */
}
</style>
