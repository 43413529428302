<template>
    <Teleport to="body">
        <dialog open>
            <article>
                <CloseIcon @close="emit('close')" />
                <h3>Ergänze hier Links zu deinen Social Media Kanälen</h3>
                <FormBusinessSocialMediaLinks
                    v-model="newSocialMedia"
                    :loading="loading"
                    @submit="confirm"
                />
            </article>
        </dialog>
    </Teleport>
</template>

<script setup>
const emit = defineEmits(['close'])

const businessStore = useBusinessStore()
const socialMedia = computed(() => businessStore.getSocialMediaLinks())

const loading = ref(false)
const newSocialMedia = ref({
    facebookLink: { link: socialMedia.value.facebook, invalid: '' },
    linkedinLink: { link: socialMedia.value.linkedin, invalid: '' },
    instagramUsername: socialMedia.value.instagram ? socialMedia.value.instagram.replace('https://www.instagram.com/', '') : '',
    youtubeLink: { link: socialMedia.value.youtube, invalid: '' },
    tiktokLink: { link: socialMedia.value.tiktok, invalid: '' }
})

const toast = useToast()
async function confirm () {
    // if no changes were made, do not call backend
    if (JSON.stringify(newSocialMedia.value) === JSON.stringify(socialMedia.value)) {
        return emit('close')
    }
    loading.value = true
    try {
        const linksRequestPayload = {
            facebookLink: newSocialMedia.value.facebookLink.link || null,
            instagramUsername: newSocialMedia.value.instagramUsername || null,
            tiktokLink: newSocialMedia.value.tiktokLink.link || null,
            youtubeLink: newSocialMedia.value.youtubeLink.link || null,
            linkedinLink: newSocialMedia.value.linkedinLink.link || null
        }
        await businessStore.updateSocialMedia({
            socialMedia: linksRequestPayload
        })
        toast.success('Gespeichert.')
        emit('close')
    } catch (error) {
        handleBackendError({ error, toast })
    }
    loading.value = false
}
</script>

<style scoped>
h3 {
    margin: 0 2rem 1rem 0;
}
</style>
