<template>
    <div>
        <h2>Produkte und Leistungen</h2>
        <p :aria-busy="pending" v-if="pending">
            Lade Preise...
        </p>
        <p v-if="error">
            Preise konnten nicht geladen werden.
        </p>
        <table v-else role="grid">
            <thead>
                <tr>
                    <th><strong>Produktbezeichnung</strong></th>
                    <th class="short-column">
                        <strong>Preis in €</strong>
                    </th>
                    <th class="action-column">
                        <strong>Aktion</strong>
                    </th>
                </tr>
            </thead>
            <tbody>
                <ServiceProductRow
                    v-for="product in products"
                    :key="product.id"
                    :service-id="serviceId"
                    :product="product"
                    @new-edit-value="(v) => (edit = v)"
                    @delete-product="removeProductById"
                    @delete-new-product="removeNewProduct"
                />
            </tbody>
        </table>
        <button v-if="!edit" @click="addProduct">
            Produkt hinzufügen
        </button>
    </div>
</template>

<script setup>
const { serviceId } = defineProps({
    serviceId: { type: Number, required: true }
})

const edit = ref(false)

const { data, pending, error } = await useIFetch(`/mobile-services/${serviceId}/products`)
const products = ref(data.value?.products || [])

function addProduct () {
    products.value.push({ edit: true })
    edit.value = true
}
function removeProductById (id) {
    products.value = products.value.filter(product => product.id !== id)
}
function removeNewProduct () {
    products.value = products.value.filter(product => product.id)
    edit.value = false
}
</script>

<style scoped>
th { padding: 0.3rem; }
.short-column { width: 7rem; }
.action-column { width: 9rem; }
button { width: fit-content; }
</style>
