<template>
    <form @submit.prevent="submit">
        <label for="question">Frage</label>
        <input
            v-model="question"
            name="question"
            maxlength="255"
            type="text"
            placeholder="Gib hier die Frage ein"
        >
        <label for="answer">Antwort</label>
        <TiptapEditor
            id="answer"
            v-model="answer"
            :headings-level-start="4"
            class="mt-1"
        />
        <div class="form-buttons mt-1">
            <button class="outline" type="button" @click="emit('close')">
                Abbrechen
            </button>
            <button :disabled="disableSubmit" :aria-busy="submitting" type="submit">
                Speichern
            </button>
        </div>
    </form>
</template>

<script setup>
const { faq, scope } = defineProps({
    faq: { type: Object, required: false, default: () => {} },
    scope: { type: String, required: false, default: '' }
})
const emit = defineEmits(['close', 'update', 'create'])

const question = ref(faq?.question || '')
const answer = ref(faq?.answer || '')

const disableSubmit = computed(() => !question.value || !answer.value)

const toast = useToast()
const { $backendApi } = useNuxtApp()
const submitting = ref(false)
async function submit () {
    if (/<h[1-3]>/.test(answer.value)) {
        return toast.error('Antwort enthält nicht erlaubte HTML-Tags (H1, H2, H3).')
    }
    submitting.value = true
    try {
        if (faq) {
            const updatedFaq = await $backendApi(`content/faqs/${faq.id}`, {
                method: 'PUT',
                body: { question: question.value, answer: answer.value, scope: faq.scope }
            })
            toast.success(`${question.value} erfolgreich bearbeitet.`)
            emit('update', updatedFaq)
        } else {
            const newFaq = await $backendApi('/content/faqs', {
                method: 'POST',
                body: { question: question.value, answer: answer.value, scope }
            })
            toast.success('Erstellung erfolgreich.')
            emit('create', newFaq)
        }
    } catch (error) {
        handleBackendError({ error, toast })
    }
    submitting.value = false
}
</script>

<style scoped>
.form-buttons {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
</style>
