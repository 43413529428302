<template>
    <BoxForSlot :fit-content="true">
        <template #content>
            <div class="flex-row">
                <ScoreCircleIndicator
                    :rating="business?.overallProfileScore"
                    :height="5"
                />
                <p class="mb-0">
                    <b>Durchschnittliche Profilstärke</b>
                    <br>
                    <span v-if="business?.overallProfileScore < 100">Füge mehr Informationen hinzu, um diesen Wert zu verbessern!</span>
                    <span v-else>Du hast die maximale Profilstärke erreicht!</span>
                    <br>
                    <NuxtLink v-if="business?.overallProfileScore < 100" :to="`${localePath('dashboard')}#infos`">
                        Profilstärke verbessern
                    </NuxtLink>
                </p>
            </div>
        </template>
    </BoxForSlot>
</template>

<script setup>
const localePath = useLocalePath()
const { business } = storeToRefs(useBusinessStore())
</script>

<style scoped>

</style>
