<template>
    <LoginPage
        email-button-text="Mit E-Mail registrieren"
        form-title="Partner werden"
    >
        <template #form>
            <FormRegistrationWithEmail />
        </template>
        <template #bottomLink>
            Du bist schon dabei?
            <NuxtLink :to="localePath('login')">
                Anmelden
            </NuxtLink>
        </template>
    </LoginPage>
</template>

<script setup>
definePageMeta({
    auth: {
        unauthenticatedOnly: true,
        navigateAuthenticatedTo: '/dashboard'
    },
    layout: 'full-width'
})

const localePath = useLocalePath()

useHead({
    title: 'Partner Profil erstellen'
})
</script>

<style scoped>
</style>
