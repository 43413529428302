<template>
    <div>
        <h2>Deine Servicegebiete</h2>
        <p class="mt-1">
            Sind jene Orte, wo du von Kunden gefunden
            werden möchtest. Du kannst beliebig viele Orte wählen (bspw. Wien oder 1140)
        </p>
        <LazyServiceLocationPolygonsDialog
            v-if="showLocationsDialog"
            v-model="locations"
            :confirm-callback="updatePolygons"
            @close="showLocationsDialog = false"
        />
        <ServiceLocationNationalAvailabilityToggle v-model="national" />
        <ServiceLocationOverviewCards
            :locations="locations"
            :disable="national"
            @show-locations-dialog="showLocationsDialog = true"
        />
        <button class="mt-2" :disabled="!allowNext" @click="next">
            Weiter
        </button>
    </div>
</template>

<script setup>
const emit = defineEmits(['next'])

const { $clientLocalStorage } = useNuxtApp()
const savedState = $clientLocalStorage.getItem({ item: 'newServiceProcess', parseJson: true }) ?? {}

const locations = ref(savedState?.locations || [])
const national = ref(savedState?.national || false)

const showLocationsDialog = ref(false)
function updatePolygons () {
    showLocationsDialog.value = false
}
const allowNext = computed(() => {
    return locations.value.length > 0 || national.value
})

function next () {
    savedState.locations = locations.value
    savedState.national = national.value
    $clientLocalStorage.setItem({
        item: 'newServiceProcess',
        value: savedState
    })
    emit('next')
}
</script>

<style scoped>

</style>
