<template>
    <img
        :style="props.customStyles"
        :class="{ absolute: props.positionAbsolute }"
        :src="'/icons/' + props.icon + '.svg'"
        alt="Action Icon"
        @click="emit('click')"
    >
</template>

<script setup>
const props = defineProps({
    icon: { type: String, required: true },
    positionAbsolute: { type: Boolean, required: false, default: true },
    customStyles: { type: Object, required: false, default: () => {} }
})
const emit = defineEmits(['click'])
</script>

<style scoped>
img {
    width: 2.5rem;
    height: 2.5rem;
    background-color: var(--neutral-100);
    border-radius: var(--border-radius);
    border: 3px solid var(--primary);
    cursor: pointer;
    padding: 0.5rem;
}
.absolute {
    position: absolute;
    bottom: 0;
    right: 0;
}
img:hover {
    background-color: var(--neutral-200);
}
</style>
