/**
 * On dashboard routes, fetch user's business and services if not done already.
 * This ensures that the store is always populated, even on page refresh.
 */
export default defineNuxtRouteMiddleware(async (to) => {
    if (!to.path.startsWith('/dashboard') || to.path.includes('stripe-postcheckout')) { return }

    // populate store (business and services) if not done yet
    const store = useBusinessStore()
    const serviceStore = useServiceStore()
    const { business } = storeToRefs(store)
    const { hasServices } = storeToRefs(serviceStore)

    if (!business.value) {
        try {
            await store.getBusiness()
            if (!hasServices.value) { await serviceStore.getServices(business.value.id) }
        } catch (error) {}
    }

    const localePath = useLocalePath()
    // check if user is on one of the onboarding routes, then no redirect to the onboarding overview
    if (to.path === localePath('dashboard-onboarding') ||
        to.path === localePath('dashboard-settings-contact-info') ||
        to.path === localePath('dashboard-business-new') ||
        to.path === localePath('dashboard-services-new') ||
        to.path === localePath('dashboard-activate-profile')
    ) { return }

    const { user, hasContactInfo } = useUser()
    // redirect user to onboarding page if not done yet
    if (!hasContactInfo.value || !hasServices.value ||
        (!business.value.stripeSubscriptionId && user.value?.id > 304)) {
        return navigateTo(localePath('dashboard-onboarding'))
    }
})
