<template>
    <dialog open>
        <article>
            <CloseIcon @close="emit('close')" />
            <div class="title">
                <slot name="title" />
            </div>
            <slot name="content" />
        </article>
    </dialog>
</template>

<script setup>
const emit = defineEmits(['close'])
</script>

<style scoped>
.title {
    margin-right: 2rem;
}
@media (min-width: 768px) {
    article {
        width: 80%;
    }
}
</style>
