<template>
    <div class="rating">
        <RatingStarImages :unique-identifier="String(totalReviews)" :rating="averageRating" />
        {{ truncatedRating }}
        <span v-if="totalReviews > 0" class="review-count">
            ({{ totalReviews }}
            <span v-if="totalReviews === 1">Bewertung</span>
            <span v-else>Bewertungen</span>)
        </span>
    </div>
</template>

<script setup>
const props = defineProps({
    averageRating: { type: Number, required: true },
    totalReviews: { type: Number, required: true }
})
const truncatedRating = computed(() => {
    return props.averageRating.toFixed(1)
})
</script>

<style scoped>

</style>
