<template>
    <div>
        <LazyBusinessSocialMediaEditDialog
            v-if="showSocialMediaEditDialog"
            @close="showSocialMediaEditDialog = false"
        />
        <p class="space-between">
            <b>Links zu Social Media</b>
            <ActionIcon
                icon="pen-solid"
                :position-absolute="false"
                @click="showSocialMediaEditDialog = true"
            />
        </p>
        <div class="some-container">
            <a v-for="(value, key) in socialMedia" :key="key" :aria-disabled="!value" :href="value" target="_blank">
                <img :class="{ 'gray': !value }" :src="`/icons/some-color/${key}-color.svg`" :alt="`${key} Icon`">
            </a>
        </div>
    </div>
</template>

<script setup>
const socialMedia = computed(() => useBusinessStore().getSocialMediaLinks())

const showSocialMediaEditDialog = ref(false)
</script>

<style scoped>
.some-container {
    display: flex;
    flex-direction: row;
    column-gap: var(--spacing);
    align-items: center;
    justify-content: center;
}
.some-container img {
    height: 2rem;
    width: auto;
}
.gray {
    filter: grayscale(100%);
}
</style>
