<template>
    <Teleport to="body">
        <dialog open>
            <article>
                <CloseIcon @close="emit('close')" />
                <h3>Ergänze hier die Zahlungsmethoden, mit denen die Kunden bei dir zahlen können.</h3>
                <div class="checkbox-container">
                    <div v-for="method in allPaymentMethods" :key="method.id">
                        <label>
                            <input
                                v-model="currentPaymentMethods"
                                type="checkbox"
                                :value="method.id"
                            >
                            {{ method.displayName }}
                        </label>
                    </div>
                </div>
                <button class="mt-1" :aria-busy="loading" @click="confirm">
                    Speichern
                </button>
            </article>
        </dialog>
    </Teleport>
</template>

<script setup>
const props = defineProps({
    serviceId: { type: Number, required: true }
})
const emit = defineEmits(['close'])

const serviceStore = useServiceStore()
const service = computed(() => serviceStore.getServiceById(props.serviceId))

const currentPaymentMethods = ref([...service.value.paymentMethods || []].map(pm => pm.id))
const allPaymentMethods = ref([])
const { $backendApi } = useNuxtApp()
onMounted(async () => {
    const { paymentMethods } = await $backendApi('/payment-methods/')
    allPaymentMethods.value = paymentMethods
})

const loading = ref(false)
const toast = useToast()
async function confirm () {
    loading.value = true
    try {
        await serviceStore.updatePaymentMethods({
            serviceId: props.serviceId,
            paymentMethodIds: currentPaymentMethods.value
        })
        toast.success('Gespeichert.')
        emit('close')
    } catch (error) {
        handleBackendError({ error, toast })
    }
    loading.value = false
}
</script>

<style scoped>
h3 {
    margin: 0 2rem 1rem 0;
}
.checkbox-container {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: var(--spacing);
}
@media (min-width: 375px) {
    .checkbox-container {
        grid-template-columns: 1fr 1fr;
    }
}
</style>
