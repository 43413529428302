<template>
    <section>
        <NavigationGoBack :link="localePath('dashboard')" />
        <h1 class="mt-1">
            Neues Service erstellen
        </h1>
        <i>Hinweis: Diese Service-Daten sind auf deiner <b>öffentlichen Anbieter-Seite</b> für alle Kunden zu sehen.</i>
        <client-only>
            <ProgressBar
                class="mt-1"
                :max="LAST_STEP"
                :current="step"
                @prev="changeStepBy(-1)"
            />
            <ServiceNewLocationStep
                v-if="step === 1"
                class="mt-1"
                @next="changeStepBy(1)"
            />
            <LazyServiceNewCategoryStep
                v-else-if="step === 2"
                class="mt-1"
                @next="changeStepBy(1)"
            />
            <LazyServiceNewNameDescriptionStep
                v-else-if="step === 3"
                class="mt-1"
                @next="changeStepBy(1)"
            />
            <LazyServiceNewBookingInfoStep
                v-else-if="step === 4"
                class="mt-1"
                @next="changeStepBy(1)"
            />
            <LazyServiceNewOpeningHoursStep
                v-else-if="step === 5"
                class="mt-1"
                @next="changeStepBy(1)"
            />
            <LazyServiceNewImagesStep
                v-else-if="step === 6"
                class="mt-1"
                @next="handleFinalStep"
            />
            <div v-else-if="step === LAST_STEP">
                <img
                    class="super-image"
                    src="/assets/images/business/super-star.png"
                    alt="Super, gut gemacht!"
                    title="Super, gut gemacht!"
                >
                <div v-if="business?.stripeSubscriptionId">
                    <h2>Dein Service ist live!</h2>
                    <p>
                        Du kannst es hier aufrufen:
                        <a
                            :href="servicePublicLink"
                            target="_blank"
                        >Zum Service auf {{ $myT('mainAppDomain') }}</a>
                    </p>
                </div>
                <NuxtLink
                    class="block"
                    role="button"
                    :to="nextLink"
                >
                    Weiter
                </NuxtLink>
            </div>
        </client-only>
    </section>
</template>

<script setup>
const LAST_STEP = 7

const step = ref(1)
const servicePublicLink = ref('')

const localePath = useLocalePath()
const { $clientLocalStorage } = useNuxtApp()
const serviceStore = useServiceStore()
const toast = useToast()
const { business, businessPublicLink } = storeToRefs(useBusinessStore())
const router = useRouter()

const totalServices = computed(() => serviceStore.totalServices)
const nextLink = computed(() => totalServices.value > 1
    ? localePath('dashboard')
    : localePath('dashboard-onboarding')
)

onMounted(() => {
    if (!business.value) {
        toast.info('Bitte lege zuerst ein Anbieterprofil an.')
        return router.push(localePath('dashboard'))
    }
    const savedState = $clientLocalStorage.getItem({ item: 'newServiceProcess', parseJson: true })
    step.value = savedState?.step || 1
    if (step.value > 1) {
        toast.info('Mache wieder dort weiter, wo du aufgehört hast.')
    }
    if (step.value === LAST_STEP) {
        $clientLocalStorage.removeItem({ item: 'newServiceProcess' })
    }
})

function changeStepBy (delta) {
    step.value += delta
    const savedState = $clientLocalStorage.getItem({ item: 'newServiceProcess', parseJson: true })
    savedState.step = step.value
    $clientLocalStorage.setItem({ item: 'newServiceProcess', value: JSON.stringify(savedState) })
}

function handleFinalStep (service) {
    step.value++
    $clientLocalStorage.removeItem({ item: 'newServiceProcess' })
    servicePublicLink.value = `${businessPublicLink.value}/mobil/${service.permanentName}-${service.id}`
}

useHead({
    titleTemplate: 'Neues Service erstellen'
})
</script>

<style scoped>
section { padding-bottom: 2rem; }
.super-image {
    width: 15rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
</style>
