<template>
    <div>
        <div v-if="editor" class="tiptap-buttons-wrapper">
            <div class="tiptap-buttons">
                <button
                    v-for="i in 7 - headingsLevelStart"
                    :key="'H' + i"
                    type="button"
                    :class="{
                        'is-active': editor.isActive('heading', { level: i + headingsLevelStart - 1 }),
                        'tiptap-button-active': editor.isActive('heading', { level: i + headingsLevelStart - 1 })
                    }"
                    @click="editor.chain().focus().toggleHeading({ level: i + headingsLevelStart - 1 }).run()"
                >
                    <img :src="`/icons/tiptap/h-${i + headingsLevelStart - 1}.svg`" :alt="`H${i + headingsLevelStart - 1}`">
                </button>
            </div>
            <div class="tiptap-buttons">
                <button
                    type="button"
                    :disabled="!editor.can().chain().focus().toggleBold().run()"
                    :class="{ 'is-active': editor.isActive('bold'), 'tiptap-button-active': editor.isActive('bold') }"
                    @click="editor.chain().focus().toggleBold().run()"
                >
                    <img src="/assets/icons/tiptap/bold.svg" alt="bold icon">
                </button>
                <button
                    type="button"
                    :disabled="!editor.can().chain().focus().toggleItalic().run()"
                    :class="{ 'is-active': editor.isActive('italic'), 'tiptap-button-active': editor.isActive('italic') }"
                    @click="editor.chain().focus().toggleItalic().run()"
                >
                    <img src="/assets/icons/tiptap/italic.svg" alt="italic icon">
                </button>
                <button
                    type="button"
                    :disabled="!editor.can().chain().focus().toggleUnderline().run()"
                    :class="{ 'is-active': editor.isActive('underline'), 'tiptap-button-active': editor.isActive('underline') }"
                    @click="editor.chain().focus().toggleUnderline().run()"
                >
                    <img src="/assets/icons/tiptap/underline.svg" alt="underline icon">
                </button>
            </div>
            <div class="tiptap-buttons">
                <button
                    type="button"
                    :class="{ 'is-active': editor.isActive('orderedList'), 'tiptap-button-active': editor.isActive('orderedList') }"
                    @click="editor.chain().focus().toggleOrderedList().run()"
                >
                    <img src="/assets/icons/tiptap/list-ordered.svg" alt="ordered list icon">
                </button>
                <button
                    type="button"
                    :class="{ 'is-active': editor.isActive('bulletList'), 'tiptap-button-active': editor.isActive('bulletList') }"
                    @click="editor.chain().focus().toggleBulletList().run()"
                >
                    <img src="/assets/icons/tiptap/list-unordered.svg" alt="unordered list icon">
                </button>
            </div>
            <div class="tiptap-buttons">
                <button
                    type="button"
                    :class="{ 'is-active': editor.isActive({ textAlign: 'left' }), 'tiptap-button-active': editor.isActive({ textAlign: 'left' }) }"
                    @click="editor.chain().focus().setTextAlign('left').run()"
                >
                    <img src="/assets/icons/tiptap/align-left.svg" alt="align left icon">
                </button>
                <button
                    type="button"
                    :class="{ 'is-active': editor.isActive({ textAlign: 'center' }), 'tiptap-button-active': editor.isActive({ textAlign: 'center' }) }"
                    @click="editor.chain().focus().setTextAlign('center').run()"
                >
                    <img src="/assets/icons/tiptap/align-center.svg" alt="align center icon">
                </button>
                <button
                    type="button"
                    :class="{ 'is-active': editor.isActive({ textAlign: 'right' }), 'tiptap-button-active': editor.isActive({ textAlign: 'right' }) }"
                    @click="editor.chain().focus().setTextAlign('right').run()"
                >
                    <img src="/assets/icons/tiptap/align-right.svg" alt="align right icon">
                </button>
            </div>
            <!--<div class="tiptap-buttons">
                <button :class="{ 'is-active': editor.isActive('link') }" type="button" @click="setLink">
                    <img src="/assets/icons/tiptap/links-line.svg" alt="link icon">
                </button>
                <button
                    type="button"
                    :disabled="!editor.isActive('link')"
                    @click="editor.chain().focus().unsetLink().run()"
                >
                    <img src="/assets/icons/tiptap/link-unlink.svg" alt="link icon">
                </button>
            </div>-->
        </div>

        <EditorContent :editor="editor" />

        <div v-if="editor" class="character-count">
            <span>{{ wordsCount }} {{ wordsCount !== 1 ? 'Wörter' : 'Wort' }}</span>
            <span :class="{'is-red': charCount < props.minLength}">{{ charCount }} Zeichen</span>
        </div>
    </div>
</template>

<script setup>
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import CharacterCount from '@tiptap/extension-character-count'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'

const props = defineProps({
    modelValue: { type: String, required: true, default: '' },
    minLength: { type: Number, default: 250 },
    headingsLevelStart: { type: Number, default: 3 }
})

const wordsCount = computed(() => {
    return editor.value.storage.characterCount.words()
})

const charCount = computed(() => {
    return editor.value.storage.characterCount.characters()
})

const emit = defineEmits(['update:modelValue', 'totalChars'])

const editor = useEditor({
    content: props.modelValue,
    editorProps: {
        attributes: {
            class: 'tiptap-editor'
        }
    },
    extensions: [
        StarterKit,
        CharacterCount,
        Underline,
        TextAlign.configure({
            types: ['heading', 'paragraph']
        }),
        Link.configure({
            HTMLAttributes: { style: 'cursor: pointer;' },
            validate: href => /^https?:\/\//.test(href),
            linkOnPaste: true,
            openOnClick: true
        })
    ],

    onUpdate: () => {
        emit('update:modelValue', editor.value?.getHTML())
        emit('totalChars', editor.value.storage.characterCount.characters())
    }
})

onMounted(() => {
    emit('totalChars', charCount.value)
})

watch(() => props.modelValue, (value) => {
    const isSame = editor.value.getHTML() === value
    if (isSame) { return }
    editor.value.commands.setContent(value, false)
})

onBeforeUnmount(() => {
    editor.value.destroy()
})

const setLink = () => {
    const previousUrl = editor.value.getAttributes('link').href
    let url = window.prompt('Geben Sie URL Ihrer Webseite ein: ', previousUrl)

    if (url === null || url === '') { return }

    /* TODO: Soll ich prompt-Eingaben validieren? */

    // Checking if the URL starts with 'https://' or 'http://'
    // If it does not, we add 'https://' to the beginning of the URL
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'https://' + url
    }

    editor.value.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
}
</script>

<style scoped>
:deep(.tiptap-editor) {
    cursor: text;
    min-height: 8rem;
    border: 1px solid var(--form-element-border-color);
    background-color: var(--white);
    border-radius: var(--border-radius);
    transition: .2s ease-in-out;
    padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
}
:deep(.tiptap-editor:is(:active, :focus)) {
    border-color: var(--form-element-active-border-color);
    box-shadow: 0 0 0 var(--outline-width) var(--form-element-focus-color);
}
:deep(.tiptap-editor:focus) {
    outline: none;
}
.character-count {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
}
.tiptap-buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--spacing) * 1.5);
}
.tiptap-buttons {
    display: flex;
    gap: calc(var(--spacing) * 0.3);
    margin-bottom: calc(var(--spacing) * 0.8);
}
.tiptap-buttons button {
    display: unset;
    width: auto;
    padding: 0;
    background: none;
    border: none;
}
.tiptap-buttons button img {
    width: 1.2rem;
}
.tiptap-buttons button.tiptap-button-active img {
    filter: invert(50%) sepia(0%) saturate(100%) hue-rotate(180deg) brightness(120%) contrast(100%);
}
.is-red { color: var(--red); font-weight: bold; }
@media (min-width: 576px) {
    .tiptap-buttons button img {
        width: 1.3rem;
    }
}
</style>
