<template>
    <div class="contact">
        <LazyDialogConfirmAction
            v-if="showDeleteLogoDialog"
            :loading="deleting"
            @cancel="showDeleteLogoDialog = false"
            @confirm="deleteLogo"
        >
            <template #title>
                <h3>Logo unwiderruflich löschen?</h3>
            </template>
            <template #content>
                <p>
                    Das Logo wird nicht mehr auf deiner Seite verfügbar sein und wir können es für dich nicht
                    mehr wiederherstellen. Selbstverständlich kannst du später ein neues Logo hochladen.
                    Wenn du das Logo lediglich tauschen möchtest, so ist ein Löschen nicht notwendig, wir ersetzen
                    das alte durch den neuen Upload.
                </p>
            </template>
        </LazyDialogConfirmAction>
        <div v-if="business?.logo" class="image-container">
            <img
                class="business-img"
                :src="logoPath"
                :alt="business?.name + ' Logo'"
            >
            <ActionIcon icon="pen-solid" :custom-styles="{top: '0'}" @click="editLogo = true" />
            <ActionIcon
                :custom-styles="{top: '3rem'}"
                class="red-border"
                icon="trash-can-solid"
                @click="showDeleteLogoDialog = true"
            />
        </div>
        <InputImageUpload
            v-if="editLogo || !business?.logo"
            :unique-identifier="business.id"
            :selected-file="selectedFile"
            @remove-file="selectedFile = null"
            @update:selected-file="(file) => selectedFile = file"
        >
            <template #dropzone-card>
                <img
                    src="/assets/icons/camera.svg"
                    alt="Camera Icon"
                    draggable="false"
                >
                Businesslogo hochladen<br>
                Empfehlung: Verhältnis 4:3, ca. 400 x 300px
            </template>
        </InputImageUpload>
        <button v-if="selectedFile" :aria-busy="uploading" @click="uploadFile">
            Hochladen
        </button>
        <BusinessSocialMedia />
        <BusinessContactInfo :business="business" />
    </div>
</template>

<script setup>
// business logo logic
const toast = useToast()
const businessStore = useBusinessStore()
const { business } = storeToRefs(businessStore)
const editLogo = ref(false)
const uploading = ref(false)
const deleting = ref(false)
const selectedFile = ref(null)

const spacesUrl = useRuntimeConfig().public.spacesUrl
const logoPath = computed(() => {
    return business.value?.logo ? `${spacesUrl}/images/business/${business.value.logo}` : null
})
async function uploadFile () {
    uploading.value = true
    try {
        await businessStore.uploadBusinessLogo({ file: selectedFile.value.file })
        toast.success('Upload erfolgreich')
        selectedFile.value = null
        editLogo.value = false
    } catch (error) {
        handleBackendError({ error, toast })
    }
    uploading.value = false
}

const showDeleteLogoDialog = ref(false)
async function deleteLogo () {
    deleting.value = true
    try {
        await businessStore.deleteBusinessLogo()
        toast.success('Logo gelöscht.')
    } catch (error) {
        handleBackendError({ error, toast })
    }
    showDeleteLogoDialog.value = false
    deleting.value = false
}
</script>

<style scoped>
.contact{
    z-index: 2;
}
.image-container { position: relative; }
.business-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 15rem;
    border-radius: var(--border-radius);
}
</style>
