<template>
    <footer class="container-fluid">
        <NuxtLink :to="localePath('index')">
            <img
                src="/assets/images/allekommen-partner-logo.svg"
                alt="Logo-white"
                class="logo"
            >
        </NuxtLink>
        <section class="footer-items mt-2">
            <section class="social-media">
                <p class="title">
                    Vernetze dich mit uns!
                </p>
                <div class="social-media-icons">
                    <a
                        href="https://www.linkedin.com/company/allekommen"
                        target="_blank"
                    >
                        <img
                            src="/assets/icons/social-media/linkedin.svg"
                            alt="LinkedIn"
                            title="LinkedIn"
                        >
                    </a>
                    <a
                        href="https://www.instagram.com/allekommen"
                        target="_blank"
                    >
                        <img
                            src="/assets/icons/social-media/instagram.svg"
                            alt="Instagram"
                            title="Instagram"
                        >
                    </a>
                    <a
                        href="https://www.facebook.com/allekommenAT"
                        target="_blank"
                    >
                        <img
                            src="/assets/icons/social-media/facebook.svg"
                            alt="Facebook"
                            title="Facebook"
                        >
                    </a>
                    <a
                        href="https://www.youtube.com/@allekommen"
                        target="_blank"
                    >
                        <img
                            src="/assets/icons/social-media/youtube.svg"
                            alt="YouTube"
                            title="YouTube"
                        >
                    </a>
                    <a
                        href="https://www.tiktok.com/@allekommen.at"
                        target="_blank"
                    >
                        <img
                            src="/assets/icons/social-media/tiktok.svg"
                            alt="TikTok"
                            fill="white"
                        >
                    </a>
                    <a
                        href="https://www.threads.net/@allekommen"
                        target="_blank"
                    >
                        <img
                            src="/assets/icons/social-media/threads.svg"
                            alt="threads"
                            title="threads"
                        >
                    </a>
                </div>
            </section>
            <section class="business">
                <p class="title">
                    {{ $myT('mainAppDomain') }} Partner
                </p>
                <NuxtLink :to="localePath('become-partner')">
                    Unternehmen eintragen
                </NuxtLink>
                <NuxtLink :to="localePath('privatanbieter')">
                    Privatanbieter
                </NuxtLink>
                <NuxtLink :to="localePath('prices')">
                    Preise
                </NuxtLink>
                <NuxtLink :to="localePath('login')">
                    Registrierung & Login
                </NuxtLink>
            </section>
            <section>
                <p class="title">
                    Erfolgreich verkaufen
                </p>
                <NuxtLink :to="localePath('start')">
                    3 einfache Fragen, um zu wachsen
                    <span class="new-link">NEU</span>
                </NuxtLink>
                <NuxtLink :to="localePath('boomende-online-on-demand-heimdienstleistungen')">
                    Studie: Trend zu mobilen Dienstleistungen
                </NuxtLink>
                <NuxtLink :to="localePath('cooperations')">
                    Feed & Shop Kooperationen
                    <span class="new-link">NEU</span>
                </NuxtLink>
                <NuxtLink :to="localePath('restaurants-mit-lieferservice')">
                    Restaurants mit Lieferservice
                </NuxtLink>
                <NuxtLink :to="localePath('customer-support-business-faq')">
                    Häufig gestellte Fragen (FAQ)
                </NuxtLink>
            </section>
            <section>
                <p class="title">
                    Kontakt
                </p>
                <NuxtLink :to="localePath('free-consulting')">
                    Kostenfreie Erstberatung
                    <span class="new-link">NEU</span>
                </NuxtLink>
                <NuxtLink :to="localePath('free-consulting')">
                    Kontakt
                </NuxtLink>
                <a
                    :href="`https://www.${$myT('mainAppDomain')}`"
                    target="_blank"
                >Zu {{ $myT('mainAppDomain') }}</a>
                <NuxtLink :to="localePath('/#newsletter')">
                    Anbieter-Newsletter
                </NuxtLink>
            </section>
        </section>
        <div
            v-if="locale === 'at'"
            class="wrapper"
        >
            <div class="marktplatz">
                <img
                    src="/images/oe_marktplatz_siegel.png"
                    alt="Österreicher Handelsverband, Marktplatz Siegel"
                >
                <p>allekommen.at ist Partner des österreichischen Handel!</p>
            </div>
        </div>
        <p class="centered mt-1">
            Mit ❤️ gemacht in Wien!
        </p>
        <p class="centered copyright">
            &copy; {{ new Date().getFullYear() }} allekommen e.U.
        </p>
        <section class="legal">
            <a
                class="secondary"
                :href="`https://www.${$myT('mainAppDomain')}/datenschutz`"
            >
                Datenschutz
            </a>
            <a
                class="secondary"
                :href="`https://www.${$myT('mainAppDomain')}/impressum`"
            >
                Impressum
            </a>
            <a
                class="secondary"
                :href="`https://www.${$myT('mainAppDomain')}/nutzungsbedingungen`"
            >
                Nutzungsbedingungen
            </a>
            <a
                class="secondary"
                :href="`https://www.${$myT('mainAppDomain')}/agb`"
            >
                AGB
            </a>
            <a
                href="#"
                @click="showCookieBanner"
            >
                Cookie Einstellungen
            </a>
        </section>
    </footer>
</template>

<script setup>
const localePath = useLocalePath()
const locale = useRuntimeConfig().public.locale

function showCookieBanner () {
    const _hsp = window._hsp = window._hsp || []
    _hsp.push(['showBanner'])
}
</script>

<style scoped>
section {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    align-items: flex-start;
    margin-bottom: calc(1.5 * var(--spacing));
    row-gap: var(--spacing);
}
.title {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 1.2rem;
}
footer {
    padding: calc(2 * var(--spacing));
    margin-top: auto;
    background-color: var(--dark-blue);
    font-size: calc(0.8 * var(--font-size));
}
footer p, footer section a {
    color: var(--white);
    text-decoration: none;
    min-height: 24px;
}
.footer-items {
    display: flex;
    flex-direction: column;
    gap: 3.3rem;
}
.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 0 1rem;
    align-items: center;
    justify-items: center;
}
.copyright {
    font-size: calc(0.8 * var(--font-size));
}
.social-media-icons {
    display: flex;
    justify-content: center;
    column-gap: 1rem;
    margin-top: 0.5rem;
}
.social-media-icons a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: var(--dark-blue);
}
.new-link {
    padding: 0.1rem 0.4rem;
    background-color: var(--yellow);
    color: var(--dark-blue);
    border-radius: var(--border-radius);
}
.marktplatz {
    grid-column: span 2;
    grid-row: 2;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    grid-template-columns: 5rem auto;
}
.marktplatz p {
    font-size: 0.75rem;
    margin-bottom: 0;
    min-height: 0;
}
.marktplatz img {
    width: 4rem;
    height: 4rem;
}
.business a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.logo {
    width: 12rem;
    height: 2.5rem;
}
.legal {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 2rem;
    row-gap: 0;
}
@media (min-width: 425px) {
    .footer-items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
}
@media (min-width: 768px) {
    section {
        row-gap: var(--spacing);
    }
    .wrapper {
        display: flex;
        flex-direction: row;
        column-gap: var(--spacing);
        justify-content: center;
    }
}
@media (min-width: 992px) {
    section {
        row-gap: var(--spacing);
    }
}
@media (min-width: 1024px) {
    .footer-items {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
    }
    .footer-items > .social-media {
        grid-column: 4;
        grid-row: 1;
    }
    .social-media-icons {
        column-gap: 0.75rem;
        justify-content: flex-start;
    }
    section {
        row-gap: calc(0.5 * var(--spacing));
    }
    .social-media-icons a {
        width: 1.25rem;
        height: 1.25rem;
    }
}
</style>
