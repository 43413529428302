<template>
    <Teleport to="body">
        <dialog open>
            <article>
                <CloseIcon @close="emit('close')" />
                <h3>Buchung und Kontakt</h3>
                <FormServiceBookingInfo
                    v-model="bookingLink"
                    :loading="loading"
                    @submit="confirm"
                />
            </article>
        </dialog>
    </Teleport>
</template>

<script setup>
const props = defineProps({
    serviceId: { type: Number, required: true }
})
const emit = defineEmits(['close'])

const serviceStore = useServiceStore()
const service = computed(() => serviceStore.getServiceById(props.serviceId))
const bookingLink = ref(service.value.bookingLink || '')

const loading = ref(false)
const toast = useToast()
async function confirm () {
    loading.value = true
    try {
        await serviceStore.updateBookingInfo({
            serviceId: props.serviceId,
            bookingLink: bookingLink.value
        })
        toast.success('Kontaktinfo gespeichert.')
        emit('close')
    } catch (error) {
        handleBackendError({ error, toast })
    }
    loading.value = false
}
</script>

<style scoped>
h3 {
    margin: 0 2rem 1rem 0;
}
</style>
