export default defineNuxtPlugin(() => {
    return {
        provide: {
            clientLocalStorage: {
                /**
                 * getItem function to retrieve an item from local storage and optionally parse it as JSON.
                 * @param {string} item - the key of the item to retrieve from local storage
                 * @param {boolean} parseJson - whether to parse the retrieved item as JSON
                 * @return {any} the retrieved item from local storage, possibly parsed as JSON
                 */
                getItem ({ item, parseJson }) {
                    if (!process.client) { return null }
                    try {
                        const data = localStorage.getItem(item)
                        if (data === null) { return null }
                        return parseJson ? JSON.parse(data) : data
                    } catch (e) {}
                },
                /**
                 * Set an item in the local storage.
                 * @param {string} item - the key of the item
                 * @param {string} value - the value of the item
                 * @return {void}
                 */
                setItem ({ item, value }) {
                    if (!process.client) { return }
                    try {
                        if (typeof value === 'object') { value = JSON.stringify(value) }
                        localStorage.setItem(item, value)
                    } catch (e) {}
                },
                /**
                 * Remove an item from the local storage.
                 * @param {string} item - the key of the item
                 * @return {void}
                 */
                removeItem ({ item }) {
                    if (!process.client) { return }
                    try {
                        localStorage.removeItem(item)
                    } catch (e) {}
                }
            }
        }
    }
})
