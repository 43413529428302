<template>
    <Teleport to="body">
        <dialog open>
            <article>
                <CloseIcon @close="emit('close')" />
                <h3>Unternehmensstandort</h3>
                <InputAddressFields v-model="address" />
                <small>* Pflichtfelder</small>
                <button :disabled="!allowSubmit" :aria-busy="loading" class="mt-1" @click="confirm">
                    Fertig
                </button>
            </article>
        </dialog>
    </Teleport>
</template>

<script setup>
const emit = defineEmits(['close'])

const toast = useToast()
const businessStore = useBusinessStore()
const { business } = storeToRefs(businessStore)

const address = ref({
    streetName: business.value?.address.streetName || '',
    city: business.value?.address.city || '',
    zipCode: business.value?.address.zipCode || '',
    houseNumber: business.value?.address.houseNumber || ''
})

const allowSubmit = computed(() => {
    return address.value.streetName && address.value.city && address.value.zipCode && address.value.houseNumber
})

const loading = ref(false)
async function confirm () {
    loading.value = true
    try {
        const ai = address.value
        const addressString = `${ai.streetName} ${ai.houseNumber}, ${ai.zipCode} ${ai.city}`
        const locations = await $fetch('/api/address-search/geocoding', {
            query: { address: addressString }
        })
        if (!locations) { return toast.error('Die Adresse konnte nicht gefunden werden') }
        const { lat, lon } = locations[0]
        await businessStore.updateBusinessAddressInfo({
            address: address.value, lat, lon
        })
        toast.success('Erfolgreich gespeichert!')
        emit('close')
    } catch (error) {
        handleBackendError({ error, toast })
    }
    loading.value = false
}
</script>

<style scoped>
@media (min-width: 768px) {
    dialog article {
        max-width: 800px;
    }
}
</style>
