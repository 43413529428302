<template>
    <dialog open>
        <article>
            <CloseIcon @close="emit('close')" />
            <h3>Service-Zeiten</h3>
            <p>
                Hinweis: hier kannst du für jeden Tag deine Öffnungs- bzw. Arbeitszeiten definieren.
                Wenn du nichts angibst, wird angenommen, dass du an diesem Tag geschlossen hast.
                Falls du keine fixen Zeiten hast, kannst du auch die Option "Auf Anfrage" wählen.
            </p>
            <FormServiceOpeningHours
                v-model="openingHours"
                :loading="loading"
                @confirm="confirmEdit"
            />
        </article>
    </dialog>
</template>

<script setup>
const emit = defineEmits(['close', 'confirm-edit'])

const props = defineProps({
    serviceId: { type: Number, required: true }
})

const serviceStore = useServiceStore()
const service = computed(() => serviceStore.getServiceById(props.serviceId))

// Create an object to hold opening hours for each day and populate it
const openingHours = ref(initializeOpeningHours([], service.value.openingHours))

const loading = ref(false)
const toast = useToast()
async function confirmEdit () {
    const formattedValidHours = formatOpeningHoursForBackend(openingHours.value)
    loading.value = true
    try {
        await serviceStore.setServiceOpeningHours({
            serviceId: props.serviceId, hours: formattedValidHours
        })
        toast.success('Gespeichert.')
        emit('close')
    } catch (error) {
        handleBackendError({ error, toast })
    }
    loading.value = false
}
</script>

<style scoped>
h3 { margin-bottom: 0.5rem; }
</style>
