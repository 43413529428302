<template>
    <div>
        <h2>Titelbild und weiter Bilder</h2>
        <p>
            Das Titelbild ist auf deiner Service-Card in allen Suchergebnissen zu sehen.
            <strong>Es ist essentiell, um dein Angebot auffallend zu machen!</strong>
        </p>
        <InputImageUpload
            :selected-file="selectedFile"
            unique-identifier="new-service"
            @remove-file="selectedFile = null"
            @update:selected-file="(file) => selectedFile = file"
        >
            <template #dropzone-card>
                <img
                    src="/assets/icons/camera.svg"
                    alt="Camera Icon"
                    draggable="false"
                >
                Hier klicken, um Titelbild für die Service-Card hochzuladen<br>
                Empfehlung: Verhältnis 4:3, ca. 400 x 300px
            </template>
        </InputImageUpload>
        <p class="mt-1">
            Gute Bilder werten deine Service-Page sehr stark auf. Oft ist es das Erste, was der Besucher wahrnimmt.
            <strong>Wir empfehlen zumindest drei Bilder hinzuzufügen</strong>.
        </p>
        <DropZone v-model="newImages" class="mt-1" />
        <button :class="{ 'outline': !selectedFile?.file }" class="mt-1" :aria-busy="uploading" @click="createService">
            {{ buttonText }}
        </button>
    </div>
</template>

<script setup>
const emit = defineEmits(['next'])

const { $clientLocalStorage } = useNuxtApp()
const savedState = $clientLocalStorage.getItem({ item: 'newServiceProcess', parseJson: true }) ?? {}

const selectedFile = ref(null)
const newImages = ref([])

const buttonText = ref('Erstellen')

const serviceStore = useServiceStore()
const toast = useToast()
const uploading = ref(false)
async function createService () {
    uploading.value = true
    try {
        buttonText.value = 'Erstelle Service...'
        const service = await serviceStore.createService({
            dataFields: savedState, serviceCardImage: selectedFile.value?.file
        })
        toast.success('Service erfolgreich erstellt!')
        selectedFile.value = null
        if (newImages.value.length > 0) {
            buttonText.value = 'Lade Bilder hoch...'
            try {
                await serviceStore.uploadServiceImages({ images: newImages.value, serviceId: service.id })
            } catch (error) {
                toast.error('Service-Bilder konnten nicht hochgeladen werden, bitte versuche es später erneut.')
            }
        }
        emit('next', service)
    } catch (error) {
        handleBackendError({ error, toast })
        buttonText.value = selectedFile.value?.file ? 'Erstellen' : 'Ohne Titelbild erstellen'
    }
    uploading.value = false
}
</script>

<style scoped>

</style>
