export const useServiceStore = defineStore('service', () => {
    const { $backendApi } = useNuxtApp()

    const services = ref([])
    const totalServices = ref(0)
    const hasNext = ref(false)
    function $reset () {
        services.value = []
        totalServices.value = 0
        hasNext.value = false
    }

    const hasServices = computed(() => totalServices.value > 0)

    function replaceService (service) {
        const index = services.value.findIndex(obj => obj.id === service.id)
        if (index === -1) { return }
        services.value[index] = service
    }

    // Exported Methods //

    /**
     * Get a service by its ID.
     * @param {number} serviceId - the ID of the service
     * @return {Object} the service object with the specified ID
     */
    function getServiceById (serviceId) {
        return services.value.find(obj => obj.id === serviceId)
    }

    async function getServices (businessId) {
        const response = await $backendApi(`/mobile-services/business/${businessId}`)
        services.value = response.services
        totalServices.value = response.total
        hasNext.value = response.hasNext
    }
    async function createService ({ dataFields, serviceCardImage }) {
        const formData = createFormDataForNewService({ dataFields, serviceCardImage })
        const service = await $backendApi('mobile-services/new-full', {
            method: 'POST', body: formData
        })
        services.value.unshift(service)
        totalServices.value++
        return service
    }
    async function updateServiceName ({ newName, serviceId }) {
        const service = await $backendApi(`/mobile-services/${serviceId}/name`, {
            method: 'PUT', body: { name: newName }
        })
        replaceService(service)
    }
    async function updateServiceCardImage ({ image, serviceId }) {
        const formData = new FormData()
        // Append the file to the FormData object
        formData.append('image', image)
        const service = await $backendApi(`/mobile-services/${serviceId}/service-card-image`, {
            method: 'PUT', body: formData
        })
        replaceService(service)
    }
    async function uploadServiceImages ({ images, serviceId }) {
        const formData = new FormData()
        // Append the file to the FormData object
        for (let i = 0; i < images.length; i++) {
            const file = images[i].file
            formData.append('images[]', file)
        }
        const service = await $backendApi(`/mobile-services/${serviceId}/service-images`, {
            method: 'POST', body: formData
        })
        replaceService(service)
    }
    async function updateCategory ({ newCategoryId, serviceId }) {
        const service = await $backendApi(`/mobile-services/${serviceId}/category`, {
            method: 'PUT', body: { categoryId: newCategoryId }
        })
        replaceService(service)
    }
    async function updatePolygons ({ locationIds, serviceId }) {
        const service = await $backendApi(`/mobile-services/${serviceId}/polygons`, {
            method: 'PUT', body: { locationIds }
        })
        replaceService(service)
    }
    /**
     * Delete service card image by service ID.
     * @param {int} serviceId
     * @return {void}
     */
    async function deleteServiceCardImage (serviceId) {
        await $backendApi(`/mobile-services/${serviceId}/service-card-image`, { method: 'DELETE' })
        const service = getServiceById(serviceId)
        replaceService({ ...service, mainImage: null })
    }
    async function updateNational ({ newNational, serviceId }) {
        const service = await $backendApi(`/mobile-services/${serviceId}/polygons/national`, {
            method: 'PUT', body: { national: newNational }
        })
        replaceService(service)
    }
    async function deleteServiceImage ({ serviceId, imageId }) {
        const service = await $backendApi(`/mobile-services/${serviceId}/service-images/${imageId}`, { method: 'DELETE' })
        replaceService(service)
    }
    async function setServiceOpeningHours ({ serviceId, hours }) {
        const service = await $backendApi(`/mobile-services/${serviceId}/opening-hours`, {
            method: 'POST', body: { openingHours: hours }
        })
        replaceService(service)
    }
    async function updateBookingInfo ({ serviceId, bookingLink }) {
        const service = await $backendApi(`/mobile-services/${serviceId}/booking-info`, {
            method: 'PUT', body: { bookingLink }
        })
        replaceService(service)
    }
    async function updatePaymentMethods ({ serviceId, paymentMethodIds }) {
        const service = await $backendApi(`/mobile-services/${serviceId}/payment-methods`, {
            method: 'PUT', body: { paymentMethodIds }
        })
        replaceService(service)
    }
    async function updateServiceDescription ({ serviceId, description }) {
        const service = await $backendApi(`/mobile-services/${serviceId}/info`, {
            method: 'PUT', body: { info: description }
        })
        replaceService(service)
    }
    async function createProduct ({ serviceId, product }) {
        const { updatedService, newProduct } = await $backendApi(`/mobile-services/${serviceId}/products`, {
            method: 'POST', body: product
        })
        replaceService(updatedService)
        return newProduct.id
    }
    async function updateProduct ({ serviceId, productId, product }) {
        const service = await $backendApi(`/mobile-services/${serviceId}/products/${productId}`, {
            method: 'PUT', body: product
        })
        replaceService(service)
    }
    async function deleteProduct ({ serviceId, productId }) {
        const { minPrice, newProfileScore } = await $backendApi(`/mobile-services/${serviceId}/products/${productId}`, {
            method: 'DELETE'
        })
        const service = getServiceById(serviceId)
        replaceService({
            ...service,
            minPrice,
            profileScore: newProfileScore
        })
    }

    return {
        $reset,
        services,
        totalServices,
        hasServices,
        getServices,
        createService,
        getServiceById,
        updateCategory,
        updatePolygons,
        updateServiceName,
        uploadServiceImages,
        updateServiceCardImage,
        deleteServiceCardImage,
        updateNational,
        deleteServiceImage,
        setServiceOpeningHours,
        updateBookingInfo,
        updatePaymentMethods,
        updateServiceDescription,
        createProduct,
        updateProduct,
        deleteProduct
    }
})
