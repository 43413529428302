<template>
    <nav class="container-fluid header">
        <ul>
            <li>
                <NuxtLink :to="localePath(logoPath)">
                    <img
                        class="navbar-logo"
                        src="/assets/images/allekommen-partner-logo.svg"
                        alt="Partner Logo"
                        loading="lazy"
                    >
                </NuxtLink>
            </li>
        </ul>
        <ul class="hamburger-container">
            <li
                class="hamburger"
                @click="toggleSidebar"
            >
                <img
                    class="hamburger-icon"
                    :src="isSidebarOpen ? '/icons/xmark-solid-white.svg' : '/icons/bars-solid.svg'"
                    alt="Hamburger menu"
                >
            </li>
        </ul>
        <ul
            v-if="showNavigationLinks"
            class="navigation-links"
        >
            <li>
                <NuxtLink
                    :to="localePath('prices')"
                    class="nav_links"
                >
                    Preise
                </NuxtLink>
            </li>
            <li>
                <NuxtLink
                    :to="localePath('free-consulting')"
                    class="nav_links"
                >
                    Kontakt
                </NuxtLink>
            </li>
            <li v-if="isAuthenticated">
                <NuxtLink
                    :to="localePath('dashboard')"
                    role="button"
                    class="nav-icon-container small nav_links"
                >
                    <img
                        src="/assets/icons/home-primary-solid.svg"
                        alt="Dashboard"
                        loading="lazy"
                    >
                    <span>Dashboard</span>
                </NuxtLink>
            </li>
            <li>
                <NuxtLink
                    role="button"
                    :to="localePath('rewards')"
                    class="small"
                    :class="{ yellow: isAuthenticated }"
                >
                    Bis 585 € Prämien
                </NuxtLink>
            </li>
        </ul>
    </nav>
</template>

<script setup>
const localePath = useLocalePath()
const { isAuthenticated } = useUser()

const { showNavigationLinks } = useOnboardingDone()

const logoPath = computed(() => isAuthenticated.value ? 'dashboard' : 'index')

const isSidebarOpen = defineModel({ type: Boolean })
function toggleSidebar () {
    isSidebarOpen.value = !isSidebarOpen.value
}
const route = useRoute()
watch(() => route.path, () => {
    isSidebarOpen.value = false
})
</script>

<style scoped>
nav {
    position: fixed;
    display: flex;
    background-color: var(--dark-blue);
    justify-content: space-between;
    height: 4rem;
    overflow-x: hidden;
    z-index: 10;
}
nav ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    list-style: none;
    padding-inline-start: 0;
}
.navigation-links { display: none; }
nav li { margin: 0; }
.nav_links {
    color: var(--white);
    text-decoration: none;
}
.hamburger { cursor: pointer;}
.hamburger-icon { height: 2.5rem; width: 2.5rem; }
.navbar-logo { width: 12rem; height: 2rem; }
.hamburger-container {
    justify-content: flex-end;
    gap: var(--spacing);
}
.nav-icon-container {
    padding: 0;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    width: 100%;
}
@media (min-width: 1024px) {
    .header {
        grid-column: span 2;
    }
    .hamburger-container {
        display: none;
    }
    .navigation-links {
        position: static;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0;
        column-gap: var(--spacing);
    }
    .navbar-logo { height: 3rem; }
    nav ul { padding: 0; }
    .nav-icon-container span { display: none; }
}
</style>
