<template>
    <div>
        <!-- Start of Meetings Embed Script -->
        <div
            class="meetings-iframe-container"
            data-src="https://meetings-eu1.hubspot.com/michael-pisnyachevskiy/meeting?embed=true"
        />
        <component :is="'script'" type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js" />
        <!-- End of Meetings Embed Script -->
    </div>
</template>
