<template>
    <aside class="sidebar" :class="isSidebarOpen ? 'show' : 'hide'">
        <div v-if="showNavigationLinks">
            <NuxtLink class="block-link" role="button" :to="localePath('dashboard-services-new')">
                <img src="/icons/plus-circle.svg" alt="Neues Service erstellen">
                Service erstellen
            </NuxtLink>
            <nav class="mt-1">
                <ul>
                    <li class="sidebar-link">
                        <NuxtLink :to="localePath('dashboard')">
                            <img class="sidebar-icon" src="/assets/icons/sidebar/overview.svg" alt="Übersicht">
                            Übersicht
                        </NuxtLink>
                    </li>
                    <li class="sidebar-link" :class="{ 'bold': business?.totalUnreadContactRequests > 0 }">
                        <NuxtLink :to="localePath('dashboard-inbox')">
                            <img class="sidebar-icon" src="/assets/icons/sidebar/mail-solid-yellow.svg" alt="Kontaktanfragen">
                            <span>
                                Kontaktanfragen
                                <span v-if="business?.totalUnreadContactRequests > 0">
                                    ({{ business?.totalUnreadContactRequests }})
                                    <span class="notification-dot" />
                                </span>
                            </span>
                        </NuxtLink>
                    </li>
                    <li class="sidebar-link">
                        <NuxtLink :to="localePath('dashboard-statistics')">
                            <img class="sidebar-icon" src="/assets/icons/sidebar/statistics.svg" alt="Statistiken">
                            Statistiken
                        </NuxtLink>
                    </li>
                    <li class="sidebar-link">
                        <NuxtLink :to="localePath('dashboard-services')">
                            <img class="sidebar-icon" src="/assets/icons/sidebar/truck-solid.svg" alt="Meine Services">
                            Meine Services
                        </NuxtLink>
                    </li>
                    <li class="sidebar-link">
                        <NuxtLink :to="localePath('dashboard-business-coupons')">
                            <img class="sidebar-icon" src="/assets/icons/sidebar/coupon-creator.svg" alt="Gutscheingenerator">
                            Gutscheingenerator
                        </NuxtLink>
                    </li>
                    <li class="sidebar-link">
                        <NuxtLink :to="localePath('dashboard-business-edit')">
                            <img class="sidebar-icon" src="/assets/icons/sidebar/edit-business.svg" alt="Anbieterprofil bearbeiten">
                            Anbieterprofil bearbeiten
                        </NuxtLink>
                    </li>
                    <li class="sidebar-link">
                        <NuxtLink :to="localePath('dashboard-settings-contact-info')">
                            <img class="sidebar-icon" src="/assets/icons/sidebar/edit-contact.svg" alt="Kontaktdaten ändern">
                            Kontaktdaten ändern
                        </NuxtLink>
                    </li>
                    <li class="sidebar-link">
                        <NuxtLink :to="localePath('dashboard-settings')">
                            <img class="sidebar-icon" src="/assets/icons/sidebar/settings.svg" alt="Einstellungen">
                            Einstellungen
                        </NuxtLink>
                    </li>
                    <li v-if="isAuthenticated" class="sidebar-link">
                        <a :href="businessPublicLink" target="_blank">
                            <img class="sidebar-icon" src="/assets/icons/sidebar/mdi-public.svg" alt="Live Profil ansehen">
                            Live Profil ansehen
                        </a>
                    </li>
                </ul>
            </nav>
            <hr v-if="isSidebarOpen" class="sidebar-divider mt-2 mb-2">
            <ul v-if="isSidebarOpen">
                <li class="award-link">
                    <NuxtLink
                        role="button"
                        :to="localePath('rewards')"
                        class="small"
                        :class="{ 'yellow': isAuthenticated }"
                    >
                        Bis 585 € Prämien
                    </NuxtLink>
                </li>
                <li class="sidebar-link">
                    <NuxtLink :to="localePath('prices')">
                        Preise
                    </NuxtLink>
                </li>
                <li class="sidebar-link">
                    <NuxtLink :to="localePath('free-consulting')">
                        Kontakt
                    </NuxtLink>
                </li>
            </ul>
        </div>
        <MichaelCard v-else class="mb-1" />
        <NavigationLogoutLinkDashboard />
    </aside>
</template>

<script setup>
defineProps({
    isSidebarOpen: { type: Boolean, default: false }
})

const localePath = useLocalePath()
const { isAuthenticated } = useUser()
const { businessPublicLink, business } = storeToRefs(useBusinessStore())
const { showNavigationLinks } = useOnboardingDone()
</script>

<style scoped>
.sidebar {
    position: fixed;
    top: 4rem;
    left: 0;
    z-index: 100;
    width: 0;
    height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
    background-color: var(--dark-blue);
    transition: width 0.3s ease-in-out;
    overflow-x: hidden;
    padding: var(--spacing) 0;
}
.sidebar.show {
    width: 100%;
}
.sidebar.hide {
    width: 0;
}
ul {
    padding: 0;
}
.sidebar-link {
    cursor: pointer;
    color: var(--white);
    justify-content: start;
    padding: 0.5rem 1rem;
    transition: all 0.2s ease-in-out;
}
.sidebar-link:last-child {
    margin-bottom: 0;
}
.sidebar-link:hover {
    color: var(--white);
    background-color: var(--background-color);
}
.sidebar-link a {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    color: var(--white);
}
.sidebar-link:hover a {
    color: var(--dark-blue);
    text-decoration: none;
}
.award-link {
    padding: 0 var(--spacing);
}
.sidebar-icon {
    width: 1.2rem;
    height: 1.2rem;
}
.sidebar-divider {
    border-top: 3px solid var(--muted-border-color);
    border-radius: var(--border-radius);
}
.notification-dot {
    background-color: var(--red);
    border-radius: 50%;
    height: 0.75rem;
    width: 0.75rem;
    display: inline-block;
}
.bold a { font-weight: bold; color: var(--yellow); }
@media (min-width: 768px) {
    .sidebar.show {
        width: 18rem;
    }
}
@media (min-width: 1024px) {
    .sidebar {
        grid-column: 1;
        grid-row: 2;
        height: calc(100vh - 4rem);
        position: sticky;
        z-index: 10;
        flex-direction: column;
        gap: var(--spacing);
        align-self: start;
    }
    .sidebar.show, .sidebar.hide {
        width: 100%;
    }
    .sidebar-icon {
        width: 1.5rem;
        height: 1.5rem;
    }
}
</style>
