<template>
    <LoginPage
        email-button-text="Mit E-Mail anmelden"
        :form-title="`${$myT('mainAppDomain')} Partner Login`"
    >
        <template #form>
            <FormEmailAndPassword />
        </template>
        <template #bottomLink>
            Noch kein Login?
            <NuxtLink :to="localePath('become-partner')">
                Anbieter werden
            </NuxtLink>
        </template>
    </LoginPage>
</template>

<script setup>
definePageMeta({
    auth: {
        unauthenticatedOnly: true,
        navigateAuthenticatedTo: '/dashboard'
    },
    layout: 'full-width'
})

const localePath = useLocalePath()

useHead({
    title: 'Login'
})
</script>

<style scoped>
</style>
