<template>
    <form @submit.prevent="submit">
        <label class="mt-1" for="websiteLink">
            Website Link für Buchungen. Falls Du keine Webseite hast, kannst Du hier auch
            einen Link zu Social Media angeben. *
            <input
                id="websiteLink"
                v-model="bookingLink"
                type="url"
                name="websiteLink"
                :aria-invalid="bookingLink.length === 0 ? '' :String(linkProblem !== null)"
                placeholder="https://www.deine-webseite.at"
                @blur="validateLink(bookingLink)"
            >
        </label>
        <small v-if="linkProblem !== null">
            {{ linkProblem }}
        </small>
        <button
            :aria-busy="loading"
            :disabled="linkProblem !== null || bookingLink.length === 0"
            class="mt-1"
        >
            {{ buttonText }}
        </button>
    </form>
</template>

<script setup>
const emit = defineEmits(['submit'])
defineProps({
    loading: { type: Boolean, default: false },
    buttonText: { type: String, default: 'Speichern' }
})

const bookingLink = defineModel({ type: String })

const linkProblem = ref(null)
function validateLink (link) {
    if (link.length === 0) {
        linkProblem.value = 'Bitte Link ergänzen!'
        return
    } else if (!link.startsWith('https')) {
        linkProblem.value = 'Bitte https im Link ergänzen!'
        return
    }
    linkProblem.value = null
}

function submit () {
    validateLink(bookingLink.value)
    if (linkProblem.value !== null) { return }
    emit('submit')
}
</script>

<style scoped>
input { margin-bottom: 0; }
</style>
