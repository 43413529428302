<template>
    <section>
        <h2>Detailiformationen</h2>
        <div class="service-card-container">
            <p>
                Hier ist die <b>Vorschau deiner Service-Card auf {{ $myT('mainAppDomain') }}</b>.
                So wird dein Service in den Suchergebnissen präsentiert.
            </p>
            <ServiceSearchCard
                class="service-card"
                :use-link="false"
                :service="service"
            />
        </div>
        <p class="mt-1">
            Das ist eine Vorschau deiner Service-Page auf {{ $myT('mainAppDomain') }}
        </p>
        <LazyDialogComingToYouExplained
            v-if="showComingToYouDialog"
            @close="showComingToYouDialog = false"
        />
        <LazyDialogInfo
            v-if="showDescriptionDialog"
            @close="showDescriptionDialog = false"
        >
            <template #title>
                <h3>Beschreibung deines Services oder Dienstleistung</h3>
            </template>
            <template #content>
                <p>
                    Diese Service-Page wird live auf {{ $myT('mainAppDomain') }} erscheinen und auch über Google oder andere
                    Suchmaschinen erreichbar sein. Damit du besser gefunden wirst und mehr Aufmerksamkeit der User
                    kriegst, empfehlen wir, die Beschreibung mindestens 200 Wörter lang zu machen und dabei die
                    für dich wesentlichen Botschaften abzubilden.
                </p>
                <p>
                    Eine gute und ausführliche Beschreibung ist ein wesentlicher Hebel für deine Auffindbarkeit auf
                    Google. Du hast somit die Möglichkeit, deine Sichtbarkeit sehr stark positiv zu beeinflussen!
                    Verpasse sie nicht!
                </p>
            </template>
        </LazyDialogInfo>
        <LazyServiceImagesUploadDialog
            v-if="showServiceImagesUploadDialog"
            :service-id="service.id"
            :images="service.otherImages"
            @close="showServiceImagesUploadDialog = false"
        />
        <section class="content-container mt-1">
            <section class="info-card">
                <p class="coming-to-you">
                    <span>
                        <img
                            src="/assets/images/allekommen-icon.svg"
                            alt="Kommt zu dir!"
                            title="Kommt zu dir!"
                            loading="lazy"
                        >
                        Kommt zu dir
                    </span>
                    <InfoIcon @click="showComingToYouDialog = true" />
                </p>
                <div class="service-name-container">
                    <div class="input-wrapper">
                        <h1 v-if="!editName">
                            {{ serviceName }}
                        </h1>
                        <input
                            v-else
                            id="servicename"
                            ref="nameInput"
                            v-model="serviceName"
                            type="text"
                            name="servicename"
                            placeholder="Servicename eingeben"
                            maxlength="70"
                        >
                        <ActionIcon
                            v-if="!editName"
                            class="green-border"
                            icon="pen-solid"
                            :position-absolute="false"
                            @click="enableNameInput"
                        />
                        <ActionIcon
                            v-if="editName && serviceName.length > 2"
                            icon="check-solid"
                            class="green-border"
                            :position-absolute="false"
                            @click="updateName"
                        />
                    </div>
                    <p
                        v-if="editName"
                        class="chars"
                        :class="{ error: serviceName.length > 70 }"
                    >
                        {{ serviceName.length }} / 70 Zeichen
                    </p>
                </div>
                <h2 class="business-name-servicepage">
                    {{ service.business.name }}
                </h2>
                <span class="category-link">
                    Kategorie: {{ service.category.name }}
                </span>
                <RatingStarsWithCount
                    :average-rating="service.rating"
                    :total-reviews="service.totalReviews"
                />
                <ServiceImageSlider
                    v-if="service.otherImages.length > 0"
                    :images="service.otherImages"
                    :service-id="service.id"
                    @add-image="showServiceImagesUploadDialog = true"
                />
                <div
                    v-else
                    class="add-images-card"
                >
                    <strong>Bilder hinzufügen</strong>
                    <ActionIcon
                        icon="plus-solid"
                        :position-absolute="false"
                        @click="showServiceImagesUploadDialog = true"
                    />
                </div>
                <ServiceOpeningHours
                    :service-id="service.id"
                    :hours="openingHours"
                />
            </section>
            <ServiceBookingInfo
                :service-id="service.id"
                class="contact-container"
            />
            <h2 class="mt-2">
                Beschreibung
                <ActionIcon
                    v-if="!editDescription"
                    icon="pen-solid"
                    :position-absolute="false"
                    @click="editDescription = true"
                />
            </h2>
            <div v-if="editDescription">
                <InputRichTextDescription
                    v-model="description"
                    ai-purpose="service"
                />
                <div class="flex-row mt-1">
                    <button
                        class="outline"
                        @click="editDescription = false"
                    >
                        Abbrechen
                    </button>
                    <button
                        :disabled="descriptionTooShort"
                        :aria-busy="loading"
                        @click="saveDescription"
                    >
                        Speichern
                    </button>
                </div>
            </div>
            <div
                v-else
                v-html="service.info"
            />
            <ServiceProductSection
                class="mt-1"
                :service-id="service.id"
            />
            <FaqFormSection
                :scope="`service-${service.permanentName}-${service.id}`"
                class="mt-2"
            />
        </section>
    </section>
</template>

<script setup>
const toast = useToast()
const route = useRoute()
const serviceStore = useServiceStore()
const service = computed(() => serviceStore.getServiceById(Number(route.params.id)))

const showServiceImagesUploadDialog = ref(false)

const editName = ref(false)
const nameInput = ref(null)
function enableNameInput () {
    editName.value = true
    nextTick(() => {
        nameInput.value.focus()
    })
}

const serviceName = ref(service.value.name)
const showComingToYouDialog = ref(false)
const showDescriptionDialog = ref(false)
async function updateName () {
    try {
        await serviceStore.updateServiceName({
            newName: serviceName.value, serviceId: service.value.id
        })
        toast.success('Erfolgreich gespeichert!')
        editName.value = false
    } catch (error) {
        handleBackendError({ error, toast })
    }
}

const openingHours = computed(() => {
    const hours = service.value.openingHours ?? []
    return hours.sort((a, b) => a.dayOfWeek - b.dayOfWeek)
})

const {
    loading, description, editDescription, descriptionTooShort, saveDescription
} = useEditDescription(
    service.value.info,
    serviceStore.updateServiceDescription,
    { serviceId: service.value.id }
)
</script>

<style scoped>
h1, #servicename { margin-bottom: 0; }
.business-name-servicepage {
    display: flex;
    column-gap: 0.5rem;
    font-weight: normal;
    font-size: 1rem;
    margin: 0;
}
.add-images-card {
    padding: var(--spacing);
    box-shadow: var(--small-shadow);
    border-radius: var(--border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: var(--spacing);
    border: 2px solid var(--yellow);
}
/* length name warning message styling */
.service-name-container {
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
}
.input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    column-gap: var(--spacing);
}
.error { color: var(--red); }
.chars {
    margin-left: auto;
    width: fit-content;
}
</style>
