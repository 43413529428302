<template>
    <div
        class="contact-request"
        :class="{ unread: !contactRequest.viewed }"
    >
        <div class="space-between">
            <p
                class="date"
                :class="{ bold: !contactRequest.viewed }"
            >
                <img
                    v-if="contactRequest.viewed"
                    class="read-icon"
                    src="/assets/icons/circle-check-solid.svg"
                    alt="Gelesen"
                    title="Gelesen"
                >
                {{ convertTimestampToDate(contactRequest.createdAtTimestamp) }}
            </p>
            <button
                class="outline small fit-content"
                @click="toggleViewed"
            >
                {{ contactRequest.viewed ? 'Nicht gelesen' : 'Gelesen' }}
            </button>
        </div>
        <div class="wrap space-between mt-05">
            <span>
                <b>E-Mail: </b>
                <span
                    class="pointer ml-05"
                    role="link"
                    data-tooltip="Kopieren"
                    @click="copyToClipboard({
                        text: contactRequest.responseEmail, toast
                    })"
                >
                    {{ contactRequest.responseEmail }}
                </span>
            </span>
            <span v-if="contactRequest.responsePhone">
                <b>Telefonnummer:</b>
                <span
                    class="pointer ml-05"
                    role="link"
                    data-tooltip="Kopieren"
                    @click="copyToClipboard({
                        text: contactRequest.responsePhone, toast
                    })"
                >
                    {{ contactRequest.responsePhone }}
                </span>
            </span>
            <b v-if="contactRequest.responsePhone && contactRequest.callWanted">
                Rückruf erwünscht!
            </b>
        </div>
        <AccordionComponent
            class="mt-1 mb-1 message"
            :use-layout="false"
            :white-theme="false"
            @opened="updateViewed(true)"
        >
            <template #title>
                Nachricht aufklappen
            </template>
            <template #links>
                {{ contactRequest.message }}
            </template>
        </AccordionComponent>
        <a
            :href="`https://www.${$myT('mainAppDomain')}${contactRequest.submissionUrl}`"
            target="_blank"
        >Seite der Einsendung öffnen</a>
    </div>
</template>

<script setup>
const props = defineProps({
    contactRequest: { type: Object, required: true }
})
const contactRequest = ref(props.contactRequest)

const toast = useToast()
const { $backendApi } = useNuxtApp()
async function toggleViewed () {
    await updateViewed(!contactRequest.value.viewed)
}

async function updateViewed (newViewed) {
    if (newViewed === contactRequest.value.viewed) {
        return
    }
    try {
        await $backendApi(`/notifications/${contactRequest.value.businessId}/toggle-viewed/${contactRequest.value.id}`, {
            method: 'PUT', body: { viewed: newViewed }
        })
        contactRequest.value.viewed = newViewed
    } catch (error) {
        handleBackendError({ error, toast })
    }
}
</script>

<style scoped>
.contact-request {
    border-radius: var(--border-radius);
    box-shadow: var(--small-shadow);
    margin-top: 1rem;
    padding: 0.5rem;
    background-color: transparent;
}
.space-between { row-gap: 0.3rem; }
.wrap { flex-wrap: wrap; }
.date { display: flex; align-items: center; column-gap: 0.5rem; margin-bottom: 0; }
.read-icon {
    width: 1rem;
    height: 1rem;
}
.bold { font-weight: bold; }
.unread {
    background-color: var(--white);
}
.message {
    border-bottom: 1px solid var(--dark-blue);
}
.pointer { cursor: pointer; }
</style>
