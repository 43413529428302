<template>
    <section class="layout-container">
        <DarkOverlay :active="isSidebarOpen" @click="toggleSidebar(false)" />
        <NavigationDashboardHeader v-model="isSidebarOpen" />
        <NavigationDashboardSidebar :is-sidebar-open="isSidebarOpen" />
        <main>
            <BoxForSlot v-if="business?.monthlyBudgetLimitReached && !isStatisticsRoute" border-color="red" class="mb-1">
                <template #content>
                    <div class="budget-note">
                        <p><b>Achtung:</b> Dein Monatsbudget wurde erreicht - die Ausspielung deiner Angebote wurde automatisch pausiert.</p>
                        <NuxtLink class="cta-link" role="button" :to="localePath('dashboard-statistics')">
                            Limit erhöhen
                        </NuxtLink>
                    </div>
                </template>
            </BoxForSlot>
            <slot />
        </main>
    </section>
</template>

<script setup>
const localePath = useLocalePath()
const route = useRoute()
const isStatisticsRoute = computed(() => route.path === localePath('dashboard-statistics'))
const { business } = storeToRefs(useBusinessStore())

const isSidebarOpen = ref(false)

function toggleSidebar (value) {
    isSidebarOpen.value = value
}
</script>

<style scoped>
section, p { margin-bottom: 0; }
.layout-container {
    display: grid;
    grid-template-rows: 4rem auto;
    background-color: var(--dark-blue);
}
main {
    padding: 5rem 1rem 1rem 1rem;
    background-color: var(--background-color);
}
.budget-note {
    display: flex;
    align-items: center;
    column-gap: var(--spacing);
    row-gap: var(--spacing);
    flex-wrap: wrap;
}
.cta-link { min-width: 10rem; }
@media (min-width: 1024px) {
    .layout-container {
        grid-template-columns: 15rem auto;
    }
    main {
        padding: 1rem;
        grid-column: 2;
        grid-row: 2;
        border-top-left-radius: calc(2 * var(--border-radius));
    }
    .budget-note {
        justify-content: space-between;
        flex-wrap: nowrap;
    }
}
@media (min-width: 1024px) {
    .layout-container {
        grid-template-columns: 16rem auto;
    }
}
</style>
