<template>
    <section class="head grid-center">
        <div class="login-form">
            <div class="points">
                <h1>Lass andere wissen, dass du richtig gut bist!</h1>
                <div class="points-container">
                    <p>📈 Über 1 Million Suchimpressionen</p>
                    <p>❤️ Leicht einzurichten</p>
                    <p>🙂 Persönlicher Ansprechpartner</p>
                    <p>🌎 Vollwertige Onlinepräsenz in Minuten</p>
                    <p>✅ Sofort Reichweite </p>
                    <p>💰 1 Monat geschenkt</p>
                </div>
            </div>
            <div id="form" class="form">
                <div class="mb-1 space-between">
                    <h2>{{ formTitle }}</h2>
                    <span v-if="useEmail" role="link" class="back-link" @click="useEmail = false">Zurück</span>
                </div>
                <div v-if="!useEmail">
                    <SocialLoginButtons />
                    <p class="mt-1 centered">
                        oder
                    </p>
                    <button class="login-btn outline" @click="useEmail = true">
                        <img src="assets/icons/mail-solid.svg" alt="Mit E-Mail anmelden">
                        {{ emailButtonText }}
                    </button>
                </div>
                <slot v-else name="form" />
                <RegistrationNotesLinks />
                <p class="mt-1 space-between mb-0">
                    <slot name="bottomLink" />
                </p>
            </div>
        </div>
    </section>
</template>

<script setup>
const { emailButtonText } = defineProps({
    emailButtonText: { type: String, required: true },
    formTitle: { type: String, required: true }
})
const useEmail = ref(false)
</script>

<style scoped>
.grid-center {
    display: grid;
    place-items: center;
}
.login-form {
    display: flex;
    flex-direction: column-reverse;
}
.form {
    min-width: 20rem;
    padding: 1.5rem;
    background-color: var(--white);
}
.points {
    padding: 1.5rem;
}
h2 { margin-bottom: 0; }
.login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
}
.back-link { cursor: pointer; }
.points p { font-weight: bold; }
@media (min-width: 768px) {
    .login-form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: var(--spacing);
    }
    .points { padding: 1.5rem 0; }
    .grid-center { height: 80vh; padding: 1rem; }
    .form {
        box-shadow: var(--shadow);
        border-radius: var(--border-radius);
    }
    .points-container {
        height: 100%;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
    }
}
@media (min-width: 1024px) {
    .login-form {
        width: 53rem;
    }
}
</style>
