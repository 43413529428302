<template>
    <section class="container centered">
        <h1>{{ title }}</h1>
        <p class="status">
            {{ emoji }}
        </p>
        <p v-if="status === 'success'">
            Auf eine gute Zusammenarbeit!
        </p>
        <NuxtLink role="button" :to="localePath('dashboard')">
            Zum Dashboard
        </NuxtLink>
    </section>
</template>

<script setup>
definePageMeta({
    auth: false
})
const localePath = useLocalePath()

const route = useRoute()
const status = route.query.status
const sessionId = route.query.sessionId
const { error } = useFetch('/api/stripe/session', { query: { sessionId } })
if (error.value) { throw error.value }

const title = computed(() => {
    return status === 'success' ? 'Es hat geklappt!' : 'Leider kein Erfolg - probiere es nochmal!'
})
const emoji = computed(() => {
    return status === 'success' ? '🤝' : '😔'
})

const { data } = useAuthState()
const { refresh } = useAuth()
onMounted(async () => {
    await refresh()
    if (status === 'success') { trackGoaffproOrder(data.value) }
})
</script>

<style scoped>
.status {
    font-size: 8rem;
}
</style>
